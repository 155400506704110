import { replaceDimensionsInUrl } from "../common_functions/Common";
import React, { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import muzifyLogo from "../assets/images/muzifyLogo.png"
function MusicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
    >
      <path
        d="M5.3531 7.33015V11.1879C5.3531 11.3859 5.19275 11.5464 4.99492 11.5464C4.7971 11.5464 4.63675 11.3859 4.63675 11.1879V7.33015C4.63675 7.13217 4.7971 6.97167 4.99492 6.97167C5.19275 6.97167 5.3531 7.13217 5.3531 7.33015ZM11.5372 7.84617C11.3393 7.84617 11.179 8.00667 11.179 8.20466V10.3137C11.179 10.5117 11.3393 10.6722 11.5372 10.6722C11.7348 10.6722 11.8952 10.5117 11.8952 10.3137V8.20466C11.8952 8.00667 11.7348 7.84617 11.5372 7.84617ZM6.3035 7.48085C6.10567 7.48085 5.94533 7.64135 5.94533 7.83933V10.6789C5.94533 10.8769 6.10567 11.0374 6.3035 11.0374C6.50133 11.0374 6.66167 10.8769 6.66167 10.6789V7.83933C6.66167 7.64135 6.50133 7.48085 6.3035 7.48085ZM3.68666 7.84617C3.48884 7.84617 3.32849 8.00667 3.32849 8.20466V10.3137C3.32849 10.5117 3.48884 10.6722 3.68666 10.6722C3.88449 10.6722 4.04483 10.5117 4.04483 10.3137V8.20466C4.04483 8.00667 3.88449 7.84617 3.68666 7.84617ZM7.61176 5.57227C7.41394 5.57227 7.25359 5.73277 7.25359 5.93075V12.5875C7.25359 12.7854 7.41394 12.9459 7.61176 12.9459C7.80959 12.9459 7.96993 12.7854 7.96993 12.5875V5.93075C7.97009 5.73277 7.80959 5.57227 7.61176 5.57227ZM8.92034 7.6216C8.72251 7.6216 8.56217 7.7821 8.56217 7.98008V10.5383C8.56217 10.7363 8.72251 10.8968 8.92034 10.8968C9.11816 10.8968 9.27851 10.7363 9.27851 10.5383V7.98008C9.27851 7.7821 9.11816 7.6216 8.92034 7.6216ZM10.2286 7.31817C10.0308 7.31817 9.87043 7.47867 9.87043 7.67665V11.1879C9.87043 11.3859 10.0308 11.5464 10.2286 11.5464C10.4264 11.5464 10.5868 11.3859 10.5868 11.1879V7.67665C10.5869 7.47867 10.4266 7.31817 10.2286 7.31817Z"
        fill="#FFE9BA"
      />
      <mask
        id="mask0_2710_1385"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="12"
      >
        <path d="M0 0.0537109H15.2289V11.5998H0V0.0537109Z" fill="white" />
      </mask>
      <g mask="url(#mask0_2710_1385)">
        <path
          d="M15.2295 7.90157V10.0492C15.2295 10.8932 14.5433 11.58 13.7001 11.58H13.4509C13.2562 11.58 13.0982 11.422 13.0982 11.227V6.25536C13.0985 3.22903 10.6386 0.766623 7.61474 0.766623C4.5909 0.766623 2.13114 3.22903 2.13114 6.25536V11.227C2.13114 11.422 1.97328 11.58 1.77857 11.58H1.52942C0.686169 11.58 0 10.8932 0 10.0492V7.90157C0 7.09238 0.630492 6.4283 1.42584 6.3748V6.25567C1.42584 2.8396 4.20209 0.0605469 7.61474 0.0605469C11.0274 0.0605469 13.8036 2.8396 13.8036 6.25536V6.37464C14.5988 6.4283 15.2295 7.09238 15.2295 7.90157Z"
          fill="#FFE9BA"
        />
      </g>
    </svg>
  );
}

export default function ScoreCard({
  artistName,
  artistImage,
  right,
  time,
  percentile,
  created= new Date(),
}) {
  const tiltRef = useRef(null);


  let today = created
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy;

  useEffect(() => {
    if (window.innerWidth > 768) {
      VanillaTilt.init(tiltRef.current, {
        max: 25,
        speed: 400,
        glare: true,
        "max-glare": 0.5,
      });

      return () => tiltRef?.current?.vanillaTilt.destroy();
    }
  }, []);

  return (
    <div
      className="max-w-sm mx-auto shadow-md"
      ref={tiltRef}
      style={{
        borderRight: "4px solid #710002",
        backgroundImage: "url('/bg.jpeg')",
      }}
    >
      <div className="p-3 md:p-6">
        <div className="flex justify-center">
          <img
            alt="Person"
            className="rounded-lg"
            height="150"
            width="150"
            src={replaceDimensionsInUrl(artistImage)}
            style={{
              objectFit: "cover",
            }}
          />
        </div>
        <div className="text-center mt-2 md:mt-4">
          {/* {console.log(percentile)} */}
          {percentile > 0 ? (
            <p className="text-xs md:text-sm text-[#710002] font-yaldevi">
              Among Top{" "}
              <span className="font-bold">
                {percentile == 0.01 ? percentile : (
                  (percentile) % 1 !== 0 ? (percentile).toFixed(2) : (percentile)
                )}%
              </span>{" "}
              fans of
            </p>
          ) : (
            <p className="text-xs md:text-xl text-[#710002] font-yaldevi">
              First fan to play
            </p>
          )}
          <h1 className="text-sm md:text-3xl font-bold font-voltaire text-[#710002]">
            {artistName}
          </h1>
          <p className="text-xs md:text-sm text-[#710002] font-yaldevi mt-1">
            Guessed <span className="font-bold">{right}/10</span> songs under
            <span className="font-bold"> {time} </span>secs
          </p>
        </div>
      </div>
      <div className="flex bg-[#710002] p-2 justify-between items-center">
        <div className="flex items-center">
          <span
            style={{ fontSize: "10px" }}
            className="hidden md:flex text-xs md:text-sm text-[#FFE9BA] font-yaldevi mr-1"
          >
            Music Quiz By
          </span>
          {/* <MusicIcon className="text-[#FFE9BA]" /> */}
          <img src={muzifyLogo} className="w-[44px] h-[17px]"/>
        </div>
        <span
          style={{ fontSize: "10px" }}
          className="text-xs md:text-sm text-[#FFE9BA]"
        >
          {today}
        </span>
      </div>
    </div>
  );
}
