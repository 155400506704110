import ShareModal from "../../components/Share";

import { useState, useEffect, useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import {
  getArtistSongs,
  getLeaderboard,
  INCREMENT_ARTIST_GAMEPLAY,
  INCREMENT_ARTIST_SHARE,
  postArtistEvent,
} from "../../endpoints/api";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/AssetLogo.svg"; // Adjust path as needed
import useAsset from "../../hooks/useAsset";
import MuzifyLogo from "../../assets/images/logo.png";
import { AvatarGenerator } from "random-avatar-generator";
import Header from "../../components/Header";
import useClevertap from "../../hooks/useClevertap";
import ScoreCard from "../../components/ScoreCard";
import AddUsernameModal from "../../components/AddUsernameModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const statuses: Record<string, string> = {
  Completed: "text-green-400 bg-green-400/10",
  Error: "text-rose-400 bg-rose-400/10",
};

type User = {
  uuid: string;
  username: string;
  profile_image: string;
  score: number;
  time: number;
  logged: boolean;
};
type ArtistData = {
  artist_name: string;
  artist_id: string;
  artist_image: string;
  users: User[];
};

interface UserInfo {
  some(arg0: (userDataItem: any) => boolean): unknown;
  username: string;
  bio: string;
  coverpic: string;
  created: number;
  email: string;
  firstname: string;
  followers: null;
  following: null;
  genre: [];
  lastname: string;
  mobile: number;
  profilepic: string;
  spotifytoken: string;
  status: string;
  telegramtoken: string;
  tokens: { applemusic: boolean; spotify: boolean; gmail: boolean };
  updated: number;
  uid: string;
  wallet: string;
}

interface Badge {
  uuid: string;
  username: string;
  artist_name: string;
  artist_id: string;
  artist_image: string;
  score: number;
  percentile: number;
  time_taken: number;
  created: number;
  updated: number;
}

// const activityItems = [
//   {
//     user: {
//       name: 'Michael Foster',
//       imageUrl:
//         'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
//     },
//     right: 2,
//     wrong: 3,
//     score: 2,
//   },

// ]

const clearUsername = (username: string) => {
  // remove numbers from username
  username = username.replace(/[0-9]/g, "");
  // remove special characters from username
  username = username.replace(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, "");
  // check for uppercase letter in username - exculde the first letter
  let dupUsername = username.slice(1, username.length);
  // get first uppercase letter
  let uppercaseLetter = dupUsername.match(/[A-Z]/);
  if (uppercaseLetter) {
    // get the index of the first uppercase letter
    let index = dupUsername.indexOf(uppercaseLetter[0]);
    // if index is greater than 0 then slice the username from 0 to index
    if (index > 0) {
      return username.slice(0, index + 1);
    } else {
      return username;
    }
  } else {
    return username;
  }
};

export default function LeaderboardTable() {
  const [isOpen, setIsOpen] = useState(false);
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [data, setData] = useState<ArtistData | null>(null);
  const { id, playername } = useParams();
  const location = useLocation();
  const [quizData, setQuizData] = useState([]);
  const [randUsername, setRandUsername] = useState<string | null>("");
  const [badge, setBadge] = useState<Badge | null>(null);

  const score = location?.state?.score;
  const time = location?.state?.time;
  const percentile = location?.state?.percentile;
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { handleEventPushClick } = useClevertap();
  const [userIndex, setUserIndex] = useState<number | null>(null);
  const [uniqueUsersFromLeaderBoard, setUniqueUsersFromLeaderBoard] = useState<User[]>([])

  const generator = new AvatarGenerator();

  const AppURL = process.env.REACT_APP_URL;
  const {
    accessToken,
    setAccessToken,
    userData: UserInfo,
    getUser,
    username,
    userInfo: untypedUserInfo,
  } = useAsset();
  const userInfo = untypedUserInfo as unknown as UserInfo;
  const userData = UserInfo as unknown as UserInfo;

  const getBadges = useCallback(async () => {
    // console.log('user data is', userData)
    if (userData?.uid) {
      try {
        const url = `${process.env.REACT_APP_ASSET_API}/api/v1/user/badges/${userData?.uid}`;

        const response = await fetch(url);
        const data = await response.json();
        if (data?.data?.length > 0) {
          // setBadges(data?.data);
          if (data?.data?.length > 0) {
            let badges = data?.data;
            if (id) {
              for (let i = 0; i < badges.length; i++) {
                if (badges[i].artist_id === id) {
                  setBadge(badges[i]);
                }
              }
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [userData]);

  //   call getBadges
  useEffect(() => {
    getBadges();
  }, [getBadges]);

  const displayTime = (time: number) => {
    if (time < 60) {
      return `${time}s`;
    } else if (time >= 60 && time < 3600) {
      return `${Math.floor(time / 60)}m ${time % 60}s`;
    } else {
      return `${Math.floor(time / 3600)}h ${Math.floor((time % 3600) / 60)}m ${
        time % 60
      }s`;
    }
  };

  const handleShare = async () => {
    if (navigator.share) {
      // Web Share API for mobile devices
      await navigator.share({
        title: document.title,
        text: "I just discovered a fun website to watch wonderful YT videos - ",
        url: window.location.href,
      });
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (accessToken && accessToken !== "") {
      // get last visited page path using
      if (
        location.state?.profile === null ||
        location.state?.profile === undefined ||
        location.state?.profile === false
      ) {
        toast.success("Yay, your scorecard is now on your profile 🥳", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [accessToken]);

  let copyToClipboard = () => {
    const textToCopy = document.getElementById("textToCopy")?.textContent || "";
    navigator.clipboard.writeText(textToCopy);
    setIsCopied(true);
    // after 2 seconds, set isCopied back to false
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const getScores = async (id: string) => {
    const response = await getLeaderboard(id);
    setData(response.data.data);
  };
  const getArtistData = async (id: string | undefined) => {
    const response = await getArtistSongs(id?.toString()!);
    setQuizData(response.data.data.artist_quiz);
  };

  const getLocalUsername = () => {
    try {
      const localUsername = localStorage.getItem("username");
      console.log(localUsername);
      if (typeof localUsername === "string" && localUsername?.length > 0) {
        setRandUsername(localUsername);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePlayAgain = () => {
    posthog?.capture("play_again");
    handleEventPushClick("MUSICNERD_PLAYAGAIN", {
      click: "play_again",
    });
    postArtistEvent(
      id?.toString() ?? "",
      location?.state?.artistName
        ? location?.state?.artistName
        : data?.artist_name,
      location?.state?.artistImage
        ? ImageDecode(location?.state?.artistImage)
        : data?.artist_image ?? "",
      INCREMENT_ARTIST_GAMEPLAY
    );
    navigate(`/quiz/${id}`, {
      state: {
        artistName: location?.state?.artistName
          ? location?.state?.artistName
          : data?.artist_name,
        artistImage: location?.state?.artistImage
          ? ImageDecode(location?.state?.artistImage)
          : data?.artist_image,
      },
    });
  };

  useEffect(() => {
    getLocalUsername();
    getArtistData(id);
  }, []);
  useEffect(() => {
    // Fetch the data when the component mounts
    getScores(id?.toString()!);
  }, [id]);

  useEffect(() => {
    if (accessToken) {
      getUser();
    }
  }, [accessToken]);

  useEffect(() => {
    if (location?.state?.artistName) {
      document.title = `${location?.state?.artistName} Leaderboard | Musicnerd - Name that tune`;
      handleEventPushClick("MUSICNERD_AFTER_SIGNUP", {
        username: playername,
        artist_name: location?.state?.artistName,
      });
    } else if (data?.artist_name) {
      document.title = `${data?.artist_name} Leaderboard | Musicnerd - Name that tune`;
      handleEventPushClick("MUSICNERD_AFTER_SIGNUP", {
        username: playername,
        artist_name: data?.artist_name,
      });
    }
  }, [location?.state?.artistName, data?.artist_name]);

  useEffect(() => {
    if (accessToken && accessToken?.length > 0) {
      let askForUsername = localStorage.getItem("askForUsername");
      console.log("ASK FOR USERNAMEW ", askForUsername);
      if (askForUsername == "true") {
        setIsUsernameModalOpen(true);
      }
    }
  }, [accessToken]);

  const ImageDecode = (imageUrl: string) => {
    const decodedUrl = imageUrl.replace("{w}", "300").replace("{h}", "400");
    return decodedUrl;
  };

  useEffect(() => {
    if (data?.users && playername) {
      let player = data?.users.find((user) => user.username === playername);
      console.log("PLAYER ", player);
      if (player) {
        const ref = player.uuid;
        console.log("ref", ref);
        if (ref) {
          const checkInLocalStorage = localStorage.getItem("ref");
          if (!checkInLocalStorage) {
            localStorage.setItem("ref", ref);
            fetch("https://cruncher.asset.money/misc/referral", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                referralCode: ref,
                referreduser: "",
              }),
            })
              .then((res) => res.json())
              .then((data) => {
                console.log("data", data);
              });
          }
        }
      } else {
      }
    }
  }, [data?.users, playername]);

  // Function to find a user by uid
  function findUniqueUsersAndIndex(
    data: ArtistData | null,
    targetUuid: string
  ): number | undefined {
    if (!data || !data.users) {
      console.error("Data or users is null or undefined");
      return undefined;
    }

    // Filter out duplicates based on username
    const uniqueUsernames = new Set<string>()
    const uniqueUsers = data.users.filter(
      (user) => {
        if (!uniqueUsernames.has(user.username) && user.score <= 100 && user.time >= 6) {
          uniqueUsernames.add(user.username);
          return true;
        }
        return false;
      }
    );

    setUniqueUsersFromLeaderBoard(uniqueUsers)

    // Find the index of the user with the target UUID among the unique users
    setUserIndex(uniqueUsers.findIndex((user) => user.uuid === targetUuid));
  }

  useEffect(() => {
    findUniqueUsersAndIndex(data, userData?.uid);
  }, [data, userData?.uid]);

  function scrollToRow(index: number) {
    let rowId = `row-${index - 2}`;
    const rowElement = document.getElementById(rowId);
    if (rowElement) {
      rowElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    if (userIndex != null) {
      scrollToRow(userIndex);
    }
  }, [userIndex]);

  return (
    <div className="flex flex-col h-screen">
      {isUsernameModalOpen && (
        <AddUsernameModal
          handleClose={() => setIsUsernameModalOpen(false)}
          accessToken={accessToken}
        />
      )}
      <div className="flex flex-col w-full"> {/* <Header /> */}</div>
      <div className="bg-blue h-screen w-full">
        <div className="flex justify-center items-center mt-14">
          <div className="flex md:justify-center absolute left-2 md:left-0 right-0">
            <Link className="flex flex-row gap-2" to="/">
              <img src={MuzifyLogo} alt="muzify" className="w-32" />
            </Link>
          </div>
          {userInfo && (
            <div className="flex justify-end mr-5 md:mr-10 absolute right-0">
              <Link to="/profile">
                <img
                  src={userInfo?.profilepic}
                  alt=""
                  className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-gray-800"
                />
              </Link>
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row mt-16 md:mt-10 justify-center items-center">
          <img
            src={ImageDecode(
              location?.state?.artistImage
                ? ImageDecode(location?.state?.artistImage)
                : data?.artist_image || "default_image_path"
            )}
            alt=""
            className="w-12 h-12 md:h-[72px] md:w-[72px] rounded-full bg-gray-800"
          />
          <p className="ml-4 mt-3 md:mt-0 text-center font-extrabold text-2xl text-yellow">
            {location?.state?.artistName
              ? location?.state?.artistName
              : data?.artist_name}{" "}
            Fan Leaderboard
          </p>
        </div>
        <ToastContainer toastClassName="md:wide-toast" />
        <div className="flex justify-center flex-col md:flex-row mt-5 md:mt-16">
          <div className="  flex-col mr-28 w-full md:w-1/3 cabinet-grotesk items-center">
            <div className="text-white text-2xl items-center flex flex-col justify-center">
              {accessToken && score ? (
                <>
                  {/* <p className="ml-4 mt-3 mb-2 md:mt-0 text-center font-extrabold text-2xl text-yellow"> Your Claimed Score-Card</p> */}
                  <ScoreCard
                    right={score / 10}
                    artistName={
                      location?.state?.artistName
                        ? location?.state?.artistName
                        : data?.artist_name
                    }
                    time={time}
                    artistImage={
                      location?.state?.artistImage
                        ? ImageDecode(location?.state?.artistImage)
                        : data?.artist_image
                    }
                    percentile={percentile}
                  />
                </>
              ) : badge ? (
                <ScoreCard
                  right={badge?.score / 10}
                  artistName={
                    location?.state?.artistName
                      ? location?.state?.artistName
                      : data?.artist_name
                  }
                  time={badge?.time_taken}
                  artistImage={
                    location?.state?.artistImage
                      ? ImageDecode(location?.state?.artistImage)
                      : data?.artist_image
                  }
                  percentile={badge?.percentile}
                />
              ) : (
                <div className="mb-5"></div>
              )}
            </div>

            {playername && (
              <div className="mt-10 flex flex-col mb-6 items-center">
                <img
                  src={
                    data?.users?.find((user) => user.username === playername)
                      ?.profile_image
                  }
                  alt=""
                  className="h-8 w-8 rounded-full bg-gray-800"
                />
                <p className=" text-white ml-4 text-xl">
                  <span className="text-yellow">{playername}</span> challenged
                  you to guess{" "}
                </p>
                <p className=" text-white ml-4 text-xl">
                  {data?.artist_name}'s Songs
                </p>
              </div>
            )}
            <div className="hidden md:flex md:justify-center mb-6">
              {isOpen && (
                <ShareModal
                  shareUrl={`${AppURL}/leaderboard/${id}/${userInfo?.username}`}
                  artistName={data?.artist_name}
                  handleClose={handleClose}
                />
              )}
              {accessToken ? (
                <div className="flex flex-col md:flex-col mt-2 items-start md:items-center gap-y-4 md:gap-y-0 md:gap-x-6">
                  <div className="flex justify-around w-full mb-3 mt-4  ">
                    <button
                      onClick={() => {
                        posthog?.capture("share_leaderboard");
                        postArtistEvent(
                          id?.toString() ?? "",
                          location?.state?.artistName
                            ? location?.state?.artistName
                            : data?.artist_name,
                          location?.state?.artistImage
                            ? ImageDecode(location?.state?.artistImage)
                            : data?.artist_image ?? "",
                          INCREMENT_ARTIST_SHARE
                        );
                        setIsOpen(true);
                      }}
                      className="bg-yellow cabinet-grotesk w-80 md:w-96 h-12 rounded-full"
                    >
                      <p className="text-base font-bold cabinet-grotesk text-black">
                        Share
                      </p>
                    </button>
                  </div>

                  <button
                    onClick={handlePlayAgain}
                    className="cabinet-grotesk w-80 md:w-96 h-12 border border-yellow rounded-full text-yellow bg-gray"
                  >
                    <p className="text-base font-bold cabinet-grotesk">
                      Play Again
                    </p>
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-4 md:gap-4 justify-center">
                  <button
                    onClick={() => {
                      postArtistEvent(
                        id?.toString() ?? "",
                        location?.state?.artistName
                          ? location?.state?.artistName
                          : data?.artist_name,
                        location?.state?.artistImage
                          ? ImageDecode(location?.state?.artistImage)
                          : data?.artist_image ?? "",
                        INCREMENT_ARTIST_GAMEPLAY
                      );
                      navigate(`/quiz/${id}`, {
                        state: {
                          artistName: location?.state?.artistName
                            ? location?.state?.artistName
                            : data?.artist_name,
                          artistImage: location?.state?.artistImage
                            ? ImageDecode(location?.state?.artistImage)
                            : data?.artist_image,
                        },
                      });
                    }}
                    className="bg-yellow cabinet-grotesk mt-10 w-80 md:w-96 h-12 rounded-full "
                  >
                    <p className="text-base font-bold cabinet-grotesk">
                      Play Quiz
                    </p>
                  </button>
                  <button
                    onClick={() => {
                      postArtistEvent(
                        id?.toString() ?? "",
                        location?.state?.artistName
                          ? location?.state?.artistName
                          : data?.artist_name,
                        location?.state?.artistImage
                          ? ImageDecode(location?.state?.artistImage)
                          : data?.artist_image ?? "",
                        INCREMENT_ARTIST_SHARE
                      );
                      setIsOpen(true);
                    }}
                    className="cabinet-grotesk w-80 md:w-96 h-12 border border-yellow rounded-full text-yellow bg-gray"
                  >
                    <p className="text-base font-bold cabinet-grotesk">Share</p>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div>
            <div className="flex overflow-y-auto overflow-x-hidden max-h-[500px] justify-center py-5 rounded-xl">
              <table className="w-full md:w-[340px] whitespace-nowrap text-left" style={{
                borderTopLeftRadius: "0.375rem",
                borderTopRightRadius: "0.375rem",
              }}>
                <colgroup>
                  <col className="w-1/2 sm:w-4/12" />
                  <col className="w-1/4 sm:w-4/12" />
                  <col className="w-1/4 sm:w-4/12" />
                </colgroup>
                <thead className="text-gray text-sm leading-6 text-white">
                  <tr>
                    <th scope="col" className="py-2 pl-4 pr-2 font-semibold sm:pl-6 lg:pl-8">Fans</th>
                    <th scope="col" className="py-2 px-2 font-semibold text-center">Score</th>
                    <th scope="col" className="py-2 px-2 font-semibold text-center">Time</th>
                  </tr>
                </thead>
                <tbody className="bg-gray overflow-y-auto">
                  {uniqueUsersFromLeaderBoard.map((user: User, index: number) => (
                      <tr
                        id={`row-${index}`}
                        className={`${
                          index === userIndex
                            ? "highlighted-row rounded-tl-md rounded-tr-md bg-yellow text-black"
                            : "text-white"
                        }`}
                      >
                        <td className="flex flex-row py-4 pl-4 pr-2 h-14">
                          <p className="mr-2 sm:mr-6">{index + 1}</p>
                          <div className="flex items-center gap-x-2">
                            <img
                              src={
                                user?.profile_image?.length > 0
                                  ? user?.profile_image
                                  : generator.generateRandomAvatar(user.username)
                              }
                              alt=""
                              className="h-8 w-8 rounded-full bg-gray-800"
                            />
                            <div className="truncate text-sm font-medium leading-6 w-20 sm:w-32 md:w-52">
                              {userInfo?.username?.length > 0
                                ? randUsername === user?.username
                                  ? clearUsername(userInfo?.username)
                                  : user?.logged
                                  ? clearUsername(user?.username)
                                  : user?.username
                                : user?.logged
                                ? clearUsername(user?.username)
                                : user?.username}
                            </div>
                          </div>
                        </td>
                        <td className="py-4 px-2">
                          <div className="flex items-center justify-center">
                            <div className="sm:block">{user.score}</div>
                          </div>
                        </td>
                        <td className="py-4 px-2">
                          <div className="flex items-center justify-center">
                            <div className="sm:block">{displayTime(user.time)}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="sm:hidden flex flex-col mt-4 md:mt-0 mr-28 w-full md:w-1/3 cabinet-grotesk items-center">
            {accessToken && score ? (
              <div className="hidden text-white mt-10 text-2xl items-center flex flex-col justify-center">
                <img
                  src={data?.artist_image || "default_image_path"}
                  alt=""
                  className="w-12 h-12 md:h-[72px] md:w-[72px] rounded-full bg-gray-800"
                />
                <p className="flex justify-center font-extrabold">
                  You’ve scored{" "}
                </p>
                <p className="font-extrabold text-yellow">
                  {score}/{quizData.length * 10} Points
                </p>
              </div>
            ) : (
              <></>
            )}
            {playername && (
              <div className="hidden mt-10 flex flex-col mb-6 items-center">
                <img
                  src={
                    data?.users?.find((user) => user.username === playername)
                      ?.profile_image
                  }
                  alt=""
                  className="h-8 w-8 rounded-full bg-gray-800"
                />
                <p className=" text-white ml-4 text-xl">
                  <span className="text-yellow">{playername}</span> challenged
                  you to guess{" "}
                </p>
                <p className=" text-white ml-4 text-xl">
                  {data?.artist_name}'s Songs
                </p>
              </div>
            )}
            <div className="mb-6">
              {isOpen && (
                <ShareModal
                  shareUrl={`${AppURL}/leaderboard/${id}/${userInfo?.username}`}
                  artistName={data?.artist_name}
                  handleClose={handleClose}
                />
              )}
              {accessToken ? (
                <div className="mt-3 flex flex-col md:flex-col md:mt-12 items-start md:items-center md:gap-y-0 md:gap-x-6">
                  <div className="flex mb-6 relative w-[335px] md:w-[394px] rounded-full bg-[#1C2124] h-16 mt-2 px-8 py-6  space-x-20">
                    <div className="pl-2 pr-8 py-1 md:w-96 text-gray flex justify-center items-center overflow-hidden overflow-ellipsis">
                      <span
                        id="textToCopy"
                        className="hidden sm:inline md:hidden"
                      >
                        {AppURL}/leaderboard/{id}/{userInfo?.username}
                      </span>
                      <span className="hidden sm:inline">
                        {`${AppURL}/leaderboard/`}..
                      </span>
                      <span className="text-sm sm:hidden ">
                        {AppURL}/leaderb....
                      </span>
                      <button
                        onClick={copyToClipboard}
                        className="mr-2 absolute right-10 top-1/2 transform -translate-y-1/2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                            stroke="#CBCBCB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                            stroke="#CBCBCB"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>

                    {isCopied && (
                      <span className="absolute right-7 top-1/2 bg-green-200 py-1 px-2 rounded-md text-xs transform -translate-y-1/2">
                        Copied!
                      </span>
                    )}
                  </div>
                  <div className="flex justify-around w-full mb-6">
                    <button
                      onClick={() => {
                        postArtistEvent(
                          id?.toString() ?? "",
                          location?.state?.artistName
                            ? location?.state?.artistName
                            : data?.artist_name,
                          location?.state?.artistImage
                            ? ImageDecode(location?.state?.artistImage)
                            : data?.artist_image ?? "",
                          INCREMENT_ARTIST_SHARE
                        );
                        handleEventPushClick("MUSICNERD_SHARE", {
                          click: "share_leaderboard",
                        });
                        setIsOpen(true);
                      }}
                      className="bg-yellow text-black cabinet-grotesk w-80 md:w-96 h-12 rounded-full"
                    >
                      <p className="text-base font-bold cabinet-grotesk text-black">
                        Share
                      </p>
                    </button>
                  </div>

                  <button
                    onClick={handlePlayAgain}
                    className="cabinet-grotesk w-80 md:w-96 h-12 border border-yellow rounded-full text-yellow bg-gray"
                  >
                    <p className="text-base font-bold cabinet-grotesk">
                      Play Again
                    </p>
                  </button>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-4 md:gap-4 justify-center">
                  <button
                    onClick={handlePlayAgain}
                    className="bg-yellow cabinet-grotesk w-80 md:w-96 h-16 rounded-full "
                  >
                    <p className="text-base font-bold cabinet-grotesk">
                      Play Quiz
                    </p>
                  </button>
                  <button
                    onClick={() => {
                      postArtistEvent(
                        id?.toString() ?? "",
                        location?.state?.artistName
                          ? location?.state?.artistName
                          : data?.artist_name,
                        location?.state?.artistImage
                          ? ImageDecode(location?.state?.artistImage)
                          : data?.artist_image ?? "",
                        INCREMENT_ARTIST_SHARE
                      );
                      handleEventPushClick("MUSICNERD_SHARE", {
                        click: "share_leaderboard",
                      });
                      setIsOpen(true);
                    }}
                    className="cabinet-grotesk w-80 md:w-96 h-16 border border-yellow rounded-full text-yellow bg-gray"
                  >
                    <p className="text-base font-bold cabinet-grotesk">Share</p>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
