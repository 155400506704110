import React, { useEffect } from 'react'
import ReactGA from "react-ga4";
const Header = () => {

  useEffect(() => {
    console.log("INITIALIZE");
    ReactGA.initialize("G-P5TY7QHTYM");
    ReactGA.send({
      hitType: "pageview",
      page: window?.location?.pathname ?? "/",
    });
  }, []);

  return (
    <div className="bg-[#efd165] flex md:h-14 w-full justify-between items-center py-2 md:py-0">
      <div className="flex grow justify-center items-center ml-20">
        <a
          className="text-black rounded-full py-2 mr-0 text-base font-bold"
          onClick={() => {
            ReactGA.event({
              category: "b2p_musicnerd",
              action: "click",
              label: "b2p_click_musicnerd"
            });
          }}
          href="https://teamasset.notion.site/MusicNerd-FAQs-To-be-published-8f2020a3f84e4c42b35b8eef6dae4320" target="_blank" rel="noreferrer"
        >
          Guess tunes to win $50 worth rewards, everyday!  🚀🎵
        </a>
      </div>
      <div className="flex items-end justify-center h-full pb-2 mr-2">
        <a className="text-black text-xs font-bold italic transform" onClick={() => {
          ReactGA.event({
            category: "b2p_musicnerd",
            action: "click",
            label: "b2p_click_musicnerd"
          });
        }} href="https://teamasset.notion.site/MusicNerd-FAQs-To-be-published-8f2020a3f84e4c42b35b8eef6dae4320" target="_blank" rel="noreferrer">To Win, Click Here</a>
      </div>
    </div>
  )
}

export default Header