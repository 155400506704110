export const artists = [
    {
        "id": "183313439",
        "name": "Ed Sheeran",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ed-sheeran/183313439",
        "editorialNotes": "Pre-add Ed’s new album and it’ll hit your library the moment it’s out.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a6/09/56/a609563b-ee32-8416-8936-e343009b277b/b4fca898-b1bc-4617-baf1-fdb4b66175b2_ami-identity-759967e43150fe52efc34501d7c47ce8-2023-08-24T17-19-12.288Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "159260351",
        "name": "Taylor Swift",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/taylor-swift/159260351",
        "editorialNotes": "From country sweetheart to pop supernova, Swift is unstoppable.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/19/c1/e7/19c1e79d-17d7-d345-0859-1f571baf4b35/mza_17588248318350205547.png/1000x1000bb.jpg"
    },
    {
        "id": "271256",
        "name": "Drake",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/drake/271256",
        "editorialNotes": "One of the most innovative hip-hop artists in the business.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/1b/71/65/1b716513-b0c1-9c6b-45aa-cbb9198248cc/01607cf1-1d52-43d2-84ce-d6fc1f4a3475_ami-identity-7665599cc626d803af7956b3691905e7-2022-11-30T21-07-19.526Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "966309175",
        "name": "Post Malone",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/post-malone/966309175",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ea/c1/e8/eac1e88f-a515-f679-c2e3-ae6cae2c4303/3b7fdb91-3d2e-4b53-9b7a-ef1c39f18cea_ami-identity-f6e441ca8bf36b9a475ea669b4b87be7-2023-04-14T22-56-21.301Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1065981054",
        "name": "Billie Eilish",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/billie-eilish/1065981054",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/aa/64/c1/aa64c19b-b273-76ee-f785-05ee22555de4/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "479756766",
        "name": "The Weeknd",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/the-weeknd/479756766",
        "editorialNotes": "A subversive vocalist who emerged from the underground to define millennial R&B.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2d/85/9c/2d859cc8-7230-8ba7-b741-a7c7c029bed6/8be5558a-5c13-48da-a649-a873856f3fb0_ami-identity-777ad796f94973f081f3e54e4b81fcb1-2023-07-22T13-59-27.161Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1031397873",
        "name": "Dua Lipa",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dua-lipa/1031397873",
        "editorialNotes": "The mezzo-soprano has garnered pop, disco and R&B fans worldwide.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7e/18/e4/7e18e487-1530-7d33-a1e1-8c92b7ae0ea4/8e089854-42b8-457a-a7a4-874553a81c86_ami-identity-6a4cdfcea5ca482d082ae1239048d924-2023-12-06T13-21-19.061Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "412778295",
        "name": "Ariana Grande",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ariana-grande/412778295",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/b0/c1/95/b0c19533-8c69-6911-dba4-2770331a56e8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1126808565",
        "name": "Bad Bunny",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/bad-bunny/1126808565",
        "editorialNotes": "Bad Bunny is Apple Music's Artist of the Year for 2022.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1a/ac/74/1aac745b-0bce-18c5-d4ac-07dbf6192fee/5c71ee0e-11e3-42d8-b1f7-0bf850252dd9_ami-identity-b99c43259683c42629e6b3a66d65af95-2023-09-25T13-13-11.585Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "300117743",
        "name": "Jay Chou",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jay-chou/300117743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/70/91/99/709199a5-0722-1648-0697-a00f1691da9c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "471260289",
        "name": "Harry Styles",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/harry-styles/471260289",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/00/8f/26/008f26ce-ec78-ad42-9303-260ac3b9701c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "320569549",
        "name": "Justin Bieber",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/justin-bieber/320569549",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3a/c1/a3/3ac1a308-06fe-f693-ea95-09ca2b2c88a1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "883131348",
        "name": "BTS",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bts/883131348",
        "editorialNotes": "The K-pop hitmakers reflect on their massive international success with Zane.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "979458609",
        "name": "Olivia Rodrigo",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/olivia-rodrigo/979458609",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/34/dd/36/34dd3678-40c6-9d8b-fa0f-cb6d82d3103b/6c46071d-e4d2-4671-a110-6ad83a63b89b_ami-identity-5b05d351a6e3c7256ef7680c8aef2894-2023-06-30T03-58-10.754Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1141774019",
        "name": "BLACKPINK",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/blackpink/1141774019",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/91/31/36/91313698-9306-c1a5-5b9c-d429b3d0800d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "549236696",
        "name": "Travis Scott",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/travis-scott/549236696",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2a/a2/bf/2aa2bf4d-058f-8142-bb91-1082f0a3f77f/01a7e7e2-3be8-4c6d-9c69-79c2cdc9bbcb_ami-identity-37880238a23a47d53d01a6f4e67f0167-2023-07-21T03-39-41.660Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "358714030",
        "name": "Imagine Dragons",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/imagine-dragons/358714030",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/83/bd/b7/83bdb7ff-63a6-2252-e04b-ece8ebdc5768/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "830588310",
        "name": "Doja Cat",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/doja-cat/830588310",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/54/ba/99/54ba9945-5aae-bec7-a80c-188de630b37b/d62857dc-e8bd-41f6-b4d5-e368875c6044_ami-identity-7d4c30a95a90f129a834aecd444aa07a-2023-06-16T14-02-41.627Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1213405916",
        "name": "Lewis Capaldi",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/lewis-capaldi/1213405916",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/fd/de/46/fdde467c-c035-bee4-2ada-874a64dd82c4/a233b0ce-4154-4146-a927-3d9614c0e5a9_ami-identity-0695df847f31eee237f30b0199060c4b-2023-01-19T17-15-55.224Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "935727853",
        "name": "Camila Cabello",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/camila-cabello/935727853",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/fa/9b/ea/fa9beae1-53a4-6b88-562f-83c88bf8cb67/mzl.bbflqfpi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "320366644",
        "name": "Ninho",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ninho/320366644",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/13/c9/fa/13c9fab6-3741-f310-f73c-3f72a239f79b/mzl.mpqklznm.jpg/1000x1000bb.jpg"
    },
    {
        "id": "471744",
        "name": "Coldplay",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/coldplay/471744",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/d0/3a/2a/d03a2a42-75f3-4fe1-da87-6588c5de12ef/635bd7cc-c6d3-4610-8f38-85b4a1cf3578_ami-identity-627a3a5a7feb2fd65e55602f252c9aa8-2022-11-11T12-07-04.512Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "580391756",
        "name": "The Chainsmokers",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/the-chainsmokers/580391756",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a1/df/f6/a1dff612-65e9-36ce-1167-c2ec7bee2899/7c048695-1009-4525-8f5a-c1319b768fc9_ami-identity-27852ea90adda198875408ceb0169bd5-2023-07-24T19-18-54.847Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1488123516",
        "name": "Tones And I",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/tones-and-i/1488123516",
        "editorialNotes": "The singer tells the heartwarming story behind “Fly Away.”",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ee/9d/ee/ee9dee79-5a3d-f600-b78c-25d0a8b3d074/832f7344-7648-4834-8f9a-b8a8f9cbad7e_ami-identity-0c3049130302aff34b3352967b9a365a-2023-07-14T03-44-26.663Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "829142092",
        "name": "Morgan Wallen",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/morgan-wallen/829142092",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/44/75/a2/4475a2f6-ed76-c387-7f40-a183001bcb73/a35ed868-577d-467a-8078-11f6f9bb68a7_ami-identity-9e8a6aedf0dca3c60ecb0d44b67ae82b-2023-10-25T14-30-17.048Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1798556",
        "name": "Maroon 5",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/maroon-5/1798556",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c3/3f/9a/c33f9a77-7395-bdfe-8068-a4b66d26531e/3ea423ea-6cf0-498a-a692-cc58da556f11_ami-identity-06acd526b8e5ef0283735a165e8eed70-2023-05-19T16-33-45.049Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1450601383",
        "name": "Pop Smoke",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/pop-smoke/1450601383",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e5/48/88/e5488851-ff42-f0ee-5466-899463fc2ef8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "111051",
        "name": "Eminem",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/eminem/111051",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/87/8e/a0/878ea0a1-ed8d-6fd7-3567-76ab8e6529af/mzl.ayuccyos.jpg/1000x1000bb.jpg"
    },
    {
        "id": "277293880",
        "name": "Lady Gaga",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lady-gaga/277293880",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/2c/7d/5e/2c7d5ec0-bad6-5fb6-08f2-ccfd94aa2985/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1400730578",
        "name": "Lil Nas X",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-nas-x/1400730578",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/cc/71/d7/cc71d772-a807-6943-b4e2-c1b92b7b99b8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "960568308",
        "name": "OFFICIAL HIGE DANDISM",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/official-hige-dandism/960568308",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/42/f2/a2/42f2a2ab-73ea-44e2-f21d-7b88fbb0d11c/274158a9-18b2-4753-bd10-018ef58f6962_ami-identity-5dd7e8ab9c68d25738a5acba342f25a5-2023-08-21T08-58-13.268Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "262836961",
        "name": "Adele",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/adele/262836961",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/a2/2d/df/a22ddf71-9254-043c-c162-11fbc25c5ff3/mzl.ilbjswky.jpg/1000x1000bb.jpg"
    },
    {
        "id": "157749142",
        "name": "DJ Khaled",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/dj-khaled/157749142",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/b6/68/c3/b668c391-9c1a-3bf2-43d2-af1aaebbb8a4/764d2954-f968-4404-9d23-cba40d825d9e_ami-identity-1ec7047ad438e6af95952f6cd8ec7e2b-2023-08-11T04-43-54.842Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "336249253",
        "name": "Charlie Puth",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/charlie-puth/336249253",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/42/ce/04/42ce04de-abfa-c9f4-c46c-f4a7defd5fcf/8c367458-cd76-452a-a6e4-e945ccd379c7_ami-identity-369af9b60942ad734b4d9e5e8a801af6-2023-08-21T17-34-34.802Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1203816887",
        "name": "TWICE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/twice/1203816887",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/89/1b/81/891b816f-1e6f-0a87-28e8-420a11ebe4b6/4c4056f5-1048-48f2-bad8-5579a8e56490_ami-identity-cd0f1d20c8c852a423f4fcf92fac6ef7-2023-01-16T02-55-55.339Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1082533559",
        "name": "XXXTENTACION",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/xxxtentacion/1082533559",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/2d/fc/65/2dfc6540-f90e-c244-4942-1fa0d3d88a72/mzl.hvlfukta.jpg/1000x1000bb.jpg"
    },
    {
        "id": "137057909",
        "name": "Miley Cyrus",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/miley-cyrus/137057909",
        "editorialNotes": "A wild and soulful pop sensation who regularly reinvents herself.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a2/40/5d/a2405d85-0fca-dc5b-a157-0b7769421337/8bc5bafb-83fe-4074-bb9d-baf713a1cedf_web-artist-image-8bc5bafb-83fe-4074-bb9d-baf713a1cedf-1692378002538_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "605800394",
        "name": "SZA",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/sza/605800394",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/78/a9/b3/78a9b3fe-3694-c068-09c5-673fe0e46f38/mza_5912257883088514867.png/1000x1000bb.jpg"
    },
    {
        "id": "1165017710",
        "name": "Aimyon",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/aimyon/1165017710",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8b/f8/21/8bf8218f-eca3-9694-ef7a-78879ae7262d/2137a85b-dcc9-412d-9042-193a754d2a67_ami-identity-f91319429fe1b75335802a50d69c444e-2023-03-14T09-50-50.222Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1490256993",
        "name": "YOASOBI",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/yoasobi/1490256993",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/4a/9e/6d/4a9e6d1e-0c67-de51-4df2-cf2ad0499698/mzl.lminplsj.jpg/1000x1000bb.jpg"
    },
    {
        "id": "890403665",
        "name": "Shawn Mendes",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/shawn-mendes/890403665",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/32/95/a2/3295a290-e68c-528d-8319-ac392e34695a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "82842423",
        "name": "Khalid",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/khalid/82842423",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/37/7f/82/377f82b2-d145-5ce4-db4f-2bc434d4d6ee/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "448916501",
        "name": "Maluma",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/maluma/448916501",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b1/70/64/b17064b0-5c1b-beb4-1b5d-45d2424b45d4/fe79a231-6d93-454a-a573-69a2ac7aa88d_ami-identity-ab0d92ed952b7a06da172e269f7ff196-2023-08-25T04-35-23.337Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "956078923",
        "name": "Cardi B",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/cardi-b/956078923",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/68/c8/6c/68c86c72-57f0-4f2e-dd72-7bb4e4d00250/74876a97-cc07-4c08-8b42-8e018fa1bceb_ami-identity-40aec5ea169fb61aebea13ddc29d6da1-2023-09-08T21-16-43.252Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "95705522",
        "name": "Chris Brown",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/chris-brown/95705522",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/56/3d/0c/563d0c83-001a-f8f2-6617-89233e2b0863/ec208af4-633a-433b-831d-085c2e9ed8f6_ami-identity-bd174f18b0e986781dbcc3955eebb0fb-2023-11-09T17-55-13.252Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "128050210",
        "name": "Future",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/future/128050210",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/c2/db/82/c2db8258-65df-a3b5-a69e-38bebb0b956c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "156488786",
        "name": "Sam Smith",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/sam-smith/156488786",
        "editorialNotes": "“When I look back at this album, it reminds me of the courage it took.”",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/de/0c/5f/de0c5f88-722a-32d8-ff95-51ea2d98cd33/9fd6e963-6b85-446e-aeb9-871641d96659_ami-identity-a9d9c72c23307ab2a64018246fe99b36-2022-11-11T11-06-33.418Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "463049461",
        "name": "Clean Bandit",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/clean-bandit/463049461",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/69/04/cf/6904cf36-b391-6f67-1867-405001e65193/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1276656483",
        "name": "Lil Baby",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-baby/1276656483",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/18/4c/81/184c81bb-cedb-2f49-0b6f-ed5bc7805647/f5c5add6-2dbb-4e3d-bfd3-22064dc590b4_ami-identity-90063e2844a90b2c24595bab4b3e50d0-2022-10-12T17-56-32.639Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "250647018",
        "name": "Jul",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/jul/250647018",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/b1/c3/fe/b1c3fe1f-9d26-3659-a943-f98b157b53c6/0ae223a6-e5b3-4797-9a8d-9b34cd2e8d82_ami-identity-10979f2d3b77c0faf0f0b23a9dee14ab-2023-11-23T17-25-04.862Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1635469693",
        "name": "NewJeans",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/newjeans/1635469693",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/27/75/6a/27756aad-9020-d361-bfdc-a7a7670f077b/6e15cab0-aa8e-4595-aaa9-f07fed6b66ec_ami-identity-10ffb209545338d630cc58ea79bf639b-2023-07-07T04-29-23.058Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1175595427",
        "name": "DaBaby",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/dababy/1175595427",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/9a/ee/a0/9aeea0fb-2910-c025-f5e3-bc4e204d7a0b/25789f1d-345b-4372-a8a4-9d36d9c8f508_ami-identity-e3154fec864836f208f3fbc13a124599-2023-09-19T21-56-25.758Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "278873078",
        "name": "Bruno Mars",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/bruno-mars/278873078",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/6d/ad/28/6dad2828-52c4-01dc-8e33-3ad3c05b73fd/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1265164818",
        "name": "Ava Max",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ava-max/1265164818",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a2/12/17/a212172a-ff4d-4937-4e71-851b9d8ef59b/4b5eb0f8-4058-43d5-97ae-f92dda211944_ami-identity-d6ea6450f3ab37c4e6e9d86d10607fec-2023-07-15T00-40-13.350Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "283578837",
        "name": "Ozuna",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/ozuna/283578837",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/27/48/8b/27488b6e-ecf5-33af-7658-3863b2b20629/1f9312e3-fe86-42d4-a920-2700ba4be1d5_ami-identity-9486929e66e5b63df2ca05cb5f997ab2-2023-11-17T16-27-20.837Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1312874741",
        "name": "Måneskin",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/m%C3%A5neskin/1312874741",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ec/27/79/ec2779ad-cabf-d849-cf30-9a7fad7b0938/07f9494d-77fd-4916-ab8e-89d809a09a69_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "2715720",
        "name": "Kanye West",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kanye-west/2715720",
        "editorialNotes": "Watch a clip of Zane Lowe's upcoming exclusive interview with Kanye West",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/6b/ca/47/6bca47fd-8a58-0652-8de8-475394e8159d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "260414340",
        "name": "OneRepublic",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/onerepublic/260414340",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/2e/aa/27/2eaa272b-2858-e27e-ada8-5b7dcf230ef3/a7bbe712-552a-44c2-9904-316a2703a768_ami-identity-01edebc56b5ef43e03197b0481a0ce28-2023-05-02T23-17-00.709Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "300300595",
        "name": "Bradley Cooper",
        "genreNames": [
            "Soundtrack"
        ],
        "url": "https://music.apple.com/us/artist/bradley-cooper/300300595",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/86/aa/04/86aa04f6-3ad7-4815-9d16-5b544114375d/mzl.yvmakgqp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "528928008",
        "name": "Glass Animals",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/glass-animals/528928008",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/06/b3/09/06b30967-8ba7-2206-79d4-e2011f18d599/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "962221033",
        "name": "Mrs. Green Apple",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/mrs-green-apple/962221033",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/cb/87/34/cb873420-4b21-a1cd-9d93-7bf50137ecb6/mzl.jmixhrpf.jpg/1000x1000bb.jpg"
    },
    {
        "id": "280215834",
        "name": "Selena Gomez",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/selena-gomez/280215834",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/65/19/2c/65192ca6-d2ac-ebc3-b3de-2743f79c4d28/8a90920d-927c-4520-a916-0db462510077_ami-identity-9dc79b57efd6f0e1ede00c6af8f8954c-2023-08-17T19-18-12.170Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "324916925",
        "name": "Halsey",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/halsey/324916925",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b2/83/a2/b283a2b7-68e7-7dbc-99f4-3eecc3ff6674/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "815635315",
        "name": "Luke Combs",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/luke-combs/815635315",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/95/da/47/95da473e-77b4-0d84-ab38-28532d1db6d9/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1042004371",
        "name": "Aya Nakamura",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/aya-nakamura/1042004371",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/e3/56/0d/e3560dbf-9946-ebde-9c27-4ae8b65ccd7a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1368733420",
        "name": "Juice WRLD",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/juice-wrld/1368733420",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/42/04/ae/4204ae87-e800-6b47-d95c-068e3bd52995/mzl.gwhtpegi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1258439196",
        "name": "King Gnu",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/king-gnu/1258439196",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/3c/5e/b3/3c5eb3f4-2b74-726a-a267-43b3ce2c7c28/mzl.xrvkmjkv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "298496035",
        "name": "Avicii",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/avicii/298496035",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d3/2d/90/d32d9025-d64d-4c80-e9d0-95f01aaec84c/16ec94ea-5c88-4f3d-a925-6c80c45ba8dc_ami-identity-f8a07b8e5cc0d3da04ed7287af5e73bd-2023-04-19T15-49-01.209Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1212442072",
        "name": "Roddy Ricch",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/roddy-ricch/1212442072",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/92/8a/69/928a6956-eefd-8d1b-2a93-96df908c0cfb/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "591899010",
        "name": "Burna Boy",
        "genreNames": [
            "Afro-fusion"
        ],
        "url": "https://music.apple.com/us/artist/burna-boy/591899010",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/8e/9a/c0/8e9ac08c-502d-96c0-25bf-9e744c16fb57/mza_9415001741371574454.png/1000x1000bb.jpg"
    },
    {
        "id": "1394262961",
        "name": "Apache 207",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/apache-207/1394262961",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/03/fe/45/03fe45a4-86da-c6b3-ef40-3842ace7877e/7c931fe6-77fe-45c4-b1c9-7de67bf516ab_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1419227",
        "name": "Beyoncé",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/beyonc%C3%A9/1419227",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/e2/10/a7/e210a754-3409-4e42-8fd9-413c1289cbb9/mza_3319038547447377908.png/1000x1000bb.jpg"
    },
    {
        "id": "201955086",
        "name": "Calvin Harris",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/calvin-harris/201955086",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/8a/bc/b3/8abcb332-aef8-4f31-74be-793bae82a8ff/7960bec4-5264-4909-b809-dc00cbaf82f3_ami-identity-07148079fe60667b8de31c2063ceb13f-2023-03-09T01-46-06.400Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "302361237",
        "name": "back number",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/back-number/302361237",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/c0/4d/aa/c04daa34-f7a1-8d8a-bac7-a9079891571e/mzl.fjhfrlwn.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1168822104",
        "name": "YoungBoy Never Broke Again",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/youngboy-never-broke-again/1168822104",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/7c/df/06/7cdf06b3-ef17-9a55-2e08-91340e50005c/252eb4a1-a7ae-492b-b924-23b434c85229_ami-identity-aedc1f00c95245e01421552039555b2a-2023-11-10T22-19-52.264Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "28721078",
        "name": "Sia",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/sia/28721078",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/b0/9f/f4/b09ff4e5-fed5-d8d7-37ef-93bf169c13cc/a6f6e899-fd34-4e25-b4fe-cee772ece143_ami-identity-e481968227e39f49322f9ea36d4ae0b8-2023-09-13T18-38-38.270Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "5557599",
        "name": "David Guetta",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/david-guetta/5557599",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/d4/0c/2d/d40c2d6c-9ebc-b1b3-6cd8-36a9fb9d4eef/mzl.jynpmslg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "102834",
        "name": "Luis Fonsi",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/luis-fonsi/102834",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/57/5c/7b/575c7b2e-dec0-b316-10bd-451f2f9d81e9/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "300117743",
        "name": "Jay Chou",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jay-chou/300117743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/70/91/99/709199a5-0722-1648-0697-a00f1691da9c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1446365464",
        "name": "Tate McRae",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tate-mcrae/1446365464",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/74/a1/57/74a15767-5217-7879-4653-77228e1a7875/420699b5-ed1e-4d6d-ade7-7cf50ff93f99_ami-identity-11b6c33ddb521791b7ba1dd8c4dcf764-2023-12-08T04-31-43.953Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "252239625",
        "name": "ONE OK ROCK",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/one-ok-rock/252239625",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/24/81/65/248165e4-529a-9bef-8131-2ebf2ef749c2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "444520760",
        "name": "J Balvin",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/j-balvin/444520760",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/00/49/4e/00494e2a-bb0e-41f6-c5e3-5bcda5a15632/1a4a96b1-436c-4796-9c0b-0cf3b87da1df_ami-identity-2477920ef7d821e53b230e4ca07b3e3e-2023-12-08T16-45-04.554Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "368183298",
        "name": "Kendrick Lamar",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kendrick-lamar/368183298",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/ab/50/ef/ab50ef3b-c936-44f0-7b01-bb113a133547/mza_5527770582281912184.png/1000x1000bb.jpg"
    },
    {
        "id": "475816358",
        "name": "French Montana",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/french-montana/475816358",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/10/3c/e1/103ce1ca-c669-27d5-7960-58197c7b2070/mzl.axlpseub.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "1487570516",
        "name": "VAUNDY",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/vaundy/1487570516",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/05/91/d0/0591d084-3bff-7528-13c2-cbdeae8b4840/d6e7ec11-364b-4905-a386-a2b114101fe2_ami-identity-e49a18916cabcd58424be6c4be1f6de4-2022-12-31T15-11-43.553Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1133442919",
        "name": "SAINt JHN",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/saint-jhn/1133442919",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/93/e4/73/93e47361-96bd-4e4b-64af-55776f0626ce/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1001696240",
        "name": "Joel Corry",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/joel-corry/1001696240",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/85/9d/f0/859df046-0eea-c194-6465-c07d7c629542/9a608782-33c7-4d4f-bbd7-b40427311774_ami-identity-65c8093992672f871b9644298163e35d-2023-08-27T11-10-34.850Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "3296287",
        "name": "Queen",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/queen/3296287",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/92/51/6a/92516a08-4257-36f8-a195-671a667bf86c/mza_8897456724926114550.png/1000x1000bb.jpg"
    },
    {
        "id": "472949623",
        "name": "Lizzo",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lizzo/472949623",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/3b/64/46/3b644621-1a86-480c-9705-d1c775651c68/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "290814601",
        "name": "KAROL G",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/karol-g/290814601",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/18/e8/41/18e841cb-854f-38fa-5405-90fee74efaed/fe81a148-df40-49db-87de-3da6d9014a7e_ami-identity-d59cbd95617167e768f2dbfe6377cf2e-2023-07-14T00-02-09.906Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1489331027",
        "name": "Yuuri",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/yuuri/1489331027",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/b2/2a/15/b22a1502-1057-f2bb-08b7-f7ce8f7cf17c/mzl.cplhdmbs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "940710524",
        "name": "Lil Uzi Vert",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-uzi-vert/940710524",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/60/34/22/603422d2-2e91-10ea-6212-7b7b56b32ce9/d5583d12-91c4-40ce-b81f-f6af9ab5ae10_ami-identity-90c70b0a82de3a94a46c3ad23b22d0e4-2023-06-30T02-55-20.668Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1177984235",
        "name": "Capital Bra",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/capital-bra/1177984235",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/be/6f/98/be6f9884-70e1-ced2-f43f-bdf1ec668967/mzl.screrhbg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "73705833",
        "name": "J. Cole",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/j-cole/73705833",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/53/4d/1e/534d1e59-8d27-7310-a142-71f38bec69c0/mzl.iiwjfsye.jpg/1000x1000bb.jpg"
    },
    {
        "id": "837847154",
        "name": "Skryptonite",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/skryptonite/837847154",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/31/e8/5a/31e85a27-939f-a7a7-3fde-09c79348b777/9edc6f30-efbf-4c12-b8d3-1a799d1982d8_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "125742557",
        "name": "DJ Snake",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/dj-snake/125742557",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/09/68/06/096806fd-02e9-45e0-5c78-8acc93f9b0ef/mza_12541734581481939892.png/1000x1000bb.jpg"
    },
    {
        "id": "114342891",
        "name": "Jonas Brothers",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jonas-brothers/114342891",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/20/88/45/2088455d-1f58-a8da-72bd-9b0db006c919/6247d769-0c58-47c4-8796-974c17e5df35_ami-identity-4d2a0a0710c584a456fcad4306438b7b-2023-02-04T00-24-06.968Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "259118085",
        "name": "Jason Derulo",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jason-derulo/259118085",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ae/1c/eb/ae1cebfd-b285-26bf-431a-37e5dc8db9cb/mzl.ecbegmjj.jpg/1000x1000bb.jpg"
    },
    {
        "id": "194502100",
        "name": "Feid",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/feid/194502100",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/9f/c3/8a/9fc38aba-bc58-a831-f5b0-b1281e588099/744ee554-b72a-4956-9384-274321c6b9ce_ami-identity-3b6171ac786da31711952722cc41532a-2022-12-01T22-27-49.045Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "705812852",
        "name": "George Ezra",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/george-ezra/705812852",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/92/fe/7d/92fe7d0d-cc8c-b45f-a201-c0f15f0dfba1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1236267297",
        "name": "Gunna",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gunna/1236267297",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/80/a6/37/80a637c6-58c6-f837-4ede-f9937fa2e3bb/085c855c-8d4b-433d-8131-4f15c2a03e68_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "355898104",
        "name": "Rita Ora",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/rita-ora/355898104",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/42/db/8f/42db8f50-c8c3-d6a5-6aa6-93e7ebc7d979/c3762cbc-989f-4244-90b2-7f84935eb8ad_ami-identity-6fe0276aafa5b41bc3d6bcb242a35cbb-2023-04-19T13-09-12.440Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1258256228",
        "name": "6ix9ine",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/6ix9ine/1258256228",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/b9/8d/e5/b98de5e6-2471-65a3-f393-6da1e141d612/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1047679432",
        "name": "Jack Harlow",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/jack-harlow/1047679432",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/53/75/cc/5375ccad-4b82-14d9-5ec2-fbb115a2a54b/58260d2f-76b6-42e0-a341-ab18d43395de_ami-identity-3ff3598bfa72166b015e2de21b1793d1-2023-11-10T01-46-30.297Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "973181994",
        "name": "ZAYN",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/zayn/973181994",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/15/54/30/155430d1-1348-37cc-0c55-0033197d5925/a8aa25cd-ae65-4560-a5d6-5a5ee7170739_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "405728790",
        "name": "Jonas Blue",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/jonas-blue/405728790",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/60/1c/a4/601ca411-2fb6-74bb-9c94-7059c6467000/42048c26-ee98-463b-9642-166c36cf5a7b_ami-identity-05f1f190bec7cc15f5ab17ec519dd949-2023-09-19T11-04-06.393Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "541282483",
        "name": "Lil Durk",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-durk/541282483",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/e6/b3/81/e6b3811d-4438-683c-1d9e-9b9c74b7898d/mzl.ehbuouad.jpg/1000x1000bb.jpg"
    },
    {
        "id": "4488522",
        "name": "P!nk",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/p-nk/4488522",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/14/f7/1e/14f71ec1-a9cf-7959-38c2-e3694d9b2196/e5042bea-9ec8-4719-a292-a6915c52e016_ami-identity-b0e41d97ff203955030fe0623b5bccf1-2023-03-09T20-11-06.828Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "799597",
        "name": "Michael Bublé",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/michael-bubl%C3%A9/799597",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/9f/74/ff/9f74ff92-a446-7d26-9145-8334e8898fb6/mza_14500817776938111649.png/1000x1000bb.jpg"
    },
    {
        "id": "980795202",
        "name": "Marshmello",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/marshmello/980795202",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/5a/7c/d0/5a7cd08b-e16e-4b46-cdc9-0c6e1c938897/c10cf5b1-957a-4e0f-8f3d-386e18dd36e4_ami-identity-ddcf775f0338963084776f074c513067-2022-10-14T07-51-39.766Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "91853",
        "name": "Mariah Carey",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mariah-carey/91853",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/3d/33/fb/3d33fb6f-ead7-4886-3624-e58b2dc0478d/18dd4e00-f13f-4bd2-99ef-4a52a114f92f_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1540251304",
        "name": "aespa",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/aespa/1540251304",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/cc/9b/83/cc9b830e-f046-4331-9749-34cb00e6edb0/7d6c5761-13c4-423c-aac0-2c6fbbc8cd14_ami-identity-a7ba7a1dcf176f3a97d5a241459fcb51-2023-11-09T01-30-17.732Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1140623439",
        "name": "Rod Wave",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rod-wave/1140623439",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/d1/c7/0e/d1c70ebe-efb5-445f-82a8-761d6bf9a299/mzl.dhnnumrm.png/1000x1000bb.jpg"
    },
    {
        "id": "779085108",
        "name": "Dean Lewis",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/dean-lewis/779085108",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a4/5b/a4/a45ba4f6-ba5c-ef06-8362-cc41a765f180/d72d85bb-32d1-4727-9bd8-8a7a19b2f90d_ami-identity-328fa2a536c5bbe49ca14d8daf54ceb9-2023-09-27T01-11-02.457Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "25514958",
        "name": "Daddy Yankee",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/daddy-yankee/25514958",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1a/b0/56/1ab056ee-4525-25ad-bc1e-22883b68b5f0/e467137f-a05f-45fc-a8dd-b2111bb8cf83_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1159371412",
        "name": "Polo G",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/polo-g/1159371412",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8e/93/b3/8e93b3c3-94ba-a3b9-0100-ddef6ec1791c/a020d9b3-1c2c-461b-848d-4e1a678b4166_ami-identity-c5c74239ef378a7af3598d8b60d044d3-2023-08-17T22-23-34.057Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1331377493",
        "name": "24kGoldn",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/24kgoldn/1331377493",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/1a/6d/8a/1a6d8a16-a234-0ca8-577b-088a9ddf6f13/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "158038",
        "name": "Fleetwood Mac",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/fleetwood-mac/158038",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/1d/51/17/1d5117c7-86de-3075-487c-bbfd06ed4b52/mza_8192933590323373427.png/1000x1000bb.jpg"
    },
    {
        "id": "982612996",
        "name": "Lauv",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lauv/982612996",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a9/27/ec/a927ec32-a197-e902-875f-b615734ea1ac/ab189792-40c1-475a-b14e-fcceaff05a01_ami-identity-ddeee229a34023afb97c518bfb244038-2023-07-26T17-24-57.991Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "628476824",
        "name": "Damso",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/damso/628476824",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/56/21/5d/56215d6c-d793-70f6-5c95-d43397eb5f4d/mza_12004611179923827742.png/1000x1000bb.jpg"
    },
    {
        "id": "4091218",
        "name": "Tiësto",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/ti%C3%ABsto/4091218",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ff/77/33/ff7733f9-533c-454f-ec31-738a166cdb8c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1492604670",
        "name": "Ado",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ado/1492604670",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/37/79/27/37792759-de09-5fa1-ff70-eea7b7e8fdbc/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "524929515",
        "name": "Tom Odell",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/tom-odell/524929515",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8c/2b/b1/8c2bb147-2be8-31b8-1f11-de8085fe046e/269bf626-aa88-45e6-8b4a-ae1d579b7666_ami-identity-f08038d0c69168c8e146047387aff4ae-2023-09-21T17-28-38.767Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "569925101",
        "name": "Migos",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/migos/569925101",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/d1/96/ba/d196ba79-80ec-e1c0-c669-c0568dc4a74e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1068300376",
        "name": "A Boogie wit da Hoodie",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/a-boogie-wit-da-hoodie/1068300376",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/7f/5f/22/7f5f22f5-f4b4-3d86-c94e-869e399c1abd/564d9348-13e6-4a35-bd8a-67a3a13a28d0_ami-identity-acf202bc3dfd2d8771ada011080ec39a-2023-09-06T16-10-39.150Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "313865761",
        "name": "Meek Mill",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/meek-mill/313865761",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ee/dc/0c/eedc0cfa-c71b-a01b-5c85-85b7b24bdfb8/mzl.owfpcewr.png/1000x1000bb.jpg"
    },
    {
        "id": "1107186883",
        "name": "Rauw Alejandro",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/rauw-alejandro/1107186883",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/d5/97/d7/d597d710-1627-8fc0-0fa4-10e56d3a767d/c33a7bdc-b7c1-4f33-beda-40858cc56dda_ami-identity-57f547f3f1f84f7abd00a2f0c282910d-2023-09-08T16-55-18.770Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "951024217",
        "name": "Regard",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/regard/951024217",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/ee/0f/47/ee0f47dc-442b-f31b-4670-5e81427eb86f/ec8fc6f0-27a0-464d-bfc7-848db22136a2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1972122",
        "name": "Luciano",
        "genreNames": [
            "Reggae"
        ],
        "url": "https://music.apple.com/us/artist/luciano/1972122",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/34/32/f1/3432f1dc-0f70-3f4c-24c3-3c2cfa24cde2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "474724108",
        "name": "Anne-Marie",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/anne-marie/474724108",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/17/2c/d9/172cd971-991d-c7b2-c3e5-e656acb9fd13/91b817fb-e546-4793-9310-da7010b56e24_ami-identity-dd31e6c49190f39e493d90fc71872029-2022-11-16T11-48-58.148Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "953921140",
        "name": "Kodak Black",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kodak-black/953921140",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/0c/0e/ef/0c0eef40-2f70-2494-36bf-5def806d35da/0d33cb6d-a2c1-4899-b095-cf31f5d443b6_ami-identity-54c02ea9f16b05ee8380e678351a4d1c-2023-11-14T03-20-20.247Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "727527367",
        "name": "Niska",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/niska/727527367",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/38/64/fa/3864fa6d-f1af-b30e-9568-917286cab551/mzl.qhmxiztc.png/1000x1000bb.jpg"
    },
    {
        "id": "1056294097",
        "name": "Mabel",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mabel/1056294097",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ef/77/8a/ef778acc-bb1e-4cf8-eed2-4991510d6025/9eea6745-f3e8-4944-9bb7-a5b4e589ae98_ami-identity-68fa91ef9f4ebb3bdfcbe110daaac72e-2023-08-29T12-52-49.316Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "538811449",
        "name": "5 Seconds of Summer",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/5-seconds-of-summer/538811449",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/96/61/b2/9661b255-ca1b-327d-6973-be4191c7f30a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "513595194",
        "name": "Dadju",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/dadju/513595194",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/63/9f/11/639f11d5-a8f3-e8a2-f57e-91001e0a9048/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "81886939",
        "name": "Young Thug",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/young-thug/81886939",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/3f/df/d2/3fdfd254-99ae-6e5b-2869-0f4ea94599a3/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1222992819",
        "name": "Zivert",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/zivert/1222992819",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e3/32/9c/e3329c15-9e73-baa3-d928-96538a3e7886/86672cc9-655c-4f1a-b7c4-dfe6d52d4588_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1435848034",
        "name": "The Kid LAROI",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/the-kid-laroi/1435848034",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d3/32/cc/d332cc4a-b0f5-1de0-0b9d-97d5e7832be6/b37c968c-c905-41d7-b14c-f7804e8a7068_ami-identity-2659880baed54ea2f54957bd0fc27164-2023-10-16T15-58-56.969Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1039356043",
        "name": "Gazo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gazo/1039356043",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8b/7e/4b/8b7e4bfe-8a7c-6934-c49a-41f6116987f6/f7532fb8-2d88-400e-a561-a95d05861564_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1449138676",
        "name": "Meduza",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/meduza/1449138676",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3a/bf/f2/3abff2e4-e063-17cb-6643-484a4327120f/acf9ff7a-0a7b-4d7a-b63c-7f55516a5fcb_ami-identity-878f41c1b75008bd45b979465fe194d8-2023-07-11T13-18-52.415Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1093500131",
        "name": "Dave",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/dave/1093500131",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ad/54/d3/ad54d32c-7547-0e39-255d-1ddfbbe55aa3/b4cba880-55e0-4152-98aa-899e7c85b654_ami-identity-c77848450af715803a987535fb9b8ebc-2023-06-01T14-05-17.849Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1198934067",
        "name": "Ultimo",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ultimo/1198934067",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/69/e3/53/69e353ce-fcbc-b10d-c17c-585f9efb103f/706bdabd-d2aa-420a-a72d-a39c5147969d_ami-identity-92732097a3afe970eb447b8746210c28-2023-07-21T19-19-35.646Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1436413980",
        "name": "Zach Bryan",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/zach-bryan/1436413980",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/a7/df/ea/a7dfea34-cc0e-0753-b663-48efaf4be5a2/mzl.xxcuvpqq.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "474022504",
        "name": "Rudimental",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/rudimental/474022504",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/a7/53/f0/a753f03d-5b46-8803-5a29-67a760fa1a61/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "26485617",
        "name": "Booba",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/booba/26485617",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/f1/c1/c4/f1c1c4bd-4d20-6520-f64b-f504c2beaabb/mza_7945573786055389980.png/1000x1000bb.jpg"
    },
    {
        "id": "1258989914",
        "name": "Megan Thee Stallion",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/megan-thee-stallion/1258989914",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/f9/6b/bd/f96bbd1a-2deb-3586-8a73-da81aef68a5b/98abea14-6074-48b0-88bd-ae2b49ccbadc_ami-identity-fde509e71f81cfa071a822f0f7dc41ec-2023-11-02T19-07-17.249Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1444702518",
        "name": "JONY",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jony/1444702518",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/96/df/86/96df8689-e11a-554e-e25c-87b342dcd593/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "394865154",
        "name": "Stormzy",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/stormzy/394865154",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ae/bc/03/aebc032a-dfd1-8262-2a9b-713aa11854d3/704469ad-4682-480e-8490-117b93e3d22c_ami-identity-944d2c1ad8398a2b38d49045f94fbce1-2023-06-22T20-45-21.946Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "193502798",
        "name": "James Arthur",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/james-arthur/193502798",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/d2/4e/26/d24e2604-dc15-7ba2-0e9c-e8dfa3df375e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "216635866",
        "name": "JJ Lin",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jj-lin/216635866",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/b9/64/5a/b9645a4b-b66b-a62c-5998-5c99c5bd6e9b/mzl.obbxkbai.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1248515752",
        "name": "Danny Ocean",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/danny-ocean/1248515752",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e5/51/40/e5514003-4689-040c-ba39-d5c9c4426860/fec3ff4a-359b-42f2-929e-305b84761563_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1114898204",
        "name": "Three Man Down",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/three-man-down/1114898204",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c9/19/03/c91903ee-f3b0-0542-7b17-0fc722671f0e/20fab163-2caa-45be-9ce3-e6d02b414eda_ami-identity-91bac5f030ed7eaf20c8dc4a865e9ee7-2023-04-25T03-39-49.682Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "364377482",
        "name": "Farruko",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/farruko/364377482",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/6a/a8/bd/6aa8bd7c-915f-0227-8846-50710b5caa2c/a98b9cec-a29f-4072-a77a-51c02cf73ecc_ami-identity-62397b8c53ee681b2a2eed5a05b2c583-2023-06-14T18-40-21.673Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "376564133",
        "name": "Dermot Kennedy",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dermot-kennedy/376564133",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/9a/57/42/9a57425e-e216-ea20-fb2e-f2dc5c783917/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1594159996",
        "name": "IVE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ive/1594159996",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e0/73/aa/e073aab8-c59e-9a1e-4684-c3698783c9e8/652695df-7e5d-4f2c-adb1-5ea1a22eef76_ami-identity-69240dba41883ac416d4effab06e1b2c-2023-10-13T03-42-00.363Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "573943518",
        "name": "LiSA",
        "genreNames": [
            "Anime"
        ],
        "url": "https://music.apple.com/us/artist/lisa/573943518",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/1a/b6/ff/1ab6ffa5-beca-cf99-6856-83f1eb243c8e/8073e2da-66a5-46b7-ad16-a653ac139f4f_ami-identity-f7fe5397d193305ef7f645abb56cd90b-2023-05-24T12-03-38.302Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "381979903",
        "name": "Dear Jane",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/dear-jane/381979903",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ee/27/d4/ee27d4e3-d857-27e4-5543-a710b46434d7/489eec16-3f4c-4e06-a6e2-ea0bd8659792_ami-identity-819a80f4bb5b2374a2702313a4463c2e-2023-06-08T03-12-18.469Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "948029024",
        "name": "Jah Khalib",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/jah-khalib/948029024",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/01/43/9a/01439abe-2157-3563-149e-9a36ac97f692/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "278464538",
        "name": "Nicki Minaj",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nicki-minaj/278464538",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/31/ff/09/31ff09dc-479c-e23e-006e-3810b12bc7da/4ffa51f7-98a2-466f-bc71-0d4a220dcb42_ami-identity-2b030140561304c28397671b03fd9e3f-2023-12-08T05-56-51.387Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1266061375",
        "name": "Dennis Lloyd",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/dennis-lloyd/1266061375",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/1a/8c/72/1a8c72a9-2640-8a24-daac-970a22a9d846/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1474403712",
        "name": "GAYLE",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/gayle/1474403712",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/87/44/ba/8744ba05-a01c-9b04-21c2-68aa11ad812e/5513a53b-d5cd-4577-a922-e7eeb7963086_ami-identity-5a77ba61ba35e69b016801c69b80b616-2023-08-17T21-48-49.957Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1509064270",
        "name": "Jawsh 685",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jawsh-685/1509064270",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/db/26/76/db2676e0-0a2f-d2ab-ec2a-477ab5441871/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "829284879",
        "name": "Loud Luxury",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/loud-luxury/829284879",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/33/bf/77/33bf77cb-a045-930d-204d-fd74f29bf5ab/5b7dbaff-fe64-4df6-9d81-8325ab09c0e1_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1363899746",
        "name": "Maes",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/maes/1363899746",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/cb/99/63/cb99630a-78c2-9ee9-a995-9e159d6911bc/mzl.cjsdctqs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1186116587",
        "name": "Manuel Turizo",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/manuel-turizo/1186116587",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/84/8b/1d/848b1d3d-7cf3-48a0-3b1c-becc57fa3b5f/a7975460-cf97-4984-8356-24eec841c42e_ami-identity-4535412cf1fdce568ee64eef9a988b9a-2023-10-26T15-08-18.639Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "903273139",
        "name": "Eric Chou",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/eric-chou/903273139",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/84/2b/c7/842bc78c-944c-0edc-6758-a511df136911/mzl.owmfftvp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1603420021",
        "name": "ANNA ASTI",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/anna-asti/1603420021",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/fd/9c/3b/fd9c3b1c-2417-08d9-080a-aa85ba5b0c2d/e0c0d2b4-ffea-4bc4-865c-9dafc856b428_ami-identity-6a3ef8d150874d058deff6b4e3ebea11-2023-04-26T12-54-08.478Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1451964345",
        "name": "ITZY",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/itzy/1451964345",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e6/54/46/e6544696-8bad-9b97-d9fd-77a078531163/59ece485-38b8-473d-b570-3f133987e094_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "396295677",
        "name": "Troye Sivan",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/troye-sivan/396295677",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/29/a6/7e/29a67ede-0004-7401-c388-d255c84dc40b/mzl.ejngdavp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "64387566",
        "name": "Katy Perry",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/katy-perry/64387566",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/4b/23/19/4b2319f0-d705-9c27-f6bc-ab127cb4b9cd/mza_656989125661554021.png/1000x1000bb.jpg"
    },
    {
        "id": "800263205",
        "name": "Bazzi",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/bazzi/800263205",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a6/b3/87/a6b38725-c544-8d4b-0a4e-376e1149dc7c/abcc16c3-0346-421d-bc10-a07a963e320e_ami-identity-7b7ceebfaee42cec1f3f502aca596736-2023-10-03T22-37-06.804Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "530814268",
        "name": "Kenshi Yonezu",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/kenshi-yonezu/530814268",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/9c/76/6a/9c766a84-fb92-ab89-5ef6-1a6e6141b46b/mzl.gogswajh.jpg/1000x1000bb.jpg"
    },
    {
        "id": "313845115",
        "name": "ROSALÍA",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/rosal%C3%ADa/313845115",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/eb/94/4a/eb944a40-9438-f7d2-1822-aae4a697c5e0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "996166916",
        "name": "Anuel AA",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/anuel-aa/996166916",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/eb/45/5c/eb455c75-ddf4-1a02-b03c-6cb474b8e8b5/433cc55e-bd11-4633-b202-ca332c5fec0e_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "80456331",
        "name": "Panic! At the Disco",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/panic-at-the-disco/80456331",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/f0/ef/c3/f0efc32a-192a-a0d3-3346-4ac87f8b17b2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "723844870",
        "name": "Marília Mendonça",
        "genreNames": [
            "Sertanejo"
        ],
        "url": "https://music.apple.com/us/artist/mar%C3%ADlia-mendon%C3%A7a/723844870",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3c/67/c1/3c67c148-b136-fe1a-c700-f4ffb987a24b/c0e3d484-e2c9-4636-9568-de1fc3a0eab5_ami-identity-3d37dc9544b0dc2053f2d58891db2ae8-2023-07-20T20-30-15.427Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "63346553",
        "name": "Rihanna",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/rihanna/63346553",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/14/9d/76/149d76d9-cca6-b8e1-146f-e95a9802db13/mza_2396005047706780053.png/1000x1000bb.jpg"
    },
    {
        "id": "1616740364",
        "name": "LE SSERAFIM",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/le-sserafim/1616740364",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/4e/22/30/4e2230a1-2739-bd59-1d3d-dbaeb663fdbb/16d7c1cb-62ff-42bc-9da3-4432c741fc1f_ami-identity-287a577e8436fb5d4109090a2ba96ce7-2023-10-27T04-07-53.289Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "132720094",
        "name": "Naps",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/naps/132720094",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8b/d5/f9/8bd5f9f1-a40f-dc20-5a1f-7c77c21791b2/64435f95-f687-45c7-8a0e-326467aeb763_ami-identity-59722d7f7478347f675ea0928b1fadd9-2023-04-25T13-58-48.484Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1427643780",
        "name": "Тима Белорусских",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/%D1%82%D0%B8%D0%BC%D0%B0-%D0%B1%D0%B5%D0%BB%D0%BE%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B8%D1%85/1427643780",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3f/a3/a9/3fa3a985-5ab8-d8f5-5b12-86222442aab2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "347433400",
        "name": "Robin Schulz",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/robin-schulz/347433400",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d2/ab/22/d2ab226e-5db6-d48d-7bc0-7eb5b4b4ff71/2708104c-ed95-4435-b1d5-9bc2102915a0_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "268893121",
        "name": "Tyga",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tyga/268893121",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/4b/5f/e6/4b5fe678-028f-b499-fd45-7059b42ef443/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "594135753",
        "name": "Vance Joy",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/vance-joy/594135753",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/44/d0/80/44d080b6-9c60-5de8-7574-9157eeb1defe/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1313094981",
        "name": "Masked Wolf",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/masked-wolf/1313094981",
        "editorialNotes": "Watch up-and-coming Australian rapper talk through his song, “Astronaut In The Ocean.”",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/dc/41/34/dc413470-4913-ee7b-d5e5-b2308035ad8c/588d5bb7-b12d-425d-85c7-a8dc6b5f9217_ami-identity-cdf2415e996ecf0cd4b284d958529cc0-2022-12-15T23-44-19.228Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "906961899",
        "name": "Red Velvet",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/red-velvet/906961899",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/15/13/d9/1513d993-4e43-9d00-3474-eb2b37bddb58/0b34944d-22c2-4fc8-aa30-f423c39a0241_ami-identity-1666b78174a80837b932d1506f9c6de4-2023-11-09T08-44-35.455Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "137938148",
        "name": "Eason Chan",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/eason-chan/137938148",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/75/18/cc/7518ccc2-97be-ccc7-6a31-6bde42af81fb/mzl.yfyaenpa.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1229933633",
        "name": "Saucy Dog",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/saucy-dog/1229933633",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/27/65/56/2765568d-87d9-c611-72c1-e5c89408c521/mzl.ybxelvpu.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1208839599",
        "name": "NCT DREAM",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/nct-dream/1208839599",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/7f/cb/fe/7fcbfe7f-50cf-d8a8-951d-a1b815e9b232/55fa0ca2-89ba-4556-bea2-45151f8b7420_ami-identity-4aa15572d79c4e920f9ca25e883000b4-2023-11-13T01-12-24.659Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "477515548",
        "name": "Little Mix",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/little-mix/477515548",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/19/7b/5c/197b5c34-28be-7a89-aa57-2d3e5d0946ad/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1052638496",
        "name": "Terence Lam",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/terence-lam/1052638496",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/9e/c2/34/9ec23446-8212-d374-493e-640fbd7627d7/a8e6b7cc-17dc-49dd-ae2b-384bc002f7ec_ami-identity-1f08684767f08b30d96e7088aa6930b4-2023-11-24T05-55-10.372Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1079597775",
        "name": "Christian Nodal",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/christian-nodal/1079597775",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e1/4f/9c/e14f9cc3-9441-4b06-0156-19e43b4c86bc/49adb12c-cd55-4e7b-88a9-bac95379befd_ami-identity-e9e30670e37565f0a3ef5fe0f61f66ba-2023-02-16T21-07-51.432Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "398128",
        "name": "Justin Timberlake",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/justin-timberlake/398128",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/c3/5c/d0/c35cd003-3afe-ec09-be1a-9bbbf62e3e98/mzl.wplqyahe.jpg/1000x1000bb.jpg"
    },
    {
        "id": "881651714",
        "name": "Sfera Ebbasta",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sfera-ebbasta/881651714",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/5d/c3/39/5dc33935-1c30-52cb-4a10-c7b5cbcc61d9/00edd0f6-f67b-47d8-b5a1-d19567416a1d_ami-identity-2034b37d651ec7b2caf9510c8bf702c9-2023-11-16T13-16-02.213Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1015463016",
        "name": "Ezhel",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ezhel/1015463016",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/0c/6a/b2/0c6ab2f1-1ae0-ff67-e2c6-9f2c717b2c5f/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "445782732",
        "name": "Ufo361",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ufo361/445782732",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/6e/af/04/6eaf04fe-01bd-ca08-5d58-09d1d2575224/2d49ffcd-f122-4299-a7ce-0a61156089f7_ami-identity-b5d5bc40f67e2f17a749943303cfa71b-2023-07-27T12-37-04.211Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1026196272",
        "name": "Arizona Zervas",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/arizona-zervas/1026196272",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ef/01/31/ef013108-c162-8445-cece-714cdfadbda0/8b8019b4-1502-42bd-b037-d31454dec1a1_ami-identity-bc100728025e9137cf7411a45c8db3ac-2023-07-13T19-20-23.788Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "894820464",
        "name": "21 Savage",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/21-savage/894820464",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/98/7e/a1/987ea1e2-ad4d-4961-95cf-f97466688c08/mzl.esxmnzmb.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1373339414",
        "name": "Angèle",
        "genreNames": [
            "French Pop"
        ],
        "url": "https://music.apple.com/us/artist/ang%C3%A8le/1373339414",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/35/35/46/35354614-fd57-7386-e1de-2a37aad180ee/f7bdcbb3-b9d1-4b10-acbb-8141bbd3956e_ami-identity-eaa3dcec307b4d755d72102243f07758-2022-11-18T14-45-27.762Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "15869915",
        "name": "Amr Diab",
        "genreNames": [
            "Arabic"
        ],
        "url": "https://music.apple.com/us/artist/amr-diab/15869915",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/bb/81/f2/bb81f214-ff94-40b1-c12e-445084f27a03/cb3db39c-b54c-48ce-bf7f-aa2f890e2153_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "956011835",
        "name": "Macaroni Empitsu",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/macaroni-empitsu/956011835",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3c/51/a6/3c51a6ad-2908-8bd7-8f19-5c42a64c3195/b8c65ac5-b1a0-436b-b200-caa750602c85_ami-identity-e6725f0342e4884019a4a51f70aeb4ee-2023-08-30T03-34-45.129Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1085149646",
        "name": "Central Cee",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/central-cee/1085149646",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/f6/72/1a/f6721a98-061c-0a1e-0667-3844ea135df9/14593247-a2bb-4d77-b44d-c0e3a41bf697_ami-identity-2ddb95c5c0c2230823a16b515e484c92-2023-06-16T22-03-22.531Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "14400049",
        "name": "Mark Ronson",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mark-ronson/14400049",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/d3/2a/5a/d32a5a8d-a2ef-d819-188e-1260c4abc88d/mzl.xrvyvwjw.png/1000x1000bb.jpg"
    },
    {
        "id": "1210225867",
        "name": "Myke Towers",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/myke-towers/1210225867",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/83/83/b7/8383b75b-367e-30f9-c6df-fe2dd5ee444d/19354ca4-7e23-4829-9e70-55457926ec56_ami-identity-96b3a53fede2eec1677bc74d4e032408-2023-01-09T20-52-16.229Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "315761934",
        "name": "Major Lazer",
        "genreNames": [
            "Electronic"
        ],
        "url": "https://music.apple.com/us/artist/major-lazer/315761934",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/2c/95/d1/2c95d16a-3da4-07ee-8367-678446784769/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "370571621",
        "name": "PNL",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/pnl/370571621",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/7e/48/cc/7e48cce9-756a-5c18-3bb6-d662df6fab60/mzl.bwjnqyrs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1275513130",
        "name": "Paulo Londra",
        "genreNames": [
            "Latin"
        ],
        "url": "https://music.apple.com/us/artist/paulo-londra/1275513130",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/e6/99/7c/e6997cb9-3f89-b21c-b5f7-434121ec4ef3/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "455690468",
        "name": "SDM",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sdm/455690468",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ca/08/9f/ca089f28-04d3-127f-b2b4-ea599f492fcd/mzl.aojiyzka.jpg/1000x1000bb.jpg"
    },
    {
        "id": "579050972",
        "name": "Sech",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/sech/579050972",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8e/96/a0/8e96a0d4-d584-1590-6f96-5db73522166a/dceea0c5-c43b-4888-a9ba-9f06ec6feeb8_ami-identity-546f94278e45b86cd7cfcd98c226a3c4-2023-06-29T14-11-26.908Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "316583534",
        "name": "PLK",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/plk/316583534",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/db/ec/b7/dbecb72e-f454-afc1-3b76-accc5118f3a9/99ec2c96-370a-49ce-b53c-4fa03d1e3487_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "901354715",
        "name": "Pinguini Tattici Nucleari",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/pinguini-tattici-nucleari/901354715",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/20/00/1d/20001db3-e226-7a76-6dad-d7fb570ecb34/mzl.nnstqfmg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1324137865",
        "name": "Trevor Daniel",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/trevor-daniel/1324137865",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/17/58/99/1758998b-464f-a8a4-d95f-6d132b801a90/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "818678567",
        "name": "Egor Kreed",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/egor-kreed/818678567",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/14/96/35/14963536-6a72-e34f-4601-14f8a66e541b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1213690168",
        "name": "Masaki Suda",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/masaki-suda/1213690168",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/cb/22/69/cb2269ab-5d4f-6571-60f9-80794c8aa855/mzl.fpqsxlnn.jpg/1000x1000bb.jpg"
    },
    {
        "id": "548421",
        "name": "Wham!",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/wham/548421",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/f6/20/5f/f6205fdd-a9e9-f2f6-9580-a5aa2cc89570/mzl.nmezbbic.jpg/1000x1000bb.jpg"
    },
    {
        "id": "440458549",
        "name": "Tory Lanez",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tory-lanez/440458549",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/0c/17/86/0c1786e8-25bc-9af4-8306-e7207e3f3289/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1334731593",
        "name": "Tiakola",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tiakola/1334731593",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/26/f6/ec/26f6eceb-70a1-9dee-e4ff-ea2b4c013d3a/mzl.qgerkrsl.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1062085272",
        "name": "Alan Walker",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/alan-walker/1062085272",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ed/2b/66/ed2b6697-02fc-0a39-36ca-949fa2ca82ea/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "281136240",
        "name": "RAF Camora",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/raf-camora/281136240",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/eb/98/cf/eb98cf0f-ef5d-1d9e-8ac3-22ac38b5ed71/dca6098e-5780-4e29-93ba-bbccb78abc99_ami-identity-ba6b0b0961c3f81d8a8f3b968749d6e0-2023-03-31T03-32-34.245Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "906961899",
        "name": "Red Velvet",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/red-velvet/906961899",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/15/13/d9/1513d993-4e43-9d00-3474-eb2b37bddb58/0b34944d-22c2-4fc8-aa30-f423c39a0241_ami-identity-1666b78174a80837b932d1506f9c6de4-2023-11-09T08-44-35.455Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "252981331",
        "name": "SCH",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sch/252981331",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/8b/dd/20/8bdd2009-ba71-e564-e6a7-b352bc659fb6/mzl.eldqriex.png/1000x1000bb.jpg"
    },
    {
        "id": "464296584",
        "name": "Lana Del Rey",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/lana-del-rey/464296584",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/99/da/66/99da66c2-f540-8406-0646-c95c85d64d04/e3d84d5f-c5b1-4769-8610-c0702bf35962_ami-identity-d8b71c87e7bea71100eb76a0935c9fa0-2022-12-12T15-36-57.679Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1304716885",
        "name": "Rema",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/rema/1304716885",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a7/8a/0b/a78a0b5e-ba8f-0c64-79e3-61c4623b85a7/2d30345e-51b3-46cd-a44d-c3855c3a6123_ami-identity-9e987f39368f9dd37966c136969aac14-2023-11-04T16-20-53.941Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "386478059",
        "name": "SIX60",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/six60/386478059",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/81/22/fa/8122faff-81e0-c7ab-ba4c-52d3ed75c836/mzl.jnhyddyp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1397025984",
        "name": "Blanco",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/blanco/1397025984",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e8/58/03/e85803ff-581c-0a7e-bce8-2ccef3a17ebb/c9486cd2-e5cc-47da-8550-bb1d3c68d754_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "429610343",
        "name": "HIRAIDAI",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/hiraidai/429610343",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c9/ae/93/c9ae9359-8a7b-aac8-1e6c-b1ebc1635569/427f0fe0-def4-430a-ae6e-eeecec1f2402_ami-identity-f94b731b1e5d2302eb4f2ce9206f769d-2023-06-23T05-47-17.874Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "6483093",
        "name": "The Killers",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/the-killers/6483093",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e8/58/47/e8584761-ab08-9346-43f4-bc558f5890eb/mzl.fwmasvui.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1062620760",
        "name": "Capo Plaza",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/capo-plaza/1062620760",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/2a/a2/51/2aa25127-2b0b-d348-aeb8-716a7370679c/mzl.cymcvnld.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "883131348",
        "name": "BTS",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bts/883131348",
        "editorialNotes": "The K-pop hitmakers reflect on their massive international success with Zane.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1206229860",
        "name": "Ali Gatie",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/ali-gatie/1206229860",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ea/6c/79/ea6c7998-2295-5e4a-5993-f5339ab1b6d7/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "30865945",
        "name": "Logic",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/logic/30865945",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/4f/10/1b/4f101b22-4449-c0b1-dc08-2b2d470e7080/c69945c8-abde-4411-af58-db7762e7a507_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "309335750",
        "name": "Wizkid",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/wizkid/309335750",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/e7/4e/be/e74ebe95-ed46-27bb-b4a4-1d0f847cf3ff/mza_12438118465786520848.png/1000x1000bb.jpg"
    },
    {
        "id": "1201228278",
        "name": "Jax Jones",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/jax-jones/1201228278",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/63/23/c1/6323c110-3b90-37bf-5401-b5569feca00b/11a39408-a21c-4693-abb8-474c54c5c5bd_ami-identity-a8e1212cecce49f9ab4b4fa8106d5d56-2023-02-10T09-36-20.968Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1370013949",
        "name": "Samra",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/samra/1370013949",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/cc/30/ac/cc30ac4b-a857-2944-7f88-de6e9b12972f/mzl.bgiyfssr.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1468456206",
        "name": "Keung To",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/keung-to/1468456206",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/be/5b/26/be5b264c-a2aa-d807-458f-8beb6acd03a7/mzl.jlingqua.jpg/1000x1000bb.jpg"
    },
    {
        "id": "420368335",
        "name": "Tyler, The Creator",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tyler-the-creator/420368335",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/be/51/83/be5183cc-87dd-3ca3-c082-3ccf228e8bf0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "425208570",
        "name": "G.E.M.",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/g-e-m/425208570",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/d4/bd/80/d4bd805b-104f-92bb-3657-5ac303272106/mzl.arclgcna.jpg/1000x1000bb.jpg"
    },
    {
        "id": "481488005",
        "name": "A$AP Rocky",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/a%24ap-rocky/481488005",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/57/45/b7/5745b7bd-44a4-e3f3-795c-3259e0cedef9/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1551258720",
        "name": "Werenoi",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/werenoi/1551258720",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/c5/90/05/c5900573-d896-766d-537f-6eee4159d3ff/mzl.cvydfoen.jpg/1000x1000bb.jpg"
    },
    {
        "id": "430932944",
        "name": "Martin Garrix",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/martin-garrix/430932944",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2b/b8/89/2bb889d7-010b-4c99-59b8-de222e35d193/49508512-8c65-479d-a4e1-373c439ed6a2_ami-identity-9eeac24988b26172713915be3bdfc283-2023-09-22T11-40-19.349Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "990402287",
        "name": "Summer Walker",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/summer-walker/990402287",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/69/0b/ac/690bacbb-7ccb-bc03-b25d-d83db752d91a/de3ed4c6-d856-4326-8c00-36bc0b901b95_ami-identity-94e8879f2274b24e35315437dd24f2c9-2023-09-18T19-10-56.938Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "967784356",
        "name": "J Hus",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/j-hus/967784356",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ed/03/a0/ed03a02d-1f80-812d-e9a1-01595316bd4e/mzl.tafvvqdl.png/1000x1000bb.jpg"
    },
    {
        "id": "883131348",
        "name": "BTS",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bts/883131348",
        "editorialNotes": "The K-pop hitmakers reflect on their massive international success with Zane.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "336904",
        "name": "Luis Miguel",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/luis-miguel/336904",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/7d/40/be/7d40beaf-6e21-d93a-c4cb-0b0c67a04b95/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "935689375",
        "name": "Tilly Birds",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/tilly-birds/935689375",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/19/a4/8a/19a48a80-bfd6-e7c6-732d-ba8059ef7563/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "647198652",
        "name": "Hins Cheung",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/hins-cheung/647198652",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ba/5d/ab/ba5dabe2-8076-1388-4e21-53d3bb18f9b5/mzl.yrkzryqd.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1053924796",
        "name": "Yerin Baek",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/yerin-baek/1053924796",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/28/ee/bb/28eebb9c-f894-c771-fabf-ffb7ae3d42a1/6ea272d6-41f5-462f-9893-43a2b13ce902_ami-identity-5b99601fd829a0ce9fcde1595099b42e-2023-01-02T05-17-01.442Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "471260298",
        "name": "Niall Horan",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/niall-horan/471260298",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/c3/ec/ba/c3ecba71-c321-0d47-785b-cbdca4de42d5/0d3aa04e-ac7b-4f70-a51b-3e5a4604e715_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1455932742",
        "name": "PASHANIM",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/pashanim/1455932742",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/6f/2a/7f/6f2a7fb8-4fb4-338a-89ba-d1d52d14b8dc/7054d91a-dce4-497e-9c62-7c3a0a07656a_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "881027285",
        "name": "Allj",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/allj/881027285",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/4f/2d/92/4f2d92d6-7a34-e7af-40df-c8d2be6264bf/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "29254083",
        "name": "Miguel",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/miguel/29254083",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/87/e7/08/87e708bb-c46c-575d-8ce1-8088a6f9e91e/967c0118-7733-4942-a830-0df27423519a_ami-identity-771187247b4d02692754b0d7ac72fcad-2023-08-11T16-05-32.674Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "216635866",
        "name": "JJ Lin",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jj-lin/216635866",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/b9/64/5a/b9645a4b-b66b-a62c-5998-5c99c5bd6e9b/mzl.obbxkbai.jpg/1000x1000bb.jpg"
    },
    {
        "id": "657630070",
        "name": "EXO",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/exo/657630070",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/84/c2/3a/84c23ae7-fa57-1567-337c-cd64239c8185/c0dfaa26-cd0d-4798-9580-f62d16b77445_ami-identity-435ed367dda58940fe99cf590d004bc9-2023-07-07T01-55-12.447Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "137938148",
        "name": "Eason Chan",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/eason-chan/137938148",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/75/18/cc/7518ccc2-97be-ccc7-6a31-6bde42af81fb/mzl.yfyaenpa.jpg/1000x1000bb.jpg"
    },
    {
        "id": "444579807",
        "name": "Gusttavo Lima",
        "genreNames": [
            "Sertanejo"
        ],
        "url": "https://music.apple.com/us/artist/gusttavo-lima/444579807",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ab/07/2d/ab072dbf-9027-9ada-0b2d-69719723ed0d/mzl.acuoesxb.png/1000x1000bb.jpg"
    },
    {
        "id": "417198364",
        "name": "Salmo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/salmo/417198364",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/4a/14/bf/4a14bfff-9fc7-512c-23a5-2925dc420037/mzl.kmiwetmg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1240341559",
        "name": "D-Block Europe",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/d-block-europe/1240341559",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/bb/37/8c/bb378c24-2a8d-f984-16a3-e754d218a896/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "403121923",
        "name": "Piso 21",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/piso-21/403121923",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/07/50/d2/0750d2a8-492e-d478-d5b0-f2249e93d1d6/d0c25b47-b541-4c44-9d43-d73db7fad394_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1448922098",
        "name": "UZI",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/uzi/1448922098",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/1d/15/b7/1d15b776-88b6-1528-8f38-9539897cf82e/41791169-dcd3-4db0-8fae-6d4f1a92ec06_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "855484536",
        "name": "Anderson .Paak",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/anderson-paak/855484536",
        "editorialNotes": ".Paak reveals how Smokey Robinson made “Make It Better” better.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/dd/f9/f4/ddf9f4fc-c6ef-0e80-acc5-3a071a2172b1/mza_14812811416096279163.png/1000x1000bb.jpg"
    },
    {
        "id": "1347757308",
        "name": "Powfu",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/powfu/1347757308",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/b7/32/91/b7329178-20f7-5e58-3df7-5f57e82d43dc/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "991187319",
        "name": "Moneybagg Yo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/moneybagg-yo/991187319",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/21/fb/cd/21fbcda0-cd69-4a26-abef-d1b046c14ab8/0d0c3ff3-8302-47c3-b0dc-f656643302ac_ami-identity-67ab99ae6761378c7cb9b5a0ae628eae-2023-04-21T04-57-56.068Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "5869117",
        "name": "Lil Wayne",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-wayne/5869117",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a1/22/7b/a1227bdb-98bb-bbbf-a025-d1f06a6b28ed/66f7c7aa-5f51-42a5-a92d-67122d602347_ami-identity-a65ba8774cde0e06f6b38b69c418c48d-2023-11-07T18-37-40.018Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1191438753",
        "name": "THE TOYS",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/the-toys/1191438753",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/9c/d8/4a/9cd84a57-b00f-c020-f941-a8e85484bfbe/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1168769616",
        "name": "CKay",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/ckay/1168769616",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3e/13/c9/3e13c963-0bea-c16a-b0bc-f66a327c89f8/0e57d87c-84cd-47d4-8250-2e1a9756755f_ami-identity-0a6129190c2518cff122be2dc5b0d3d7-2023-04-01T11-09-24.961Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "417691809",
        "name": "Hebe Tien",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/hebe-tien/417691809",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/f2/b3/93/f2b393fb-2a7d-0367-62ae-82086bb4d092/mzl.rtiltffg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "167649475",
        "name": "Portugal. The Man",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/portugal-the-man/167649475",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1e/d0/8b/1ed08b3b-12b8-eed6-d6c8-f1b61b321ce3/8fb6cfd9-40f4-424d-bddc-e13220df8df1_ami-identity-a1adb2e59ded8c55c3551a19c2718af9-2023-06-20T21-32-04.669Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1556737497",
        "name": "AYLIVA",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ayliva/1556737497",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8d/e2/47/8de24746-e395-499a-1e3a-6e00c33ba7fb/277643f8-ff5a-4765-abc1-c13daff9916d_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1258279972",
        "name": "Joji",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/joji/1258279972",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/ff/19/c1/ff19c1c0-bd5f-b5e6-3073-fcf2dc107f6c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1532003825",
        "name": "MC Cheung Tinfu",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/mc-cheung-tinfu/1532003825",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ec/45/13/ec45136c-609a-95b8-8dd9-3efb064c28ba/mzl.dldbkdmq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1416696196",
        "name": "EggPlantEgg",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/eggplantegg/1416696196",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/13/9b/bb/139bbbaf-149b-15e9-a7b6-3d03207be3e3/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1235849306",
        "name": "NCT 127",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/nct-127/1235849306",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ab/51/0b/ab510b3c-6021-4320-ef59-17cff2b3ed67/7740ee93-4db8-4f2e-ac51-313c5afcdd91_ami-identity-ed1f3087a233ad26eb688255bab23cc0-2023-09-27T03-35-51.313Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1193225731",
        "name": "BOWKYLION",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/bowkylion/1193225731",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ba/b4/ec/bab4ec91-cf13-f536-f54c-2bdb4b4489f4/61935e7a-9d12-4205-a76c-ada4b72ed712_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1199624018",
        "name": "RIN",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rin/1199624018",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/83/c5/e0/83c5e053-33ac-0295-36fa-8c4a87ea1ee1/mza_11901905220416438642.png/1000x1000bb.jpg"
    },
    {
        "id": "1657206517",
        "name": "MACAN",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/macan/1657206517",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/1d/0f/45/1d0f451a-08f3-c74a-2212-161af4f7e37d/8beabaa9-7a02-4c60-bd0e-e05f99b2f6e2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "366710817",
        "name": "Liam Payne",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/liam-payne/366710817",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/1a/9c/89/1a9c89f8-01a9-dc6f-b106-afecb319814d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "579281370",
        "name": "Rag'n'Bone Man",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ragnbone-man/579281370",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/49/db/19/49db1906-b013-d48b-6c1a-0e89f8159f3b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "837847154",
        "name": "Skryptonite",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/skryptonite/837847154",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/31/e8/5a/31e85a27-939f-a7a7-3fde-09c79348b777/9edc6f30-efbf-4c12-b8d3-1a799d1982d8_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "252282750",
        "name": "Coez",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/coez/252282750",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/6d/67/c1/6d67c144-2f23-4a3d-1ce3-ed2d9eabf857/dad78107-4213-4e14-806b-44739112a5c5_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1199669456",
        "name": "Tom Walker",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/tom-walker/1199669456",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/bd/bb/7a/bdbb7a6e-a3af-a7c7-c2cd-b62c5e86ca0e/8c2e66a0-d11a-43a6-8d8a-d52e832d3420_ami-identity-463f0e589dbc7f2d265c472f06fc7bc1-2023-09-21T17-36-36.391Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "128864409",
        "name": "Axwell Λ Ingrosso",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/axwell-%CE%BB-ingrosso/128864409",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e7/f4/bc/e7f4bc34-70a5-8f54-08b4-6ee06e22656a/mzl.xabakgdg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1019899019",
        "name": "Daniel Caesar",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/daniel-caesar/1019899019",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/fc/2e/88/fc2e88e3-4a70-5f32-6674-76eff2c2fded/f3376469-06f3-4378-838b-cfddf2bd71a8_ami-identity-cdd1dffd7a519f8b2bc6c0b48f239aa3-2023-08-29T20-07-56.290Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1594159996",
        "name": "IVE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ive/1594159996",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e0/73/aa/e073aab8-c59e-9a1e-4684-c3698783c9e8/652695df-7e5d-4f2c-adb1-5ea1a22eef76_ami-identity-69240dba41883ac416d4effab06e1b2c-2023-10-13T03-42-00.363Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "166747511",
        "name": "МОТ",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/%D0%BC%D0%BE%D1%82/166747511",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/5f/ed/f0/5fedf080-7a82-47c9-f9eb-8bdd77bc8fc5/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "408932",
        "name": "Rammstein",
        "genreNames": [
            "Metal"
        ],
        "url": "https://music.apple.com/us/artist/rammstein/408932",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/da/35/f1/da35f1b7-0963-0af5-b021-8669f62ca1cc/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1070668868",
        "name": "GIVĒON",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/giv%C4%93on/1070668868",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/f7/06/79/f70679a3-f6c2-78b2-add1-f905b89a826c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1362746533",
        "name": "BENEE",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/benee/1362746533",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7d/07/43/7d0743f2-d05e-4dab-02b7-dbe00414a0c3/f6eaa845-0bd2-4643-8d71-2d1d5602c082_ami-identity-af8411f7c180019cd3683c527e92edb7-2023-02-08T17-27-34.177Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "409076743",
        "name": "IU",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/iu/409076743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/41/04/45/410445f8-f70e-2d89-c26a-c3806d8e3f7c/mzl.uvpocmik.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1022677279",
        "name": "Novelbright",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/novelbright/1022677279",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1c/50/0a/1c500adb-77c6-ac46-dc04-268e5e063211/a4cb0bb4-622b-4311-94ce-35daa9d0650a_ami-identity-0875d341dcf8150729cbd27edbaf0687-2023-03-16T00-49-12.995Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1529375597",
        "name": "David Kushner",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/david-kushner/1529375597",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/71/b7/eb/71b7ebc4-537e-4122-5d91-13d0f77dc2b6/d423d5cb-b6e0-4462-9edb-dac6ee2a63c6_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "301345693",
        "name": "Leto",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/leto/301345693",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/cb/2a/3b/cb2a3b40-42b8-9ff4-aa74-7430aca6db39/9a372201-4a9a-4471-963f-9bdcb67a551f_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "445782702",
        "name": "Bonez MC",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/bonez-mc/445782702",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/63/74/88/63748826-8cd7-c6fa-fef7-a34c7307fadb/7b05b655-fa1a-4a3f-a043-a47e93c44d1a_ami-identity-b55985a5619261bccfe03aa2497c8b82-2023-09-13T09-20-06.997Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "62820413",
        "name": "Arctic Monkeys",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/arctic-monkeys/62820413",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/d9/a6/06/d9a60642-3a9a-c289-326d-9af3928c4784/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1477657249",
        "name": "Shiva",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/shiva/1477657249",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/55/92/e2/5592e296-f493-e95e-090a-9cb1dcf35b1a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1062592283",
        "name": "Irama",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/irama/1062592283",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/d3/df/f0/d3dff033-0571-91ca-dab7-e0f8ae8db134/mzl.evlzgppr.jpg/1000x1000bb.jpg"
    },
    {
        "id": "268243693",
        "name": "Shindy",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/shindy/268243693",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/05/72/95/057295e1-488d-5a9a-2606-a9bb504e835f/48c5c07f-359a-4dc4-bdcc-a459971fb22e_ami-identity-562cc75f4f34cda3164423457c21830d-2023-12-11T10-17-24.283Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "298529572",
        "name": "LOBODA",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/loboda/298529572",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/fd/af/25/fdaf2529-d3ed-9afc-2354-9a942cc5890f/mza_8616478833785724824.png/1000x1000bb.jpg"
    },
    {
        "id": "1437398609",
        "name": "GAYAZOV$ BROTHER$",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/gayazov%24-brother%24/1437398609",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/56/d4/5f/56d45f5f-18f2-08df-f342-26e5188fd346/b3669354-9d9a-41aa-97de-449a0bbff16b_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1371400021",
        "name": "MORGENSHTERN",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/morgenshtern/1371400021",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/1a/78/88/1a78885a-3459-5d94-c7ea-ea8de147447a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "898094630",
        "name": "NF",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nf/898094630",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1e/bf/e9/1ebfe9c6-4d2d-b415-b3cb-e1e3c2797197/265cdc14-cd50-4e8c-a3c4-8eb53de02b14_ami-identity-2185802b4bcfeed81e8f8dc59999108d-2023-04-18T22-47-47.510Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "532788825",
        "name": "Alonzo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/alonzo/532788825",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ff/87/b8/ff87b8b3-a1d1-8a87-897a-67a63b767ab5/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "409076743",
        "name": "IU",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/iu/409076743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/41/04/45/410445f8-f70e-2d89-c26a-c3806d8e3f7c/mzl.uvpocmik.jpg/1000x1000bb.jpg"
    },
    {
        "id": "889327",
        "name": "Shakira",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/shakira/889327",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/26/53/90/265390af-e4b3-22d6-20cc-a8ad19551d01/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "341924363",
        "name": "Ghali",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ghali/341924363",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/9a/22/fd/9a22fd22-5496-a91b-7ac1-bb24a67617b6/mzl.cyndvrxp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1414503043",
        "name": "Geolier",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/geolier/1414503043",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/05/70/d1/0570d17e-e275-9d88-ae87-d12e5b9145ed/69e15390-f7ca-4fc5-b962-755b69280b18_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "369211611",
        "name": "Mayday",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/mayday/369211611",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/14/7d/5b/147d5ba3-e4ca-5784-841e-79f197f98b75/mzl.msrqezki.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1250709916",
        "name": "Yorushika",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/yorushika/1250709916",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/9b/80/90/9b80906f-6983-aace-9ec4-88da893fab29/mzl.lbluzxry.png/1000x1000bb.jpg"
    },
    {
        "id": "882854586",
        "name": "Viktor Sheen",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/viktor-sheen/882854586",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/96/6c/74/966c7409-0341-1dfc-2104-b960a19f2e04/0909dfc6-4f24-45e0-8cf6-862e55df747e_ami-identity-e3e991e1a0427eb555801ce779b6746c-2023-10-12T17-13-25.706Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1378887586",
        "name": "(G)I-DLE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/g-i-dle/1378887586",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e1/9e/43/e19e434b-d176-0ab6-8eb5-82f9755a81f8/75218861-e4cf-4a8a-8598-b6e6517663e0_ami-identity-da9dd3316026fa2554d986133c5e010a-2023-04-28T18-03-39.501Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "254654363",
        "name": "Davido",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/davido/254654363",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/cf/ec/c7/cfecc704-915b-2a5f-a957-9a80997a6d4b/51b72b16-1401-4879-bbf5-553898ddc8ed_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1457386767",
        "name": "Camilo",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/camilo/1457386767",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/96/a6/a7/96a6a715-662f-12b0-4c92-b098577221a5/mzl.ylrlsdhf.jpg/1000x1000bb.jpg"
    },
    {
        "id": "597214610",
        "name": "Anitta",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/anitta/597214610",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/4e/f5/ea/4ef5ea3b-5850-7915-6d8a-dc4afa111f75/d325c001-27de-4ecc-98a7-10cf51e8d836_ami-identity-1f7a3d646bcbe73066230d63fbafef1c-2023-06-10T19-08-56.836Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "569972619",
        "name": "Aimer",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/aimer/569972619",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/7d/7f/1b/7d7f1b24-9bb8-3ff5-83aa-11ce9cdf5a1e/58199f98-63ad-4268-9696-511403110f18_ami-identity-54f701b8b197c76a0b9fa55ea5989664-2023-06-19T03-26-47.389Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "722264654",
        "name": "Sebastián Yatra",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/sebasti%C3%A1n-yatra/722264654",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/30/d7/fb/30d7fb8a-8ad9-51a8-5e9d-192cdac1b72e/3a66bb0a-71ab-4aaa-b8d1-a755bc83be8c_ami-identity-02a4d6467a06e9572e3a780df3129f51-2023-09-18T15-48-46.199Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "435966642",
        "name": "TAEYEON",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/taeyeon/435966642",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a3/39/e5/a339e556-d10a-1bec-683c-5d2501661334/4d6808bf-3365-4237-8f5d-fa9129ad04e3_ami-identity-fd696571638a8434db5153af25490f51-2023-11-23T05-18-39.424Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1044506362",
        "name": "iKON",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ikon/1044506362",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/ae/0e/73/ae0e7323-b4f4-8aa9-3f34-add173f3c0a1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "999644772",
        "name": "SEVENTEEN",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/seventeen/999644772",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/8f/20/4a/8f204a28-6732-f750-8cb0-7e90bef91dfe/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "906366067",
        "name": "HONNE",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/honne/906366067",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/f3/01/ba/f301bae9-fea2-c6a4-d6c3-839b997727d9/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "348580754",
        "name": "Meghan Trainor",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/meghan-trainor/348580754",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/61/cf/18/61cf18e0-8b81-cc10-8e0d-b4efde748e6e/55517823-adc5-4e5f-b527-0fd797b5ad05_ami-identity-f573a3cd10ba514d37787ffa4a260e7f-2023-03-04T01-40-40.815Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1096512501",
        "name": "Keyakizaka46",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/keyakizaka46/1096512501",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/b6/fd/cd/b6fdcd80-68a6-bcb0-3795-1fbed047dd29/mzl.nccfutln.jpg/1000x1000bb.jpg"
    },
    {
        "id": "280215821",
        "name": "Demi Lovato",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/demi-lovato/280215821",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/85/30/eb/8530eb49-616d-667f-7f2b-a1a35ea0d37d/5b1491a1-8313-4699-bdf5-d1091bfe70d2_ami-identity-b3323febefbf1240358c69b8c380fa92-2023-05-25T22-16-58.059Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "487277",
        "name": "Kate Bush",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/kate-bush/487277",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/2a/7c/7e/2a7c7ee9-743f-5f9e-cc34-248ad7e27df5/mzl.aiqoizvy.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1465235237",
        "name": "INSTASAMKA",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/instasamka/1465235237",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/35/40/27/35402789-3fdb-de78-cfef-8835f8f13917/3a291db1-aa52-4d9b-b831-57acda35dd7f_ami-identity-f7b5060bbbd0732f60ff6dc6a7fa131e-2023-11-20T15-13-36.860Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "490137239",
        "name": "Kontra K",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kontra-k/490137239",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/b5/0f/f7/b50ff72f-3b86-1ad9-fb8f-26465f177078/aad24bac-0e5d-4bb0-9dfb-68e71086e140_ami-identity-e35f11381faa19f08f7fe11ac16b0cdd-2023-11-09T21-30-51.291Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "883131348",
        "name": "BTS",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bts/883131348",
        "editorialNotes": "The K-pop hitmakers reflect on their massive international success with Zane.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1257856923",
        "name": "Sidhu Moose Wala",
        "genreNames": [
            "Punjabi Pop"
        ],
        "url": "https://music.apple.com/us/artist/sidhu-moose-wala/1257856923",
        "editorialNotes": "Punjabi hip-hop’s hardest-hitting emcee pulls no punches.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e1/ea/cd/e1eacdff-c35f-668b-7a6a-600ba53bb8c0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1519410581",
        "name": "NiziU",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/niziu/1519410581",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/b6/bd/20/b6bd20c6-4555-85e9-2e43-8131d74d1d0f/mzl.fefmehtq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1237012992",
        "name": "Don Toliver",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/don-toliver/1237012992",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/bf/68/f1/bf68f11e-3162-2939-5079-6ef28c0fb59e/8de2d4be-3916-49fe-875e-5ea040526dd9_ami-identity-cacb2d8ba70d803142bbddc0616f9897-2022-11-17T23-49-59.741Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1294478827",
        "name": "YNW Melly",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ynw-melly/1294478827",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ba/90/f5/ba90f5ec-b54f-7150-2104-1477db48d2ff/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1129587661",
        "name": "Lil Pump",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-pump/1129587661",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/e2/0e/3d/e20e3d39-4000-dd20-d6ef-cb6e06acaef8/3cda5b36-8ec9-4692-83cc-1d21b16e2395_ami-identity-eff7491f2d6bb825e31f1735d72bcf2f-2023-01-12T18-23-25.108Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1450326296",
        "name": "Ramil'",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ramil/1450326296",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/f7/5a/ae/f75aaeb6-7a0e-ece8-958f-7ec8971753fb/mza_13806848351228653648.png/1000x1000bb.jpg"
    },
    {
        "id": "293423557",
        "name": "Orelsan",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/orelsan/293423557",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/6f/ab/5a/6fab5aa8-ea6f-1e77-6193-eb0c8d26caa1/mzl.vwakegnw.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1521602916",
        "name": "Tani Yuuki",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/tani-yuuki/1521602916",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/71/6a/17/716a17f0-86af-d246-cfe4-5931cbb57222/b82166a0-9f44-48df-8c73-5d80be8d1ced_ami-identity-148557fab137d85e9650d1103f9d9565-2023-08-04T02-24-12.027Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1367253654",
        "name": "Zola",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/zola/1367253654",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/88/ba/b3/88bab33f-fcd1-6362-e9df-d7c3a8aee3be/mzl.wzilpoow.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1284151651",
        "name": "Accusefive",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/accusefive/1284151651",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/59/bc/55/59bc5569-2498-3bf1-0f11-665493262866/1aaa2687-9cf3-4ec1-886c-dcaaed939341_ami-identity-5ac388f5a4bb7fa5a43ba02f144cc5ae-2023-02-10T09-34-03.466Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1196193002",
        "name": "Ian Chan",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ian-chan/1196193002",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/38/4f/95/384f9578-6c34-e3d2-19c9-ee8cdfbe654a/mzl.axzcxsds.jpg/1000x1000bb.jpg"
    },
    {
        "id": "594629011",
        "name": "Max Korzh",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/max-korzh/594629011",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/c9/e3/cd/c9e3cd27-8c2e-9534-ce0d-1ac0bdac6a86/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "327850974",
        "name": "Lazza",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lazza/327850974",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/54/cb/7f/54cb7fc7-52fe-2b4d-4627-28ffdb1849b7/9dbaca41-2879-461e-9792-83be9cba4505_ami-identity-16a778b4bd49270ad2e8ebc66eac8670-2023-02-06T16-19-55.744Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "635806094",
        "name": "Kygo",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/kygo/635806094",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/0b/89/cc/0b89cca1-7b33-a3ae-99c0-df918d98390f/mzm.xitokrfl.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1372953342",
        "name": "Basta",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/basta/1372953342",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/c7/43/1a/c7431af7-6678-bfa6-8bf2-856dcf5eaf0d/mza_153465342923494642.png/1000x1000bb.jpg"
    },
    {
        "id": "466842536",
        "name": "Childish Gambino",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/childish-gambino/466842536",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/27/6e/aa/276eaa3e-19ca-9cb6-cbd9-8f9f5ff0f614/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1351931100",
        "name": "Koba LaD",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/koba-lad/1351931100",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/24/1b/aa/241baa45-22a6-3c70-0e29-1bcc5ab14a8e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1444397487",
        "name": "Mirrr",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mirrr/1444397487",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/53/56/49/53564975-7c18-c8d9-2cfe-4ccd741c64da/6b772789-b0c8-4553-b5f1-2176f5632aa2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1366503504",
        "name": "Lil Tecca",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-tecca/1366503504",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/54/d9/bf/54d9bf71-0c11-ed7c-cb03-00c7308d7802/55d0711d-751e-4bef-b185-8c6ddf4917d1_ami-identity-6a2fd0d266ce0b726f818a3c9e7d26b5-2023-10-07T19-12-00.785Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1203172438",
        "name": "Trippie Redd",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/trippie-redd/1203172438",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/bf/97/33/bf9733b1-bfb2-bbd5-942e-e17b9cdfbe08/163cb366-83e3-4a05-b183-c4115f2e544d_ami-identity-e44b598ab00b47557ba0882f29aeaac7-2023-06-15T17-35-29.213Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "105693325",
        "name": "Summer Cem",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/summer-cem/105693325",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/af/6f/c4/af6fc403-9bee-229b-fcaa-9f9af2041473/mzl.oavnmlkk.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1406984792",
        "name": "Natanael Cano",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/natanael-cano/1406984792",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/bb/78/0e/bb780e45-90aa-9d1f-9222-5f1a91060234/a1939324-4ebb-4173-a2c0-f1c378a8d7a1_ami-identity-e44f3f9fe06b124b109d9c33c535a300-2023-06-30T05-14-52.318Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "867853783",
        "name": "LANY",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lany/867853783",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7d/98/52/7d9852e0-3e8f-94de-06e4-a3e349e62a0e/ca9803c6-df5f-4dfb-9e13-16ae2f7dded1_ami-identity-0d298f41e3775ce9e42a3da0d1771f1d-2023-09-08T00-45-50.239Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "454694621",
        "name": "SEKAI NO OWARI",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/sekai-no-owari/454694621",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/37/a9/56/37a956ef-f30d-a258-b8e0-c3f156ecc9ac/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1367299533",
        "name": "Lil Mosey",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-mosey/1367299533",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/73/54/6d/73546d5a-1f5d-0f7c-a297-34229a9717d6/87c4e9e8-c088-48ed-b7c1-0b70e5e7b445_ami-identity-338b5184faa2de923283078ffde0c661-2023-03-13T20-37-26.640Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "609909194",
        "name": "Lomepal",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lomepal/609909194",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/98/87/46/9887467b-96d4-e0f3-242d-c3cb2874f5e2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "890558420",
        "name": "Sam Feldt",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/sam-feldt/890558420",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/5b/c2/00/5bc20048-133d-1084-5127-60dee08d1836/5709e549-cd67-4707-99d4-28d5a59d9a63_ami-identity-f028d148b91d4a6e8fdaaebaeb3f25b1-2023-11-10T21-18-53.708Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1445956748",
        "name": "Surf Mesa",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/surf-mesa/1445956748",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3c/d7/3c/3cd73cbf-bf64-ceb4-bea8-7b8c662ce41f/33d6d57e-12a5-42a4-8c78-c48a07e04c07_ami-identity-4a96c9fbdd15112fc26285acd1d4ee2a-2023-01-27T05-47-04.942Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "423087540",
        "name": "Diljit Dosanjh",
        "genreNames": [
            "Indian Pop"
        ],
        "url": "https://music.apple.com/us/artist/diljit-dosanjh/423087540",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ce/21/13/ce211315-dc6b-7d78-7c3c-0d8c4b90556f/mza_7354487753015500845.png/1000x1000bb.jpg"
    },
    {
        "id": "670534462",
        "name": "Metro Boomin",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/metro-boomin/670534462",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/73/b9/31/73b931cf-28ae-d248-fcd0-a52fd5dd19a8/4253e999-9fb5-45fe-a040-d6085082dfd6_ami-identity-e28ee99feb872b1d45ee8f94f9a0603f-2023-06-08T20-52-07.721Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1442611399",
        "name": "MERO",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/mero/1442611399",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/6b/1c/59/6b1c5977-710d-2e28-8eca-5169a23a9580/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "466059563",
        "name": "Bebe Rexha",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/bebe-rexha/466059563",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/2a/2d/cf/2a2dcfa0-bb97-1e9a-a76c-7e8c37d6c805/f03995ef-a810-44dc-9743-fdd5f0e9e056_ami-identity-8690b622c0e00a96931cb0dffb812025-2023-03-31T05-44-31.555Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1040760101",
        "name": "CJ",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/cj/1040760101",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ec/b1/00/ecb10080-ab17-2f9b-230c-7b957d2f8caa/mzl.lkktrqyt.jpg/1000x1000bb.jpg"
    },
    {
        "id": "473219952",
        "name": "Lukas Graham",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lukas-graham/473219952",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/cb/0f/8c/cb0f8ce6-a88a-3deb-84b8-b64a984ae703/37812569-2997-4745-a580-f5f6535197a1_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1262931166",
        "name": "Acraze",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/acraze/1262931166",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/1c/cd/11/1ccd11ad-de17-c3c9-a66c-9b4e38de37d9/7228bb8c-9ba1-4a18-9754-52c153be7b3c_ami-identity-a33846391d26f3410d89dac18e47766c-2022-10-05T00-20-54.707Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1536429348",
        "name": "Ayra Starr",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/ayra-starr/1536429348",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/34/54/34/3454343d-36f5-77e5-b096-552c32646967/e0d6f0c1-056e-4e4b-814e-5b4d33123000_ami-identity-34043dbeadeb9bd7ff2463d570f34c82-2023-09-13T12-17-02.933Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1168567308",
        "name": "Conan Gray",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/conan-gray/1168567308",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/aa/06/64/aa066489-251b-6c4c-a07f-0fb881ee83f4/194251e1-19aa-4f6e-a4a2-8afe876f4c7b_ami-identity-501fae298d84a5606e4634ff5abdd848-2023-05-10T14-52-57.233Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1477175033",
        "name": "Billkin",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/billkin/1477175033",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d5/7b/de/d57bde95-25eb-5d22-2b3c-9362f0b51952/e2951b37-05f3-4e36-93f2-60973a50b178_ami-identity-5d57149a03fc730c13c0711631bc7a71-2023-10-23T14-59-33.697Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1585737475",
        "name": "Shubh",
        "genreNames": [
            "Punjabi Pop"
        ],
        "url": "https://music.apple.com/us/artist/shubh/1585737475",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/3c/fd/ae/3cfdae86-6f48-d7fb-cddf-706ccde4b24c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1397775892",
        "name": "Loredana",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/loredana/1397775892",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/12/f8/f3/12f8f35b-0344-8338-75f8-8f0b7199c1b3/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "283347662",
        "name": "Macklemore",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/macklemore/283347662",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/24/3c/51/243c510f-4509-bee8-1773-f286c28ca77a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "219350813",
        "name": "The Neighbourhood",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/the-neighbourhood/219350813",
        "editorialNotes": "First came “Sweater Weather,” now comes the storm.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/3b/84/f9/3b84f924-9e0e-fbd6-3460-c04fa43e6a84/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1085511635",
        "name": "Ink Waruntorn",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ink-waruntorn/1085511635",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/2c/65/b4/2c65b483-9d36-c6bc-be65-c8c7e9bc8a9e/mzl.rhwpqwsv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1101305467",
        "name": "Lesha Svik",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lesha-svik/1101305467",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/83/39/23/833923f1-2d43-73cc-506f-28b75f1cb66e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1095881981",
        "name": "Osn",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/osn/1095881981",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/9e/66/36/9e6636db-58c4-8090-37d1-4862bb248299/0cb9b4a6-8e5a-4adb-aaec-e84caa76ccf2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "451785386",
        "name": "Ofenbach",
        "genreNames": [
            "Electronic"
        ],
        "url": "https://music.apple.com/us/artist/ofenbach/451785386",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/98/8a/b4/988ab4f1-b4de-0227-108a-1ee3b68189af/a39f886c-7d8d-4c81-8847-91d9f2317ec5_ami-identity-baddcdcb06e7c40b58d02f85289ef55f-2023-10-09T14-24-16.135Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "316265",
        "name": "Kelly Clarkson",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/kelly-clarkson/316265",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a3/e5/ab/a3e5ab01-69d9-50b0-6f18-2e4ddbad9b9e/a2f9739b-dfa9-4361-ad6b-6bb045f0617d_ami-identity-447103ec3a106b7434e5fdc6cf53b868-2023-04-12T22-16-19.553Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "148662",
        "name": "LINKIN PARK",
        "genreNames": [
            "Hard Rock"
        ],
        "url": "https://music.apple.com/us/artist/linkin-park/148662",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/78/46/62/78466263-aca7-d469-c106-c8ea4895d487/ce29f072-6937-45f8-aefb-4dec52bed5df_ami-identity-640bb7a837fee9170cc487c37709e42e-2023-02-21T21-02-54.761Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "805276942",
        "name": "HEIZE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/heize/805276942",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/c7/0b/54/c70b548d-9e3d-08a9-ce32-58761f905690/mzl.cyxrhuif.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1134937261",
        "name": "Dept",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/dept/1134937261",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/bb/3b/0d/bb3b0de8-1fd1-6f46-725c-0c8d5cfdcdcd/mzl.lxymegvk.jpg/1000x1000bb.jpg"
    },
    {
        "id": "451953510",
        "name": "Omer Adam",
        "genreNames": [
            "Israeli"
        ],
        "url": "https://music.apple.com/us/artist/omer-adam/451953510",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/04/cc/d4/04ccd4d1-a90e-3a14-5722-dc7e241dfafe/e19d6293-8dcd-4503-9025-f58d4f4819c9_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1104179033",
        "name": "Dominic Fike",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/dominic-fike/1104179033",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/64/0a/c2/640ac21e-a5f2-d971-50ef-2b87c3edc301/bed67b5e-dc54-4cfa-9761-8477bca7ca0e_ami-identity-c591af659b243bd05700d77c52d34b26-2022-10-09T05-41-04.668Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "386255092",
        "name": "Shota Shimizu",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/shota-shimizu/386255092",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/fc/28/09/fc28090e-f6ac-8027-01e5-4a568fc6e067/mzl.ygdblenc.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1580652660",
        "name": "Rosa Linn",
        "genreNames": [
            "Indie Pop"
        ],
        "url": "https://music.apple.com/us/artist/rosa-linn/1580652660",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/75/4c/3b/754c3b37-5a16-c203-f6f6-ff5c3ddfa93e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "253438210",
        "name": "Polycat",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/polycat/253438210",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/27/ac/d3/27acd334-37cf-0457-1657-1e7b2d5f15a8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1128462780",
        "name": "Hamza",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/hamza/1128462780",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/db/34/d8/db34d809-9186-46d3-3f3c-8ca38e9dd69b/mzl.fokzjrak.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1378887586",
        "name": "(G)I-DLE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/g-i-dle/1378887586",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e1/9e/43/e19e434b-d176-0ab6-8eb5-82f9755a81f8/75218861-e4cf-4a8a-8598-b6e6517663e0_ami-identity-da9dd3316026fa2554d986133c5e010a-2023-04-28T18-03-39.501Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "484568188",
        "name": "Arijit Singh",
        "genreNames": [
            "Bollywood"
        ],
        "url": "https://music.apple.com/us/artist/arijit-singh/484568188",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/e1/e9/56/e1e956fc-919e-b2c9-924d-a8b54be35d3c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1318608647",
        "name": "Heuss L'enfoiré",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/heuss-lenfoir%C3%A9/1318608647",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3b/49/60/3b4960d1-a10c-185b-4e8a-b45548a3cad7/32eceeb2-4adb-4081-8ab2-843b103c9844_ami-identity-3fd6360ea81a5748268c209dbd57236d-2023-06-28T14-12-25.680Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1168766439",
        "name": "Surfaces",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/surfaces/1168766439",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/35/c7/bc/35c7bc7e-f737-2306-777e-853aff18dd48/9a84f428-07fc-4377-a16e-ec7d1276c7cf_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1071639773",
        "name": "twocolors",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/twocolors/1071639773",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/f9/97/7b/f9977b21-ed0f-ef19-05cc-acbcf9242c3a/94010d0c-a67a-43c1-a055-9c2b25452e89_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "718954463",
        "name": "T-Fest",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/t-fest/718954463",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/bc/a5/6a/bca56abc-a2d6-5b98-009a-8fbbce269186/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "458589218",
        "name": "Nekfeu",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nekfeu/458589218",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/dd/28/2b/dd282b77-5e2f-2a95-1145-c7f9f5ded22b/mzl.mqlhkkrq.png/1000x1000bb.jpg"
    },
    {
        "id": "837847154",
        "name": "Skryptonite",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/skryptonite/837847154",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/31/e8/5a/31e85a27-939f-a7a7-3fde-09c79348b777/9edc6f30-efbf-4c12-b8d3-1a799d1982d8_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1614202131",
        "name": "NCT U",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/nct-u/1614202131",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/12/0a/66/120a660b-d48d-0151-9b73-16dfb8755c44/16a12034-3c59-4ade-b44c-afa0f72ad603_ami-identity-487473a6f3611432e5108f77b10a6d1c-2023-08-25T02-00-19.435Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "667590649",
        "name": "Lil Dicky",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-dicky/667590649",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/e5/47/e2/e547e223-5cb5-a842-6210-7de987ba19b2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1371620063",
        "name": "Dardan",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/dardan/1371620063",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/82/be/b2/82beb20b-405f-832c-7c86-1bd5696a95b1/cc4ef718-bb84-43b8-af0e-352b317ca1f5_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1191850724",
        "name": "JIN",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/jin/1191850724",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/a1/b7/ca/a1b7ca45-84c6-1939-70cb-fdfb79c23b68/c6967405-5d2d-4e64-91ec-be857bb9b0b6_ami-identity-e3de1066dd9a89fa6fadb60b1a50fdd9-2022-10-24T04-08-49.135Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1486113150",
        "name": "Fujii Kaze",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/fujii-kaze/1486113150",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/95/b4/73/95b47392-9c5e-0b23-6656-a182b78ad604/ecad94ac-bb27-452b-808a-52d78bc392b1_ami-identity-3535bf7389b1a8b65d0e43bf0a1142a5-2023-10-16T10-28-08.586Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "945659329",
        "name": "Shirin David",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/shirin-david/945659329",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/54/14/a9/5414a99a-d3e6-4d39-eee8-ec8ccca7d1fb/fb7da040-cc26-41ba-b4e9-1b616d5d727c_ami-identity-d976eb099ae4981c516bb8fde35f6ccc-2023-09-27T13-59-54.297Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "571990937",
        "name": "Nogizaka46",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/nogizaka46/571990937",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/0e/e0/c2/0ee0c254-31f8-a1a6-167b-9fb71ee85a1c/24046922-3afc-4341-b8ed-4959d97587d8_ami-identity-929bdb3792b9ce407b7ce124b42bab82-2023-07-27T05-53-42.059Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1240753121",
        "name": "Tayc",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/tayc/1240753121",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/9c/a0/9c/9ca09c53-e119-c964-4b77-058d8da0991b/081ea30e-120c-4962-9e02-2b0f9426e3ef_ami-identity-0390f6638007dcce6ccfe3c9e80f6ca9-2023-07-06T14-21-19.623Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1210275020",
        "name": "Steve Lacy",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/steve-lacy/1210275020",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/4c/13/f4/4c13f434-29ff-36d5-2d40-d6a97a149ba4/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1116295799",
        "name": "Miyagi",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/miyagi/1116295799",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/46/00/fd/4600fde6-407d-7898-ca75-853dbb059220/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "417755574",
        "name": "Loreen",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/loreen/417755574",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c0/56/8e/c0568e8e-2880-5ecb-23e8-3c802ea24221/f2f9b0f7-de4f-4047-b1bb-5b7b9292b887_ami-identity-ebd31eb1e543e630fcd5a70d51ee8e60-2023-10-14T13-43-37.670Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "320569549",
        "name": "Justin Bieber",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/justin-bieber/320569549",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3a/c1/a3/3ac1a308-06fe-f693-ea95-09ca2b2c88a1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "442122051",
        "name": "Frank Ocean",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/frank-ocean/442122051",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/d8/90/47/d8904782-ec1f-d3fc-a4cb-3efb713c9ac8/mzl.vhdbrgee.jpg/1000x1000bb.jpg"
    },
    {
        "id": "90895",
        "name": "Enrique Iglesias",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/enrique-iglesias/90895",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/88/00/4a/88004a8a-1929-c573-c021-64443250333e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1294518981",
        "name": "Mimi Webb",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mimi-webb/1294518981",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/a4/ee/ab/a4eeabdf-c7e4-4759-ce80-bb4c18966d2a/mzl.taztetox.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "1089156574",
        "name": "Tom Grennan",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/tom-grennan/1089156574",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/44/67/70/44677090-2321-0117-f33b-82f0e14f271d/e324145b-e05e-4c09-a74f-b45d46cb69f5_ami-identity-02b99684cd9d4e6bc188a795ea191ce6-2023-01-19T13-09-16.876Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "704309129",
        "name": "Jess Glynne",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jess-glynne/704309129",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e2/8c/41/e28c4106-41f4-3356-d370-9a3937b89737/f935b7e1-8c75-4ad5-9703-b31f34db6959_ami-identity-0b3a9865fe16be1dad775d3fbda1b6ff-2023-04-26T22-36-10.047Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "62374520",
        "name": "Gucci Mane",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gucci-mane/62374520",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/c7/65/ff/c765ff9c-3757-4b96-043b-b9551d96d731/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "368183298",
        "name": "Kendrick Lamar",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kendrick-lamar/368183298",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/ab/50/ef/ab50ef3b-c936-44f0-7b01-bb113a133547/mza_5527770582281912184.png/1000x1000bb.jpg"
    },
    {
        "id": "1031397873",
        "name": "Dua Lipa",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dua-lipa/1031397873",
        "editorialNotes": "The mezzo-soprano has garnered pop, disco and R&B fans worldwide.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7e/18/e4/7e18e487-1530-7d33-a1e1-8c92b7ae0ea4/8e089854-42b8-457a-a7a4-874553a81c86_ami-identity-6a4cdfcea5ca482d082ae1239048d924-2023-12-06T13-21-19.061Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1476379003",
        "name": "E.SO",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/e-so/1476379003",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/bb/32/db/bb32db00-de5b-2ae8-ee34-66913a6cc966/mzl.slajabzc.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1523423348",
        "name": "Mahalini",
        "genreNames": [
            "Indo Pop"
        ],
        "url": "https://music.apple.com/us/artist/mahalini/1523423348",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/e0/5c/1c/e05c1c03-608a-9785-50e8-395189d8f42b/7fafb977-c167-41b4-8e22-c2f8ebbc89e8_ami-identity-05a524908e21f3d53e5dbad8b7e6cbb6-2023-01-19T05-56-35.941Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "425208570",
        "name": "G.E.M.",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/g-e-m/425208570",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/d4/bd/80/d4bd805b-104f-92bb-3657-5ac303272106/mzl.arclgcna.jpg/1000x1000bb.jpg"
    },
    {
        "id": "266581121",
        "name": "Marracash",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/marracash/266581121",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/88/b5/32/88b532cb-3172-d356-ffff-e841d1ee59dd/d86dad9f-deb4-4d6d-86e3-52e19940c5ff_ami-identity-82d5261e166e40558b0057c33f750654-2022-12-09T18-03-27.804Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1065154423",
        "name": "AJ Tracey",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/aj-tracey/1065154423",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/08/b2/86/08b28686-4dde-a121-9a77-4aa94e441246/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1160482144",
        "name": "Ella Mai",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/ella-mai/1160482144",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/5c/8c/95/5c8c95de-949f-6722-ffbe-a15a41e1986e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "361434320",
        "name": "Lacrim",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lacrim/361434320",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/82/02/7e/82027e65-6e51-eaf9-17da-8d7fd5add4a1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1180539623",
        "name": "Eden Hason",
        "genreNames": [
            "Israeli"
        ],
        "url": "https://music.apple.com/us/artist/eden-hason/1180539623",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/da/97/5b/da975bd7-eee5-3af8-927b-8edee1b41fd8/mzl.kvrxjlew.jpg/1000x1000bb.jpg"
    },
    {
        "id": "425208570",
        "name": "G.E.M.",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/g-e-m/425208570",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/d4/bd/80/d4bd805b-104f-92bb-3657-5ac303272106/mzl.arclgcna.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1356097231",
        "name": "Big Baby Tape",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/big-baby-tape/1356097231",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/9a/d5/10/9ad51075-1561-696d-2427-86a9c053b5e2/mzl.uspjqebq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "632781795",
        "name": "Alec Benjamin",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/alec-benjamin/632781795",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/64/12/d0/6412d0e3-ecbf-412f-7d28-7a052d3bfedf/9c41fa68-3c11-4a0c-8c96-ad55580d78ad_ami-identity-0fdba0f7b3547f3b1d331782d824d855-2023-10-23T20-07-52.505Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "300117743",
        "name": "Jay Chou",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jay-chou/300117743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/70/91/99/709199a5-0722-1648-0697-a00f1691da9c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1324809450",
        "name": "NIKI",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/niki/1324809450",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/3c/48/f0/3c48f0d0-f296-f354-a1c2-41cb1555342d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "708229222",
        "name": "Bausa",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/bausa/708229222",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/20/bb/2a/20bb2a42-317f-4493-1d15-c644a3747b55/ae47b614-40ea-4f43-8dc2-df5be4bb01a7_ami-identity-91bde78f37d3109b09a3df40ce54a210-2023-06-06T13-45-15.234Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1551033783",
        "name": "Bailey Zimmerman",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/bailey-zimmerman/1551033783",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/38/c1/53/38c15364-dfef-5467-a8ab-6aaa0bc03b12/6b133539-46dc-47d3-b4cf-fbad21404c4a_ami-identity-8b19e2a35d1afadeeb045d994fe1bf51-2023-03-29T14-40-12.253Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "659665223",
        "name": "DISH//",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/dish/659665223",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/cf/9c/b8/cf9cb8c0-d5e0-50c2-d721-b3c9d662c889/52d54241-e6e5-4f30-99cb-234854c468fd_ami-identity-ca0b8eaab6db20cdd4969ec8d5499f3d-2023-07-03T02-44-41.938Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1304823362",
        "name": "Stray Kids",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/stray-kids/1304823362",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/34/13/ed/3413ed03-c7a7-4506-8da0-8501bb1ae41f/b8aa34d2-0829-4683-bf12-e96215369f65_ami-identity-ae24761de3608ee52087a99d0cd83d87-2023-10-23T07-29-42.360Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1581975222",
        "name": "Semicenk",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/semicenk/1581975222",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/6e/38/e7/6e38e701-b98d-4844-446d-74827f2aa104/fe9deff5-7bc2-45cc-8a69-493ad8e46a54_ami-identity-d5de988bd8402937fbd3a3e0a9a7b9da-2023-05-31T12-17-20.545Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "960568308",
        "name": "OFFICIAL HIGE DANDISM",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/official-hige-dandism/960568308",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/42/f2/a2/42f2a2ab-73ea-44e2-f21d-7b88fbb0d11c/274158a9-18b2-4753-bd10-018ef58f6962_ami-identity-5dd7e8ab9c68d25738a5acba342f25a5-2023-08-21T08-58-13.268Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "717795073",
        "name": "Gazapizm",
        "genreNames": [
            "Turkish Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gazapizm/717795073",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/7a/5a/1a/7a5a1ad2-0585-dbb7-6b60-ad44216bb108/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "334089123",
        "name": "THE CARTERS",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/the-carters/334089123",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/db/b8/1e/dbb81e43-7897-c790-c65e-42e4a9e15e1f/mzl.wrbucjwm.png/1000x1000bb.jpg"
    },
    {
        "id": "1406242696",
        "name": "ROSÉ",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ros%C3%A9/1406242696",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/e6/13/53/e61353de-8298-103e-9933-819581fa3212/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1234516722",
        "name": "YOUNGOHM",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/youngohm/1234516722",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/64/5e/71/645e7106-37ed-94a2-f300-cca90daaf683/mzl.eeffjnll.jpg/1000x1000bb.jpg"
    },
    {
        "id": "307680660",
        "name": "Lipta",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lipta/307680660",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/9a/cc/11/9acc11c7-2fa2-afc3-7390-51f98c63b5a5/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "466691",
        "name": "Andy Williams",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/andy-williams/466691",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/e6/89/e5/e689e594-9bc1-47f4-2f4b-a665e0c2fed5/mzl.nrpbpqqp.png/1000x1000bb.jpg"
    },
    {
        "id": "1235623728",
        "name": "Headie One",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/headie-one/1235623728",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/d6/2c/79/d62c7966-e8c6-b546-95d2-769b84b97a1e/a7cc3305-6988-4854-b027-529f387ce96e_ami-identity-bba5974f5e6a19b013f83093c9495235-2023-01-18T13-54-17.442Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "355491437",
        "name": "Marco Mengoni",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/marco-mengoni/355491437",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/97/a7/59/97a759c5-9365-a45b-1d66-3f85feb405b9/bd621b9d-41bf-4891-86da-b04fd47927dd_ami-identity-fe55fc9f2c5f572922f96bb28935696c-2023-05-19T09-20-40.778Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "575910990",
        "name": "Fred De Palma",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/fred-de-palma/575910990",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/69/80/35/698035a8-ac6e-34c3-a0a2-38020289ebcf/mzl.tpghbqls.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1440449616",
        "name": "IZ*ONE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/iz-one/1440449616",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/78/b7/31/78b7317a-0135-4949-13b2-c0715eceac99/mzl.sxnuczgj.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1132230403",
        "name": "Rkomi",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rkomi/1132230403",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b4/76/13/b4761360-322d-80e9-7c08-517de6982fdf/7d74fef3-d6cc-4916-a95d-4a5c437f1d5d_ami-identity-c2ced2b9b8368f746c3ec62c7de8dadb-2023-06-06T09-27-00.605Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "394295270",
        "name": "Elley Duhé",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/elley-duh%C3%A9/394295270",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/d9/8a/3b/d98a3bbc-85cd-b2eb-5afe-df93bc7d8ece/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1478449554",
        "name": "Libianca",
        "genreNames": [
            "Afro-Beat"
        ],
        "url": "https://music.apple.com/us/artist/libianca/1478449554",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/0d/3c/d5/0d3cd5f1-4bb4-c8d8-8449-7b569a9401d9/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "671377502",
        "name": "NONT TANONT",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/nont-tanont/671377502",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/0e/df/e9/0edfe96d-f866-9d6a-6605-4f49ed685eb9/mzl.xqukdgkd.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "570372593",
        "name": "Zara Larsson",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/zara-larsson/570372593",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/99/ed/a2/99eda2d9-2265-d818-165a-02b1db7a22fa/cc08df0d-552a-45d4-878a-a7ed73c9a496_ami-identity-9fa2d824f9bf37c4eb8301c82f732159-2023-11-24T16-50-49.454Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1077538316",
        "name": "PHARAOH",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/pharaoh/1077538316",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/26/e7/04/26e7042f-1fea-4a1e-c1a8-c04bb2e16146/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1377413450",
        "name": "LSD",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lsd/1377413450",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/cd/b1/7d/cdb17dc5-53f9-8f39-3b7e-92e632482663/mzl.tubuoqip.jpg/1000x1000bb.jpg"
    },
    {
        "id": "385521476",
        "name": "Gzuz",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gzuz/385521476",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/61/2b/43/612b43e7-6515-a866-63ea-21706892e28f/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "602767352",
        "name": "Lorde",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/lorde/602767352",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/a6/05/b1/a605b185-1921-aef6-46be-f036f0518c3e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "597228040",
        "name": "FEDUK",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/feduk/597228040",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/83/41/f5/8341f5f8-88b9-3109-5c41-998cecf69de0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "512633",
        "name": "Oasis",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/oasis/512633",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8e/e0/22/8ee02243-149b-ece2-f4a4-d1f163a9bcc2/3b8c4a94-d6b3-4f0e-a201-78433a5e93de_ami-identity-b890419329fe08a1ba58fa86e943bee1-2023-09-21T10-33-00.361Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "561444659",
        "name": "Rich The Kid",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rich-the-kid/561444659",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/0c/9b/31/0c9b31b2-1940-a00f-85cd-14062721e3e4/51ffd75e-f7d1-4485-8e65-9d979424624f_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "477669285",
        "name": "Crowd Lu",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/crowd-lu/477669285",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/78/da/0a/78da0a72-2da1-79fb-0949-286f0296e3d0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1001681665",
        "name": "Tulus",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tulus/1001681665",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/6a/43/1b/6a431bb9-f901-02a3-8b38-91d901c54237/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "560195338",
        "name": "Loren Allred",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/loren-allred/560195338",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/72/7f/b8/727fb8f0-526f-170a-6c9a-2d228cb917b2/d76ca28c-3abe-4f19-9eab-72a7a7929c36_ami-identity-41b4abb6b0de21689adf2e3a5dc192bb-2023-04-06T04-38-05.915Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "521141925",
        "name": "blackbear",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/blackbear/521141925",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/2e/fe/7c/2efe7c43-3109-a6da-da7d-a85d4a45caf0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1255257222",
        "name": "BlocBoy JB",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/blocboy-jb/1255257222",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/46/17/2b/46172ba8-4308-41c0-bc4d-8f2dcdf7b630/mzl.fvqcxasy.jpg/1000x1000bb.jpg"
    },
    {
        "id": "699343585",
        "name": "Sangiovanni",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sangiovanni/699343585",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/dc/5b/a5/dc5ba5fc-6115-6a3e-330f-8ef9e8b5c854/af3b5877-6e6d-489b-ade3-965ce085a2e5_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1402222881",
        "name": "Pink Sweat$",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/pink-sweat%24/1402222881",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/89/eb/b2/89ebb268-8b4d-c202-7b6d-36592c3ab982/8d49a79c-8776-47be-9da7-693e39bfd1c1_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1052732345",
        "name": "Vegedream",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/vegedream/1052732345",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/b9/c7/38/b9c73801-5c8d-59fc-1a29-243115f2bfc8/mzl.kpmlvfgw.jpg/1000x1000bb.jpg"
    },
    {
        "id": "344030880",
        "name": "OBOY",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/oboy/344030880",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/60/cb/5a/60cb5adf-bc8d-4d37-f731-1278e8a8babd/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "5040714",
        "name": "AC/DC",
        "genreNames": [
            "Hard Rock"
        ],
        "url": "https://music.apple.com/us/artist/ac-dc/5040714",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/af/b0/02/afb00272-666e-d9ed-24c8-ce9a75e83b5e/mzl.hvoetenq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1462708784",
        "name": "Lauren Spencer Smith",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lauren-spencer-smith/1462708784",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e0/d9/19/e0d919fb-045a-aee8-d8a9-82356448db47/4bb2c155-c516-46dd-abc9-12e4fbb4422c_ami-identity-0892b03c50b09945cfe32c1f76469746-2023-11-13T21-56-53.488Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1257594394",
        "name": "HENTAI SHINSHI CLUB",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/hentai-shinshi-club/1257594394",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/88/0b/84/880b84c0-937b-0dfc-fdef-6e4931f6a7de/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1293896908",
        "name": "Leony",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/leony/1293896908",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/87/95/10/87951096-b896-718a-4996-a17184d20ed0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "108709",
        "name": "Eve",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/eve/108709",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/6f/22/1c/6f221c91-842c-fce1-3bda-7dea3807835a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1363775273",
        "name": "Ben Fero",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ben-fero/1363775273",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/81/f0/a8/81f0a8a5-d537-78bc-1b45-a35e8e33fe5c/mza_4181299851683040580.png/1000x1000bb.jpg"
    },
    {
        "id": "656726368",
        "name": "ZICO",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/zico/656726368",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b6/db/1e/b6db1ebb-1f8c-590f-8149-294fc2808341/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1088331223",
        "name": "Jorja Smith",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/jorja-smith/1088331223",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/4a/cd/69/4acd6967-2e43-acdd-1b26-7907a89b303f/90b260e2-1f73-4aed-adef-d7ad8ea4794b_ami-identity-a81fc2827629b04d800766a5837bc685-2023-05-18T13-23-25.832Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1354545350",
        "name": "WANYAi",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/wanyai/1354545350",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/d7/84/06/d78406c6-58cc-320d-9d92-6844ce76cea8/mzl.rvxmhwdb.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1476584041",
        "name": "Kenya Grace",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/kenya-grace/1476584041",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/12/88/5a/12885a75-f1bc-e71d-bbd6-b357d5f2e6b4/31f61f5b-94ac-48ab-8ea9-4c2c7fc75369_ami-identity-9071226a01f789ae6bfa56e1c87e1a22-2023-04-26T13-25-58.614Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1268507013",
        "name": "Wanna One",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/wanna-one/1268507013",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/72/91/c5/7291c5d2-b5f0-67f5-8b6c-dc72f4eb3ed1/mzl.rpdmwzer.jpg/1000x1000bb.jpg"
    },
    {
        "id": "42264594",
        "name": "Sido",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sido/42264594",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/d9/2b/e8/d92be8d2-5c49-e34c-75eb-8286e6644002/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "377761784",
        "name": "Quevedo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/quevedo/377761784",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features113/v4/86/ce/60/86ce60a7-77e1-f06f-776c-fea43f636f14/mza_2400742164567432256.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "458659054",
        "name": "GIMS",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gims/458659054",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/9c/ec/d8/9cecd81b-be6e-5960-4ef6-b8cd71127ce0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "450552292",
        "name": "Mabel Matiz",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/mabel-matiz/450552292",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/51/c4/27/51c427ea-6e7f-2514-4328-2fe78fca535a/42a9dbb7-d179-4fcb-9446-a3fce6f7de32_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1021730955",
        "name": "Kabza De Small",
        "genreNames": [
            "Amapiano"
        ],
        "url": "https://music.apple.com/us/artist/kabza-de-small/1021730955",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/ce/c1/52/cec15279-5739-ada7-cc3e-219a7e26ac34/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "509474948",
        "name": "Veysel",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/veysel/509474948",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/d8/f9/21/d8f921cf-2cdc-e9cf-e92c-d64e2ebe5b75/f73d36b6-dd8a-4421-9a7a-bbf5a4e5d641_ami-identity-4f9e4c231608e7988b8a6cffb4f355b3-2022-12-16T20-23-36.454Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "117471769",
        "name": "Reik",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/reik/117471769",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b5/2c/39/b52c3937-d1ef-aeb0-fe66-c4d4c2f41d50/441a3ca1-1d51-46fe-818b-3556808c94bc_ami-identity-727072286d41d2ca50c42ce09076e461-2023-09-22T21-45-57.638Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "147559",
        "name": "The Goo Goo Dolls",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/the-goo-goo-dolls/147559",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/d5/8e/a3/d58ea3d3-9e98-00af-407d-f50355c0f853/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1579400",
        "name": "Bobby Helms",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/bobby-helms/1579400",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/67/a9/26/67a9263e-245a-f8de-5d04-33e9a3eec641/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "503131736",
        "name": "kizaru",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kizaru/503131736",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/71/4e/75/714e754f-d07c-4bdb-5fd9-14491006d08d/d569549f-434f-42d4-8853-0f483a6f3f6a_ami-identity-38f0995592b2a6d83327732ce5487b2c-2023-11-02T13-36-16.322Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "982177407",
        "name": "Azet",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/azet/982177407",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/51/e4/36/51e436cf-d897-d66c-bd77-7a39241340ee/74001d28-a38a-48f4-a8b0-9c888dcc1aa9_ami-identity-f8748b311c62fb15e990cf1e2bf24521-2023-06-20T14-03-29.638Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "210441542",
        "name": "EDIS",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/edis/210441542",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e9/f6/3f/e9f63f3c-d367-c1ab-6e81-81360ef32c40/25ffbade-a246-4698-9153-df1842d9fcce_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1068912248",
        "name": "Peggy Gou",
        "genreNames": [
            "Electronic"
        ],
        "url": "https://music.apple.com/us/artist/peggy-gou/1068912248",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/19/dd/b1/19ddb12a-24d3-cbee-15bc-e4db91778760/e0279fec-d56a-445d-9444-a4081bf060f8_ami-identity-31a954c200a5c5c449bf5faedb6bd2b7-2023-11-13T11-49-20.272Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1183386766",
        "name": "Gazzelle",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/gazzelle/1183386766",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b0/d7/a9/b0d7a9da-e661-7e1c-fb68-9dbe284820b7/72c8e3eb-4d9c-4dd6-b845-424b857da3b0_ami-identity-249b70c9f98bd9b537bc7d308922e439-2023-04-19T08-45-04.992Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "556508733",
        "name": "Vald",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/vald/556508733",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/42/ba/17/42ba17af-9f1a-782c-894c-4054fdec364b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "422762545",
        "name": "Annalisa",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/annalisa/422762545",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/a7/7a/c2/a77ac25a-e0a3-27d8-389b-f67cdd2da330/mzl.wkrbejek.jpg/1000x1000bb.jpg"
    },
    {
        "id": "198431322",
        "name": "Jhené Aiko",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/jhen%C3%A9-aiko/198431322",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ef/e2/dc/efe2dc62-f946-7384-a8ec-1a45a8ae46c9/mzl.mfwtjprs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1436064480",
        "name": "Asake",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/asake/1436064480",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/75/9e/1f/759e1f5f-7274-284b-7fa8-927b3560245e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1400676576",
        "name": "Master KG",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/master-kg/1400676576",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/2d/09/93/2d09930a-4cd8-1dec-4eec-adf0b6604a81/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1464001638",
        "name": "Gambi",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gambi/1464001638",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/47/03/d4/4703d4e1-a68d-5fb7-a526-d9e2ef013851/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "718095421",
        "name": "Yüzyüzeyken Konuşuruz",
        "genreNames": [
            "Turkish Alternative"
        ],
        "url": "https://music.apple.com/us/artist/y%C3%BCzy%C3%BCzeyken-konu%C5%9Furuz/718095421",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/dd/1c/93/dd1c9326-d98e-fcc8-4e1d-95e42d6c40e5/mzl.nhouyndi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1438242979",
        "name": "Nina Chuba",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nina-chuba/1438242979",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/c3/45/93/c34593bb-1719-8e9c-96ed-818a9e7ce05a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1451910317",
        "name": "Wegz",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/wegz/1451910317",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/42/e5/e0/42e5e00e-414a-497e-3938-4932302554cc/35445175-0a2a-4710-94db-d74faa81503d_ami-identity-1f29816561e336c21c78b39ac49f39cf-2022-12-20T10-27-39.868Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1103179516",
        "name": "Soolking",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/soolking/1103179516",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/6a/7d/37/6a7d37cd-7ab9-e2eb-636d-ebfdb4c7ed90/mzl.obupxsrz.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1088228936",
        "name": "Pamungkas",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/pamungkas/1088228936",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ec/fa/c8/ecfac800-34ae-0e2b-d687-58148b324b15/mzl.sydfpdyp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "275649746",
        "name": "G-Eazy",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/g-eazy/275649746",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/6d/5c/69/6d5c6940-485b-d60f-e334-b39e81c5780e/908da668-f697-4166-907d-149c5607c1bd_ami-identity-8da1dc8e941a35d55632abba2260c3e5-2023-03-16T23-59-48.216Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "955657302",
        "name": "Morat",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/morat/955657302",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/cd/b6/50/cdb65091-4f07-6c40-64eb-9e39013a66ca/mza_5545020179733644321.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "908222845",
        "name": "WINNER",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/winner/908222845",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/a5/bf/bf/a5bfbfec-bc54-914a-6efc-598bb6f1ef83/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1436446949",
        "name": "Lil Tjay",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-tjay/1436446949",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b8/be/b9/b8beb974-d904-f19a-8f51-e462f82536f6/1d1575a4-08c1-446a-9a5b-aee2ff7ccec3_ami-identity-bf881037fbdfc6109a639cc2e713a4a7-2023-07-12T16-56-28.863Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1089553981",
        "name": "URBOYTJ",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/urboytj/1089553981",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3d/99/4b/3d994be6-3c1f-fce0-31d5-a2a830fd4b91/1c9b4233-31b7-4894-87a8-fadcad5d35e4_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1081118714",
        "name": "Drax Project",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/drax-project/1081118714",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e1/86/64/e1866450-b5ef-146c-a4a0-20f5ab7f2ed0/2015d56a-438c-4954-8eac-da767fe1abf6_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1080621580",
        "name": "Carl Brave",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/carl-brave/1080621580",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/d1/9c/e6/d19ce6c8-c829-dceb-3a7e-223af059a08a/461cebc6-599f-4d4e-93ca-ca450d4d66c4_ami-identity-5c8af84cca02e08cb8166187ee6ee1c8-2023-06-15T07-18-14.832Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1089789428",
        "name": "MHD",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/mhd/1089789428",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/b1/d6/d7/b1d6d735-3a7a-a744-09f3-721797f55078/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "578918746",
        "name": "Lil Kleine",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lil-kleine/578918746",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/85/13/0f/85130f3e-1c39-1a3d-0a45-1474367bf6f1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1442059565",
        "name": "Junior H",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/junior-h/1442059565",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/45/5a/b5/455ab5ab-fdcf-606e-e2ff-8c0acb8af4ca/b3bea1b1-3eae-40da-be56-49c9ec72d201_ami-identity-0748a22ab411e5f7e59fd479e0336fca-2023-09-27T19-01-41.716Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1200089113",
        "name": "NAV",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nav/1200089113",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/1c/d0/c4/1cd0c415-016e-f308-569f-a7973c6085ff/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "592702233",
        "name": "Pop Pongkool",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/pop-pongkool/592702233",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/4b/7e/41/4b7e41ab-9ba1-6613-6b1a-022723613699/mzl.pyjihsrx.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "1360733410",
        "name": "Sefo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sefo/1360733410",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/c6/11/c6/c611c6d8-14f9-90e8-4cd9-078a807de1f7/289bd20f-f002-4cec-9eb4-e971fb7b5649_ami-identity-a274a68596781a3b1b7021abf014011c-2022-10-28T09-32-20.544Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1439887928",
        "name": "Jamule",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/jamule/1439887928",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/97/36/7e/97367e0c-e38c-5d16-01ad-8dbf13cc72e2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "270954369",
        "name": "Stromae",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/stromae/270954369",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/5a/76/ea/5a76eadf-aca4-38c1-6065-c1a42a9a221b/mza_11610344235846104160.png/1000x1000bb.jpg"
    },
    {
        "id": "957521979",
        "name": "Cheat Codes",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/cheat-codes/957521979",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/7a/89/b4/7a89b4b6-b735-2c85-8400-48d03c4f440c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "54657",
        "name": "Elton John",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/elton-john/54657",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/22/7a/b7/227ab79d-8c57-3622-f414-ba2e83252ee5/mzl.qlkmclct.jpg/1000x1000bb.jpg"
    },
    {
        "id": "659860538",
        "name": "Markul",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/markul/659860538",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/a7/5e/32/a75e326d-da77-9a98-99d7-38fbdfcb8003/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "882867001",
        "name": "Nathan Dawe",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/nathan-dawe/882867001",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/ee/67/56/ee67568a-e028-9b2f-9502-957c0abdd026/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1550891016",
        "name": "Edan Lui",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/edan-lui/1550891016",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/45/20/3f/45203f10-4b7c-2733-e444-a8b74650e38a/mzl.ycpkrrlx.png/1000x1000bb.jpg"
    },
    {
        "id": "1478391105",
        "name": "BIG Naughty",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/big-naughty/1478391105",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/ec/91/73/ec9173a9-19a8-a5b0-3ebe-609d5da8e281/mzl.wyiyljqv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1352449404",
        "name": "JAY-Z",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/jay-z/1352449404",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/9c/72/11/9c721146-12e5-beee-4fa1-4a9042a9f42f/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "747306596",
        "name": "AKMU",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/akmu/747306596",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/71/75/fa/7175fa15-00d7-0cc2-ac4b-63c00aacbd0f/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1500139475",
        "name": "Peso Pluma",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/peso-pluma/1500139475",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/fb/cf/77/fbcf77d5-0356-5ed4-d69f-913c21054089/mza_16631334747480910238.png/1000x1000bb.jpg"
    },
    {
        "id": "1282207196",
        "name": "Sheck Wes",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sheck-wes/1282207196",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/13/d6/43/13d64387-bda3-dbf6-870e-018b6fa9d9ce/mzl.tqadarhk.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1635469693",
        "name": "NewJeans",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/newjeans/1635469693",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/27/75/6a/27756aad-9020-d361-bfdc-a7a7670f077b/6e15cab0-aa8e-4595-aaa9-f07fed6b66ec_ami-identity-10ffb209545338d630cc58ea79bf639b-2023-07-07T04-29-23.058Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1500689282",
        "name": "Jer Lau",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/jer-lau/1500689282",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/c2/f7/9b/c2f79b57-011a-7013-52ee-07c21083c040/mzl.rgyvgkaf.jpg/1000x1000bb.jpg"
    },
    {
        "id": "419944559",
        "name": "Mac Miller",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/mac-miller/419944559",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/f4/d4/70/f4d470f9-d15a-be8d-add5-d53340dac9c1/mzl.larabvqb.jpg/1000x1000bb.jpg"
    },
    {
        "id": "677647437",
        "name": "Felix Jaehn",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/felix-jaehn/677647437",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/9a/ee/ad/9aeead10-67a5-ce04-1339-9764d46fd01a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "471260295",
        "name": "Louis Tomlinson",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/louis-tomlinson/471260295",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/90/3c/78/903c7834-be5f-a91f-ccf7-97c158d6187b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1341643717",
        "name": "MATRANG",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/matrang/1341643717",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/2d/2a/52/2d2a5256-b322-ed2b-19bd-dcf06408bbee/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "152678183",
        "name": "Jolin Tsai",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/jolin-tsai/152678183",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/91/36/26/913626dc-d908-1fb0-c603-e1f35756ce7b/mzl.jgmzfify.jpg/1000x1000bb.jpg"
    },
    {
        "id": "722264654",
        "name": "Sebastián Yatra",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/sebasti%C3%A1n-yatra/722264654",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/30/d7/fb/30d7fb8a-8ad9-51a8-5e9d-192cdac1b72e/3a66bb0a-71ab-4aaa-b8d1-a755bc83be8c_ami-identity-02a4d6467a06e9572e3a780df3129f51-2023-09-18T15-48-46.199Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "982372505",
        "name": "Playboi Carti",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/playboi-carti/982372505",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/6e/3f/94/6e3f946b-7539-dc88-e556-674482d8d557/mzl.nlqqapzi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "218103600",
        "name": "Achille Lauro",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/achille-lauro/218103600",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/2a/0e/10/2a0e10c8-7a41-e19c-a144-3fcb1353e169/mzl.fxdymawd.jpg/1000x1000bb.jpg"
    },
    {
        "id": "33987470",
        "name": "Nicky Jam",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/nicky-jam/33987470",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e8/1e/72/e81e72ae-2fca-43db-ae00-2fc02e08ebc7/dec43111-c288-4303-bfa1-565517e89275_ami-identity-018c7e93692fbb95d87f8d6f10acb233-2023-08-30T20-26-30.087Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1470589518",
        "name": "Zoe Wees",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/zoe-wees/1470589518",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/d8/97/12/d8971222-e727-837d-f01e-314fd9de77ca/21bdab6b-a0fa-4683-9222-7c5080ddece2_ami-identity-dd0024d7987510e064c676d1e18378cd-2023-04-11T11-04-37.198Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "559870896",
        "name": "Cairokee",
        "genreNames": [
            "Worldwide"
        ],
        "url": "https://music.apple.com/us/artist/cairokee/559870896",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/c7/71/07/c7710791-207a-6964-682d-f1d7e8ff316c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "16586443",
        "name": "John Legend",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/john-legend/16586443",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/cb/36/02/cb360281-43a0-0a39-403d-7faa2942389d/3d93493e-44b5-4317-8080-f44d3c998f07_ami-identity-d3d3617fe88a10c02960ce907e385bea-2023-11-01T23-17-36.242Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1408535810",
        "name": "F.HERO",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/f-hero/1408535810",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/b6/2f/18/b62f18a2-7df5-fa03-0223-85375d597813/mzl.ozhhkgpl.jpg/1000x1000bb.jpg"
    },
    {
        "id": "83106206",
        "name": "Hilltop Hoods",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/hilltop-hoods/83106206",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/4b/60/8b/4b608bfe-b931-a0b4-58f0-bc7c6de0fbab/5a483cd0-1040-4170-a5e9-5bd70b3345fd_ami-identity-6b701d2e6ea5e7f44c6c21b9891d6931-2023-06-14T09-10-03.757Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1208257541",
        "name": "Big Shaq",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/big-shaq/1208257541",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/20/83/87/2083870a-4ca5-2212-8015-f017ae487d11/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "189792075",
        "name": "YG",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/yg/189792075",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/74/29/96/74299651-2da7-b4ed-58ad-b5e78c02119b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1358539712",
        "name": "Coi Leray",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/coi-leray/1358539712",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/56/11/a6/5611a604-7908-d5ed-8bfd-6b87eb7b0d12/ed2c825b-3f6b-4a12-8caa-deac60f6bdee_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1052782461",
        "name": "Marwa Loud",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/marwa-loud/1052782461",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/0d/a4/e1/0da4e13e-c9d3-77be-4081-8a555474f093/mzl.snqxdlcv.png/1000x1000bb.jpg"
    },
    {
        "id": "960127446",
        "name": "Brent Faiyaz",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/brent-faiyaz/960127446",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/d2/da/96/d2da9606-6ac9-9079-1b68-480466233e65/mza_7013539446778743910.png/1000x1000bb.jpg"
    },
    {
        "id": "1016633280",
        "name": "6LACK",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/6lack/1016633280",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/59/42/40/5942407c-baa9-44b6-5e02-f181418ce5f9/7e906ea9-7597-42ca-8c02-67ca159dc375_ami-identity-bbaf9066f4058e4dfcfc0be947cb75e1-2023-03-01T21-41-27.276Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "446049881",
        "name": "MAX BARSKIH",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/max-barskih/446049881",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a6/53/9f/a6539f29-9300-2792-f50b-137e8ca42c78/bfb6ff64-ffdd-4373-a355-c0fb8b2c4fb8_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "18756224",
        "name": "Hikaru Utada",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/hikaru-utada/18756224",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/40/06/5b/40065b8a-0a80-cb3e-ff7b-16402aed8f60/08f018f7-5f26-468f-9bdc-d845c8694206_ami-identity-e515d0261ef40f57193a77881b22b13f-2023-12-08T15-24-29.568Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1309337700",
        "name": "Landy",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/landy/1309337700",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/1b/c1/b1/1bc1b1b4-6b58-4e9d-a339-abce3499173d/4a3f24e4-39ce-4fdd-aaaf-2ccf98d3d22e_ami-identity-b111284eb744b1bb8f3078eab9a74865-2023-01-23T14-11-04.000Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1538881438",
        "name": "STAYC",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/stayc/1538881438",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/03/e4/38/03e4388d-142f-08b5-1b24-53d83b4ebfbf/mzl.xuelyynz.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1209181560",
        "name": "Duncan Laurence",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/duncan-laurence/1209181560",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/a4/cd/0f/a4cd0fab-f0e7-d3c9-b834-7ec89965da58/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "462614",
        "name": "Toto",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/toto/462614",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/8f/b5/78/8fb57893-421f-74ac-bd8b-3d27b12c8f41/mzl.xqwiwbxt.jpg/1000x1000bb.jpg"
    },
    {
        "id": "489704062",
        "name": "KSI",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ksi/489704062",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/30/a7/35/30a7355f-7ca7-8d41-dacc-80fa53aa4ac0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "91160335",
        "name": "RADWIMPS",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/radwimps/91160335",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/c0/53/ab/c053ab54-b607-cb7a-89d0-23750f3a906a/d6059c29-5102-4162-9250-ca101aeeb3bd_ami-identity-60e298455bd895221b74629334666db7-2022-10-25T12-13-18.445Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "436847316",
        "name": "Yoga Lin",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/yoga-lin/436847316",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/2c/28/6f/2c286fe0-bcea-7d39-7c36-b49439c5ee99/mzl.yljpprbs.png/1000x1000bb.jpg"
    },
    {
        "id": "448309178",
        "name": "Charmaine Fong",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/charmaine-fong/448309178",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/5f/b2/b6/5fb2b608-3c9b-a142-1c27-49e6d3d8abb7/mzl.mqdtkyiv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "332659150",
        "name": "Kaaris",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kaaris/332659150",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/ee/96/14/ee9614e3-c476-9c9d-57db-caa7e1b09bcb/mza_10715344397394312327.png/1000x1000bb.jpg"
    },
    {
        "id": "1752134",
        "name": "Chris Stapleton",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/chris-stapleton/1752134",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/4b/4f/b0/4b4fb07e-8690-16cf-06fe-b3f259a077e8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "661638057",
        "name": "Elena Temnikova",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/elena-temnikova/661638057",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/32/f2/89/32f28973-8e69-617d-05a2-a2b0ea59b56d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "474023444",
        "name": "Thegiornalisti",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/thegiornalisti/474023444",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/69/0a/a9/690aa9fa-1eb2-fca5-f7e3-331a4db5cbfc/mzl.ynctylya.jpg/1000x1000bb.jpg"
    },
    {
        "id": "99320",
        "name": "Brenda Lee",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/brenda-lee/99320",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/69/13/66/69136677-f3c8-a780-125c-a0dda017f7a4/mzl.aihrdlzj.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1456620250",
        "name": "Pooh Shiesty",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/pooh-shiesty/1456620250",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/32/e2/5a/32e25a06-9064-df1c-523b-1d7b82632795/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "190876590",
        "name": "Calum Scott",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/calum-scott/190876590",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8b/87/27/8b87278c-549e-9438-3927-d557ae8a6028/35fffb2b-c716-4182-b007-0d27c4535e1e_ami-identity-195559bb5d8ea5396df356539ea23193-2023-08-18T08-11-10.321Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1049356910",
        "name": "Jeremy Zucker",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jeremy-zucker/1049356910",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/6a/61/ca/6a61caef-c1ad-3b27-e8a2-7edb80174b53/2edcced0-9059-48b5-b558-26fee1f8f81a_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "274752638",
        "name": "Kelly Yu",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/kelly-yu/274752638",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/dc/46/bc/dc46bc68-f60e-37a1-d77a-62ec85161d9d/mzl.safqvisa.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1132229934",
        "name": "Tedua",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tedua/1132229934",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ff/96/4b/ff964b27-9fda-926b-e716-cdefc7698931/494ea37a-2bc7-42b1-8d1a-b41ec19339b8_ami-identity-21db2fb7cfd2ed05fea6c3850b9edea0-2023-05-22T10-44-23.741Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "249044",
        "name": "José Feliciano",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jos%C3%A9-feliciano/249044",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/7d/5a/e8/7d5ae8f3-54e8-c5e6-a40f-d19752ea611e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "428909573",
        "name": "Mr.Children",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/mr-children/428909573",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/4d/7c/4d/4d7c4df0-f709-58b3-7481-e26541c7d3d2/mzl.tczpbrmd.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1275690475",
        "name": "Fuerza Regida",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/fuerza-regida/1275690475",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/9d/ca/3d/9dca3d1a-5fd3-ef4b-9841-eb40795236b9/mza_9317979140866085545.png/1000x1000bb.jpg"
    },
    {
        "id": "74576999",
        "name": "Sheena Ringo",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/sheena-ringo/74576999",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/53/ff/15/53ff1584-2910-f231-f641-bd024b3df433/mzl.kkgmwogu.jpg/1000x1000bb.jpg"
    },
    {
        "id": "690126399",
        "name": "Kehlani",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/kehlani/690126399",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/b0/e3/9f/b0e39fa6-d813-6100-9d18-43ca35a14fa3/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1509888018",
        "name": "SERIOUS BACON",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/serious-bacon/1509888018",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/f4/84/eb/f484ebb2-ab36-6d03-5b09-8bf3853434c0/mzl.pfsfwpzs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1225659793",
        "name": "Freya Ridings",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/freya-ridings/1225659793",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/12/78/28/12782843-d0c3-adf7-f028-f14ceadca45a/11f6981a-d042-404a-8885-87f390569770_ami-identity-7b196866d6c0b8aa462dfbb001f8ea68-2023-01-13T09-34-13.493Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "263153342",
        "name": "Timal",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/timal/263153342",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e9/be/e8/e9bee85b-c71a-f945-92df-4bb78449f8c2/mzl.knkuzjpi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "318754656",
        "name": "BIGBANG",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bigbang/318754656",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/cd/7d/98/cd7d98d2-d6c4-e147-f07f-2c792c3513a4/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1196040827",
        "name": "Calin",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/calin/1196040827",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/11/d6/82/11d682d2-57bd-56ae-0f80-8ac76078ea71/69da00c7-87b1-4ad8-8956-22f4bada4518_ami-identity-793ff0f6081319b7f3843adc07560c48-2023-10-12T17-16-16.888Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1459319359",
        "name": "Omah Lay",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/omah-lay/1459319359",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3d/a7/74/3da7743c-af8f-67bb-280a-9b903e4c915a/0ab4bc0c-cb37-4cb6-aa7a-d2b51e7cb59a_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1161673879",
        "name": "Mary Gu",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mary-gu/1161673879",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/5f/52/9d/5f529dd7-5e6e-606d-09b3-ba0d53f4861c/mzl.rnuerdpr.jpg/1000x1000bb.jpg"
    },
    {
        "id": "743971830",
        "name": "Nico Santos",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/nico-santos/743971830",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/3d/19/c8/3d19c8a7-09e8-60a0-fa48-beea9b5e1d60/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1148527055",
        "name": "CHUNG HA",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/chung-ha/1148527055",
        "editorialNotes": "The K-pop star on the making of her fierce pop-trap anthem.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c1/e9/86/c1e986d0-8c80-a53a-a7e1-d43a47359418/8822833a-aa3c-449c-a4c0-dab65ba8dfb7_ami-identity-545bd1485cd04523fe5b61b5512238eb-2023-11-24T08-35-48.711Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1222065185",
        "name": "Hornet La Frappe",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/hornet-la-frappe/1222065185",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/5a/aa/a9/5aaaa924-ade9-d19b-adc4-3ecc3662dea0/5d5f9c87-eabc-49f3-90f5-824fafffcb93_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1165017710",
        "name": "Aimyon",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/aimyon/1165017710",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/8b/f8/21/8bf8218f-eca3-9694-ef7a-78879ae7262d/2137a85b-dcc9-412d-9042-193a754d2a67_ami-identity-f91319429fe1b75335802a50d69c444e-2023-03-14T09-50-50.222Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "575804826",
        "name": "Jubël",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jub%C3%ABl/575804826",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/c8/52/bf/c852bf87-9129-0c99-535d-910f59543618/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "429610343",
        "name": "HIRAIDAI",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/hiraidai/429610343",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c9/ae/93/c9ae9359-8a7b-aac8-1e6c-b1ebc1635569/427f0fe0-def4-430a-ae6e-eeecec1f2402_ami-identity-f94b731b1e5d2302eb4f2ce9206f769d-2023-06-23T05-47-17.874Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "3973268",
        "name": "Offset",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/offset/3973268",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b6/71/ae/b671ae05-c7b7-c620-8d52-4ec19b8f4de2/2ba525cb-6ab6-4d91-9f83-70b14dd829b6_ami-identity-c7a2d0c7c50e3dc557a5178513527276-2023-10-11T18-44-17.470Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "642591128",
        "name": "Bryson Tiller",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/bryson-tiller/642591128",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/50/83/ca/5083ca6f-9b2c-bafc-62cc-716e6f1f5b9a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "747734869",
        "name": "Ryokuoushoku Shakai",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/ryokuoushoku-shakai/747734869",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/49/d2/c5/49d2c50a-8110-0c10-7d65-cb223faa7c43/mzl.rdivjchp.jpg/1000x1000bb.jpg"
    },
    {
        "id": "786833865",
        "name": "Da-iCE",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/da-ice/786833865",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/22/53/26/22532671-7a37-993b-de95-e9d184fcfd8a/374c62d8-6924-45f7-8b92-8f94cfbe436d_ami-identity-1d14fba1bad9a9608293e3e301e487a2-2023-04-16T03-31-27.587Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "548185047",
        "name": "ARASHI",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/arashi/548185047",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/79/95/e0/7995e08a-9e91-51c5-f6c7-262ef20fce44/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1600411676",
        "name": "NMIXX",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/nmixx/1600411676",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/63/04/e3/6304e3e4-8447-dd89-2386-ea6f010cc12c/cde3e68b-3ad3-4b8e-80c7-8ef2fdfcffc9_ami-identity-84981263043a445cb066935dc2b1cfdc-2023-12-05T14-23-42.828Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "349736311",
        "name": "twenty one pilots",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/twenty-one-pilots/349736311",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/ee/a5/67/eea567e6-e0a7-6ec0-e564-cb2bedf11608/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "579281370",
        "name": "Rag'n'Bone Man",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ragnbone-man/579281370",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/49/db/19/49db1906-b013-d48b-6c1a-0e89f8159f3b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "215508555",
        "name": "Minelli",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/minelli/215508555",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/e6/f4/2f/e6f42f9a-786e-2c3f-14be-ecd7366cf316/90df9b7a-98d0-42e4-91e6-7ec282fe1f82_ami-identity-4f853334872ff7d514a3bd15b3bd8cac-2023-01-10T12-27-59.731Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "518017277",
        "name": "Lartiste",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/lartiste/518017277",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/96/79/79/96797900-0e47-ab0b-b1e8-285e4a809b19/mzl.eauiuijj.jpg/1000x1000bb.jpg"
    },
    {
        "id": "904192340",
        "name": "831",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/831/904192340",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ba/c6/fe/bac6fee1-a750-899f-c6aa-17e03d91e307/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1374872852",
        "name": "Mahmood",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mahmood/1374872852",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a6/1c/15/a61c1565-11d6-d56e-f3e5-2bab868c31c0/f03e413a-c5af-46ac-b69d-93717e26b84b_ami-identity-5224c5cf56d359ad7bb656faab110cf5-2023-11-02T11-02-38.321Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "667456186",
        "name": "MONATIK",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/monatik/667456186",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/98/89/82/9889822a-9d68-41b6-77d3-83283e0aed12/mza_5544218030048397518.png/1000x1000bb.jpg"
    },
    {
        "id": "31427440",
        "name": "Fredo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/fredo/31427440",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/33/1e/9c/331e9c53-de10-b1d7-8045-53138ea27e04/c5ccf3c5-c7ac-4f06-b4ae-0c274ffc43af_ami-identity-5b464ca07b0c57c5cb9156ab2e042385-2023-08-07T17-01-36.781Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1403572128",
        "name": "Capo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/capo/1403572128",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/bf/74/c3/bf74c3b6-47d0-044e-fabb-e3f5b536c3b4/195999831077.jpg/1000x1000ac.jpg"
    },
    {
        "id": "1329561467",
        "name": "Mora",
        "genreNames": [
            "Latin"
        ],
        "url": "https://music.apple.com/us/artist/mora/1329561467",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c0/a5/6b/c0a56bae-7ce3-f884-0b7a-ce8322315932/7b0fcf22-b784-43cf-ba4b-4bca91683bfb_ami-identity-6398293bdcd4d3678c157c96d018c22f-2023-09-13T14-52-23.374Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "340971585",
        "name": "Mean Jeans",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/mean-jeans/340971585",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features113/v4/d2/0b/be/d20bbe71-4ddc-138f-36ee-9654dd3d74f7/mzl.gztowwqa.jpg/1000x1000bb.jpg"
    },
    {
        "id": "13478930",
        "name": "Shakin' Stevens",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/shakin-stevens/13478930",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/69/cc/c4/69ccc47b-ca88-06e2-f979-430659f49b13/5b6f1301-f3ce-4dd2-ba1b-333e2aff0f54_ami-identity-b898e19ce699210d98bbb823bb4a2cbe-2023-01-16T19-31-55.876Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1512019109",
        "name": "BE'O",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/beo/1512019109",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/d8/ba/e1/d8bae190-eb04-d35c-90c8-a1ea3a4f0323/mzl.fztnykmk.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1552925732",
        "name": "Tyla",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tyla/1552925732",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d9/c7/de/d9c7de70-b4e0-0fbf-1a0a-f084b8190c69/e5013c73-0f79-4fca-99d3-56270bd90bb7_ami-identity-a66d9229f871152b4ff92342246646ef-2023-12-01T03-51-55.168Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "600574924",
        "name": "C. Tangana",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/c-tangana/600574924",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/58/19/68/58196896-b5f5-2abc-e50e-f2e45fe79f4c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1133504399",
        "name": "Люся Чеботина",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/%D0%BB%D1%8E%D1%81%D1%8F-%D1%87%D0%B5%D0%B1%D0%BE%D1%82%D0%B8%D0%BD%D0%B0/1133504399",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/4b/e7/66/4be766c7-9f30-1311-844c-12fc1a53e914/mzl.pvykjumh.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1154586071",
        "name": "Мальбэк",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/%D0%BC%D0%B0%D0%BB%D1%8C%D0%B1%D1%8D%D0%BA/1154586071",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/89/1d/73/891d73de-c6cc-875b-f884-0bd8ea2f6300/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1110918227",
        "name": "Sam Fischer",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/sam-fischer/1110918227",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b7/48/f3/b748f357-8634-34f6-3b9e-cebc15e66391/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "616058967",
        "name": "Separ",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/separ/616058967",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7c/9b/d4/7c9bd437-bc5b-cf83-c6a9-0207a76f7075/3c1b8d63-d5ad-4873-9aff-d7dad602f762_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1281039362",
        "name": "Freeze Corleone",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/freeze-corleone/1281039362",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e1/04/b1/e104b1ff-ef85-3725-27ed-df5057dc5f55/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "174960567",
        "name": "Guè",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gu%C3%A8/174960567",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages113/v4/ae/e4/45/aee44530-1f39-d01a-a3df-48bac1cfdd95/0a4e6ce3-536f-4491-bf67-fdf8351e85bc_ami-identity-ad8aabe392fad262d4c459fd386325de-2023-01-12T18-09-41.140Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1083804443",
        "name": "Safeplanet",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/safeplanet/1083804443",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/9e/ec/f4/9eecf4db-689d-2cec-e53e-dabd61b0f306/mzl.fsojgqlg.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "307674970",
        "name": "Li Ronghao",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/li-ronghao/307674970",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/0a/d4/03/0ad403a4-7bc8-da3d-40d7-ff6ed23e3d45/mzl.bxujwsii.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1449052753",
        "name": "NLE Choppa",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nle-choppa/1449052753",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c5/07/34/c5073486-23ed-15aa-615f-ce1bcb91ada9/b26c569d-fc73-4ff3-8194-b2ae8040c47d_ami-identity-8bc5cd52b875fac780b288a4516122f5-2023-06-22T01-27-55.692Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1291895849",
        "name": "thasup",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/thasup/1291895849",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ea/79/e6/ea79e614-43df-c452-4378-0bbda170e32d/mzl.oixmsfqy.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1291895849",
        "name": "thasup",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/thasup/1291895849",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/ea/79/e6/ea79e614-43df-c452-4378-0bbda170e32d/mzl.oixmsfqy.jpg/1000x1000bb.jpg"
    },
    {
        "id": "74456960",
        "name": "Spitz",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/spitz/74456960",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/54/1d/25/541d25e7-2ec8-b9ab-4d59-caefab80362a/mzl.dfrxejba.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1650383111",
        "name": "juju<3",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/juju-3/1650383111",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a2/ce/6c/a2ce6c0d-b2ef-f5f1-94b1-8e58a26e336e/bcf1911d-bd91-4e50-aa49-a5c1a10950b1_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "123051403",
        "name": "Peter Fox",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/peter-fox/123051403",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/6a/0c/ef/6a0cef65-6baf-4e35-fa9f-2cdac44c4984/37d72766-bee7-4463-bbc0-281ebaa311ce_ami-identity-b9b570e8857413632d7228f44976ceaf-2023-03-07T14-55-12.133Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "914892130",
        "name": "Karan Aujla",
        "genreNames": [
            "Worldwide"
        ],
        "url": "https://music.apple.com/us/artist/karan-aujla/914892130",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/4c/0f/17/4c0f1732-828a-c56b-3141-42e66c1b27b5/2c2fd99f-e832-4181-bb79-b0d38c2c04aa_ami-identity-1615217ac49eff25005362d92bbcab9e-2023-07-27T21-24-14.868Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "23148284",
        "name": "Danna Paola",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/danna-paola/23148284",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/3b/a8/81/3ba88146-aebc-9a45-509e-a86d732ffd3f/3bf24d31-5e1f-44b1-9dce-8ebb90b87e6a_ami-identity-3d12160d28bb8c9545a4f1c2734419ad-2023-03-23T22-30-31.523Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1073702819",
        "name": "Nimo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nimo/1073702819",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/6d/b8/cf/6db8cf84-3848-100b-b564-a1f2d68362af/5b773f55-165a-43aa-8354-8c857230fad6_ami-identity-f061c18aaac965750e823613382c8c12-2023-10-05T21-21-57.437Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1083179259",
        "name": "Amy Shark",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/amy-shark/1083179259",
        "editorialNotes": "Watch the singer discuss her new single, \"Love Songs Ain't For Us\".",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/f9/7a/c5/f97ac5ac-5d10-db5d-7af0-1987c3ffd645/0569dc01-aade-4b7b-a385-436cbf921bd6_ami-identity-8a5935dae0ba79335df78f85bcfad5a4-2023-11-16T19-24-12.112Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1500364597",
        "name": "Tiara Andini",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tiara-andini/1500364597",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/a3/86/2d/a3862d5b-1d34-e509-8560-216a070f7d38/mzl.uhypsveo.jpg/1000x1000bb.jpg"
    },
    {
        "id": "725044408",
        "name": "Band Aid",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/band-aid/725044408",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/84/53/9d/84539df4-ae6a-e252-9cb5-74351949cc2f/16898_1.jpg/1000x1000ac.jpg"
    },
    {
        "id": "771239",
        "name": "Chris Rea",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/chris-rea/771239",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/4f/2c/77/4f2c7763-f615-7aa1-89fb-ee5208ee4317/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "569363",
        "name": "N.W.A",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/n-w-a/569363",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/22/90/88/22908803-567e-d359-ef12-62beb93ff141/mza_6133116394539442840.png/1000x1000bb.jpg"
    },
    {
        "id": "1798556",
        "name": "Maroon 5",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/maroon-5/1798556",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c3/3f/9a/c33f9a77-7395-bdfe-8068-a4b66d26531e/3ea423ea-6cf0-498a-a692-cc58da556f11_ami-identity-06acd526b8e5ef0283735a165e8eed70-2023-05-19T16-33-45.049Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "690319057",
        "name": "Dan + Shay",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/dan-shay/690319057",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/81/fd/4c/81fd4ca4-89f2-8726-a76c-8a857a0a1a23/48f401d5-4e91-4d62-9e6e-4d86dcbd0517_ami-identity-da1de433f8cabc891f22036698c8b6c7-2023-07-13T23-38-54.715Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "162585630",
        "name": "Karen Mok",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/karen-mok/162585630",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/3f/4b/38/3f4b3826-6538-dab3-ec4e-fc0af5f7c85b/mzl.zptyuiql.jpg/1000x1000bb.jpg"
    },
    {
        "id": "201714418",
        "name": "Wiz Khalifa",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/wiz-khalifa/201714418",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/41/e1/ff/41e1ff34-ce0f-ae01-570e-ae75faa8d38e/04cc76b5-a591-4690-b8e4-05a31043c318_ami-identity-71c2abc633d0109a877705d7abf6a039-2023-07-14T19-40-06.142Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1232534347",
        "name": "Hatik",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/hatik/1232534347",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/39/92/f4/3992f49f-4751-f56b-724c-bc44ea8f35b4/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "958056826",
        "name": "Atom Chanakan",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/atom-chanakan/958056826",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/cf/1b/6f/cf1b6f8d-7c2e-80c3-6582-ebb0b3bad13c/7fd2f260-5732-46bb-b58c-2ad32ba27076_ami-identity-e3c2443f8e4d3a3b65cc055565a4c348-2023-07-19T07-28-59.972Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "968819175",
        "name": "Oxlade",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/oxlade/968819175",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/c7/25/b3/c725b311-5818-acb3-f877-b9eec9f5a1fe/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "441911933",
        "name": "Gemitaiz",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gemitaiz/441911933",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/0e/2d/93/0e2d93bb-d0f5-bfb9-6078-ac52819f1ab4/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1318085504",
        "name": "Saweetie",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/saweetie/1318085504",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/80/40/d8/8040d8c2-304c-1df8-c274-9f2d3a2d901c/712378bc-c068-4fb5-89f0-212f7c699ec9_ami-identity-5e7f26525a4de7fa90b1b5b65c9fce4c-2023-07-28T00-31-21.501Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1439925456",
        "name": "JVKE",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jvke/1439925456",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/62/ad/88/62ad888b-7d98-577c-a2c0-429867021d14/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "894731301",
        "name": "Kali Uchis",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/kali-uchis/894731301",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/a7/3f/5e/a73f5ecd-0e10-a21c-cd8c-db182bcebe1e/0224c76a-3c8d-4867-921b-4d7e5525e627_ami-identity-8febedf595ea3c164413ad5ed4403a8e-2023-10-11T17-10-37.293Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1213989970",
        "name": "Sam Fender",
        "genreNames": [
            "Singer/Songwriter"
        ],
        "url": "https://music.apple.com/us/artist/sam-fender/1213989970",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/d1/9c/88/d19c882f-9758-e15a-7671-384a93e20859/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "875457681",
        "name": "Justin Quiles",
        "genreNames": [
            "Latin"
        ],
        "url": "https://music.apple.com/us/artist/justin-quiles/875457681",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/68/c6/31/68c6311e-2f33-f3d0-44da-ee8d588f218b/5b439a6d-ea07-449a-b773-653e0b67cf65_ami-identity-460382101dcdf6f553028a7b91396b38-2023-10-13T21-28-50.146Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1479514208",
        "name": "YEW",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/yew/1479514208",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/c0/2d/38/c02d38c1-0968-2a48-9ae9-f81a0b041e09/66b2fc3a-dfdc-4580-b1cb-a4ba303c3b92_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "432942256",
        "name": "Charli XCX",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/charli-xcx/432942256",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/32/c5/f1/32c5f1d8-b491-f0ff-1789-4785c361314c/5ff586c2-f509-46ba-a2c3-772dade351fb_ami-identity-cb4aed1ba5e50eba1e49f90cd4295ee2-2023-10-20T17-16-45.145Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "200951847",
        "name": "Elodie",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/elodie/200951847",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ab/cb/48/abcb48ba-1e18-caa2-3791-bb0decdec3e9/ce3e609e-8a37-40ac-9311-f4b673cf90ea_ami-identity-b5740d9bb2188bf55860335238cfc98c-2023-10-05T17-17-48.403Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1494945383",
        "name": "LF SYSTEM",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/lf-system/1494945383",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/9d/e6/8b/9de68b24-0b33-45d5-a3bb-2b1bcc629009/04dfe61a-d039-4305-b9db-fb13d1c71662_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "977177120",
        "name": "Alvaro Soler",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/alvaro-soler/977177120",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/47/62/9b/47629ba1-72da-f936-fe7f-9eca711ec57b/64b0573b-fd03-4b8a-9afd-ea59d04991c8_ami-identity-818b0ab5ff74e4364221ced7e7907739-2023-05-04T08-29-48.598Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "422255649",
        "name": "a-mei",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/a-mei/422255649",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/89/f4/be/89f4be11-36c9-7429-a352-6e21b1572e77/mzl.hglnqhbm.jpg/1000x1000bb.jpg"
    },
    {
        "id": "341339650",
        "name": "Fedez",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/fedez/341339650",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/73/ac/43/73ac43a9-1407-99c2-0181-4d1be5e26caf/3999ce49-d128-4c34-83f7-701179f50a65_ami-identity-0c358119bb3b0fdddf93398b052146fc-2022-12-05T13-17-14.346Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1104839687",
        "name": "Chanmina",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/chanmina/1104839687",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/01/62/b6/0162b69e-89cb-9626-709f-b87ed5f7439c/c2256d65-714e-4e88-b6d2-5ab0edc56580_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1654860606",
        "name": "FIFTY FIFTY",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/fifty-fifty/1654860606",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/af/f5/46/aff54674-decd-2dc2-f9df-2296bf6f527f/4c1e15f7-373b-4822-93d9-31823d3523d0_ami-identity-64bc6925ec853309c09739a4115b89e8-2023-11-07T06-29-42.853Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "549329748",
        "name": "Nines",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nines/549329748",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a2/87/e7/a287e740-d04d-b7cb-d14b-3ff2d7449d16/6e9e446c-3572-4b96-94cd-c4c40d9435e2_ami-identity-8310dfdfe37544752b8d02e412aa9ebb-2023-05-10T15-42-44.294Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "14420857",
        "name": "Sezen Aksu",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/sezen-aksu/14420857",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/bd/35/05/bd35052f-2fcf-f53c-815c-49d24cd43e9e/5ea112d1-cee8-4b9a-add8-52c11c147df2_ami-identity-5957a82dc3f86906436bd426c32442b8-2023-11-07T08-30-49.123Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1587414058",
        "name": "Benson Boone",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/benson-boone/1587414058",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/94/6d/76/946d767c-9bb6-5810-f2ff-4e39cddf169e/1705e288-fea7-4e28-a5b2-e82261611304_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "368841405",
        "name": "Luan Santana",
        "genreNames": [
            "Sertanejo"
        ],
        "url": "https://music.apple.com/us/artist/luan-santana/368841405",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/c1/82/f5/c182f555-f066-b6fa-9897-533c1322fcd8/mzl.pcluueoe.png/1000x1000bb.jpg"
    },
    {
        "id": "1456286645",
        "name": "10AGE",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/10age/1456286645",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/5e/66/69/5e66692c-c47a-7702-340e-421ec502cb77/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "652139007",
        "name": "Mr.Rain",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/mr-rain/652139007",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/c9/b7/0c/c9b70c39-706b-e3cb-a1c5-44d05e9e2ae2/mzl.ydihvtri.jpg/1000x1000bb.jpg"
    },
    {
        "id": "491847321",
        "name": "Baby K",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/baby-k/491847321",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/6f/2b/c0/6f2bc0d9-ba89-c58e-6fa2-1bb0c07b0330/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "319063256",
        "name": "Sauti Sol",
        "genreNames": [
            "Afro-Pop"
        ],
        "url": "https://music.apple.com/us/artist/sauti-sol/319063256",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/78/01/81/78018147-21fa-7df3-1824-423a6f183b5c/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "321849415",
        "name": "187 Strassenbande",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/187-strassenbande/321849415",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/8a/85/42/8a8542b6-b89c-0cb5-7338-af6f5930e4dc/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1280541395",
        "name": "Reynmen",
        "genreNames": [
            "Turkish Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/reynmen/1280541395",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b6/80/cd/b680cd93-633d-288e-2d14-ee474d3b6202/bbdec535-54c8-4e83-bdab-27ea44c96fd0_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1404786529",
        "name": "Takaya Kawasaki",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/takaya-kawasaki/1404786529",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/16/0d/81/160d816a-94b4-3269-8fd1-9ff86e5dd91d/mzl.uiovsxwl.jpg/1000x1000bb.jpg"
    },
    {
        "id": "42760745",
        "name": "Aaron Smith",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/aaron-smith/42760745",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/56/df/26/56df2690-dbe1-0445-d34c-a0cd959ca523/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1335765508",
        "name": "MEYOU",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/meyou/1335765508",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/8c/48/99/8c489936-7a81-e0b4-2708-e46c04ba5994/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "30961675",
        "name": "JC",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/jc/30961675",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/47/7f/65/477f6501-fbbe-e9c0-1a41-183068771533/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "935766494",
        "name": "Zeynep Bastık",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/zeynep-bast%C4%B1k/935766494",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ce/19/bd/ce19bd57-c95e-9cc9-dba7-a83c357c340c/e6822bb4-272f-40f4-bbd1-e32343635880_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "171366",
        "name": "Frank Sinatra",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/frank-sinatra/171366",
        "editorialNotes": "Celebrate a century of Sinatra with great albums and playlists.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/8b/78/6c/8b786c35-57e8-baa8-b3a2-ced18ac9d602/mza_1777275275048889965.png/1000x1000bb.jpg"
    },
    {
        "id": "1172737396",
        "name": "reezy",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/reezy/1172737396",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/52/e4/31/52e4314d-86e5-e748-f71d-ade3f3c6eef7/7b3bf9ee-6cd4-4703-9287-7a6e50bcd9f0_ami-identity-1de5f47ed4f2ad5134147aa180db58ab-2023-05-17T08-32-05.482Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "278109107",
        "name": "KC Rebell",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kc-rebell/278109107",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d9/2c/c6/d92cc669-b021-f35c-3bb7-3d62191e9460/10d2aca8-022e-4092-b605-08e5e6f14552_ami-identity-73f8e58a39a6e903630d161d0a840adb-2023-03-30T17-52-47.525Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "465031",
        "name": "Kylie Minogue",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/kylie-minogue/465031",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a7/80/68/a780680c-4c35-d726-7e7a-640840773243/e8a2b870-48b0-4339-ac7f-b68e7aca28d0_ami-identity-7cbd1257f075783021b4305eb176cd9e-2023-05-15T14-15-07.768Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "214150835",
        "name": "Sam Hunt",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/sam-hunt/214150835",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/82/d4/7e/82d47ee8-7678-6ddf-d8a0-7659465f6936/8680b5f5-fa98-461b-8912-8d7f885b90e4_ami-identity-861f7596b2fa83c8e0ca695dbfe57321-2023-03-07T19-29-02.018Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "266028",
        "name": "Dean Martin",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dean-martin/266028",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/69/15/36/69153659-cb6c-dd15-cb76-274edbc64214/mzl.xetttpnk.jpg/1000x1000bb.jpg"
    },
    {
        "id": "877792512",
        "name": "Lost Frequencies",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/lost-frequencies/877792512",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/41/dd/36/41dd360d-34a4-1ddb-dea0-76bc35b349ac/9b9905af-5b0a-403f-a55f-7d1c69d69d85_ami-identity-78fa7d2ed5554241cf8bdd780d53444f-2023-11-09T15-16-36.287Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "72929426",
        "name": "Yandel",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/yandel/72929426",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/e6/c0/7a/e6c07a90-2745-b01a-1a4c-3e73a74350e6/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1241546470",
        "name": "Ernia",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ernia/1241546470",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/7e/3e/c6/7e3ec647-d6a9-56eb-704e-89b2de99aea1/70d6a6ce-1c52-4108-b6e8-b336ca9de0c9_ami-identity-f0ded759274ac7b9d723a2bf931770d8-2022-11-15T15-32-29.933Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "298383551",
        "name": "Kevin Gates",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kevin-gates/298383551",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/3b/2b/cc/3b2bccc5-9e85-f726-7f22-0b5e7a02d5bf/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1396099695",
        "name": "S1mba",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/s1mba/1396099695",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/a0/f5/3e/a0f53e00-1363-c059-5ef0-1ef67eba32c8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "6080342",
        "name": "L.A.B",
        "genreNames": [
            "Reggae"
        ],
        "url": "https://music.apple.com/us/artist/l-a-b/6080342",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/2d/42/29/2d422954-4d96-0848-9bd9-f45b6cf7022f/9657504d-2671-46f3-a1c9-9c2048043bd3_ami-identity-2b58b9686341abcdd0551b836dff2c36-2022-12-04T21-44-46.683Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1251512636",
        "name": "SLAVA MARLOW",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/slava-marlow/1251512636",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/4f/de/49/4fde4993-4bbf-0f52-0f03-813b2577e4ac/b6865048-8d0e-49a3-a074-cc6bfedb8876_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "217529867",
        "name": "Kalash",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/kalash/217529867",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/61/98/6a/61986a93-a5a2-a4f1-1750-0ba639c429be/mzl.ibjpxxar.jpg/1000x1000bb.jpg"
    },
    {
        "id": "307674970",
        "name": "Li Ronghao",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/li-ronghao/307674970",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/0a/d4/03/0ad403a4-7bc8-da3d-40d7-ff6ed23e3d45/mzl.bxujwsii.jpg/1000x1000bb.jpg"
    },
    {
        "id": "155138",
        "name": "Sean Paul",
        "genreNames": [
            "Reggae"
        ],
        "url": "https://music.apple.com/us/artist/sean-paul/155138",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/5f/55/09/5f550930-5673-3ba5-4b9f-6ed6786e9de8/mzl.sclgcibb.jpg/1000x1000bb.jpg"
    },
    {
        "id": "15050817",
        "name": "Bushido",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/bushido/15050817",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/39/e6/85/39e68516-5351-09a4-98f1-6b2272c1601b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1263552447",
        "name": "Ryan Castro",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/ryan-castro/1263552447",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/67/57/6c/67576cc5-5e5b-baf0-cb29-0f8976fe729a/26568af4-d59c-442a-bd76-4b56d5377d69_ios-artist-image-26568af4-d59c-442a-bd76-4b56d5377d69-1692306004402_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "663417013",
        "name": "JP Saxe",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jp-saxe/663417013",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/07/6e/65/076e65a0-7c4f-1541-6d21-4636f3a36232/c5a3d0f7-7ad9-4489-8709-104b71eecc72_ami-identity-bad8d6aace8f7ceb18480329d217ba28-2023-08-09T16-10-54.983Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1203094063",
        "name": "DA Uzi",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/da-uzi/1203094063",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ef/fe/db/effedb46-1f14-ef68-3444-bae925bedfe7/mzl.vcgzhxxv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "519020866",
        "name": "Tom Gregory",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tom-gregory/519020866",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/36/cd/0c/36cd0c86-71b5-5133-393d-6815ba2e3eeb/b63206b3-8c79-404a-9728-8123bf563854_ami-identity-04de70fceaf74a242bf6ec43d55d34e3-2023-08-31T16-19-21.696Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1443847561",
        "name": "dhruv",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dhruv/1443847561",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/20/31/d5/2031d5fe-01d6-322e-efb5-8cd04ac7948f/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "139334133",
        "name": "Epik High",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/epik-high/139334133",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/27/35/48/2735485e-73e0-f9d1-3da3-dc7c25c776b1/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "953052200",
        "name": "Awesome City Club",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/awesome-city-club/953052200",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/4b/46/05/4b460519-ffda-9839-1a67-e6caa951c357/9aaf7464-4735-4fde-a846-9189f92a4188_ami-identity-1cdb6c58d25c8e1c3284b297ff3c8359-2023-04-18T15-42-24.354Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1290520265",
        "name": "FACE",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/face/1290520265",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/09/b7/1b/09b71bea-7b08-7573-2635-0afe3743ff55/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "160809474",
        "name": "Joker Xue",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/joker-xue/160809474",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/e4/c4/ed/e4c4ed38-5921-9073-f428-f76ba5437f62/mzl.kvvowdwi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "601295999",
        "name": "Rocco Hunt",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rocco-hunt/601295999",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/da/ea/93/daea9341-a3ff-f6a2-4d00-442b6b5af28d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "38124",
        "name": "Brian Eno",
        "genreNames": [
            "Electronic"
        ],
        "url": "https://music.apple.com/us/artist/brian-eno/38124",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/71/25/21/712521e8-b786-8a37-5feb-062adeb149d8/2739dea7-607b-47de-b936-62bc53bb0d7d_ami-identity-79e343656081eed625cda6d85ffb1ad9-2023-07-07T10-05-18.420Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1373310295",
        "name": "RK",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rk/1373310295",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/6c/c3/19/6cc31947-5b74-6bb7-1dfe-df324c6f2bc0/mzl.jrkshkss.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1437963589",
        "name": "Tananai",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tananai/1437963589",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/5c/ee/3f/5cee3f56-26ea-8c26-ce1c-b0f1fd92cb7a/b53fdd5a-81ed-4b62-b4ae-74c9ce56eeb7_ami-identity-77b03c27ac3b817fd45960f201006593-2023-06-22T08-30-46.521Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "435300447",
        "name": "2 Chainz",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/2-chainz/435300447",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3e/0c/74/3e0c74f9-87b8-639f-517d-4a1cf4bb3c56/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "263633866",
        "name": "Sagopa Kajmer",
        "genreNames": [
            "Turkish Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/sagopa-kajmer/263633866",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/ef/de/99/efde9915-8e5e-87e2-eb26-b66912443681/mzl.vukkloux.jpg/1000x1000bb.jpg"
    },
    {
        "id": "381183610",
        "name": "Jamie Miller",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jamie-miller/381183610",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ed/de/1a/edde1a20-5063-1654-ee7b-1ebd835fd7d6/601161a1-e308-4c55-b9fb-be1e6200d31c_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1428083875",
        "name": "ZUTOMAYO",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/zutomayo/1428083875",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/e5/6d/27/e56d27a0-eba3-acfd-18c8-78f58eb909d0/mzl.bijgxavd.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1206741918",
        "name": "NILETTO",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/niletto/1206741918",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/ec/73/06/ec7306a1-2a83-46ed-fcac-f377a8893445/22fd7766-9c3b-4735-b860-5865d6202e1b_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1291931978",
        "name": "Emir Can İğrek",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/emir-can-i-%C4%9Frek/1291931978",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/85/c4/7b/85c47b79-e2dd-19e7-7989-d0feb80c0952/16a6a335-b071-4870-93da-ff1fcdd3075f_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "448032126",
        "name": "10cm",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/10cm/448032126",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/14/94/8c/14948cb1-cd76-a8b0-65c1-2f9d9bd9a7df/mzl.cxnywdmr.png/1000x1000bb.jpg"
    },
    {
        "id": "387313548",
        "name": "Tanya Chua",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/tanya-chua/387313548",
        "editorialNotes": "She celebrates 25 years in music by reissuing three English LPs.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/97/63/1f/97631f72-7b27-dcd5-71c9-50634ece8b6b/mzl.cxlgfhxq.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "592170733",
        "name": "Olexesh",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/olexesh/592170733",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/22/04/fd/2204fd2c-349d-8c98-3860-23be9d8403a1/7702957b-fcaf-4cc9-ae4b-82bd7da51539_ami-identity-a313fd86289303c07444b89709faf5e2-2023-11-08T20-10-21.850Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "950546264",
        "name": "Renran",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/renran/950546264",
        "editorialNotes": "The ethereal pop darling weaves lilting, evocative ballads.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/0e/f4/bd/0ef4bdb5-5dd3-2304-1687-60e4d777134e/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "252312257",
        "name": "sakanaction",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/sakanaction/252312257",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/5b/ac/75/5bac753f-11bb-a98a-83a3-b5d96e80167c/c6d52ff5-d518-4e43-b2c5-0d01ad204b0a_ami-identity-14054fa463e3f81d8e20494fc4454d6e-2022-11-16T13-18-42.585Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "530814268",
        "name": "Kenshi Yonezu",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/kenshi-yonezu/530814268",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/9c/76/6a/9c766a84-fb92-ab89-5ef6-1a6e6141b46b/mzl.gogswajh.jpg/1000x1000bb.jpg"
    },
    {
        "id": "212884079",
        "name": "No One Else",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/no-one-else/212884079",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/43/ca/83/43ca8351-42ca-e3db-99a9-b69f94f89e95/mzl.ickqriiw.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1317091714",
        "name": "Ramz",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/ramz/1317091714",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/59/b5/c9/59b5c90e-b9c4-12b0-21d9-568c37d3b5b2/mzl.maubture.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1370989430",
        "name": "Dark Polo Gang",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/dark-polo-gang/1370989430",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/56/63/7e/56637e56-1037-07ec-98da-f95f3611fba9/mzl.hspxfhgi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1130337733",
        "name": "Ritviz",
        "genreNames": [
            "Indian Pop"
        ],
        "url": "https://music.apple.com/us/artist/ritviz/1130337733",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/94/15/37/941537b3-d9f9-dd70-35bb-64949b410f68/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1450567125",
        "name": "Madame",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/madame/1450567125",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/57/d1/33/57d13354-df6d-45e4-df02-89c7b1f1d476/001f4cc4-006b-4d0d-bfca-8638b6613518_ami-identity-d3d8e5ece455582218ce127a482af955-2023-02-03T15-29-16.064Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1502454735",
        "name": "StaySolidRocky",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/staysolidrocky/1502454735",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/3a/08/9a/3a089a7c-b8f0-862c-accb-d847b2220664/3990ce85-ea95-4190-a355-b3a63fe5abfc_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "298496035",
        "name": "Avicii",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/avicii/298496035",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/d3/2d/90/d32d9025-d64d-4c80-e9d0-95f01aaec84c/16ec94ea-5c88-4f3d-a925-6c80c45ba8dc_ami-identity-f8a07b8e5cc0d3da04ed7287af5e73bd-2023-04-19T15-49-01.209Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1441939457",
        "name": "Mirror",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/mirror/1441939457",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/d8/07/51/d80751ec-fa6d-4463-f553-c832a3d884f4/mzl.pjensmxg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "486597",
        "name": "Journey",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/journey/486597",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/27/4e/44/274e44f4-5c59-ef93-d276-a908ece16fde/mza_17972897951652055781.png/1000x1000bb.jpg"
    },
    {
        "id": "301261712",
        "name": "Palmy",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/palmy/301261712",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/97/a3/73/97a3733c-d02c-fb06-2b2f-ce151de5ce3c/mzl.niscwywg.jpg/1000x1000bb.jpg"
    },
    {
        "id": "422335345",
        "name": "Shouse",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/shouse/422335345",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/44/d1/3e/44d13e75-43b7-075a-6442-c3e2546025b1/47fe039b-d90f-492e-9770-ea6f178d2f02_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "497915155",
        "name": "Montez De Durango",
        "genreNames": [
            "Música Mexicana"
        ],
        "url": "https://music.apple.com/us/artist/montez-de-durango/497915155",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/45/df/b4/45dfb4e3-0e07-a66b-c99d-80fb59ff0c45/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "322671226",
        "name": "Cakal",
        "genreNames": [
            "Turkish Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/cakal/322671226",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/0d/a2/bc/0da2bce8-2bf6-b9c5-7afb-84a536093220/fe658ea4-60c9-4722-8aea-4b679be06cb0_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "818951094",
        "name": "MAMAMOO",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/mamamoo/818951094",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/32/3b/c7/323bc7a8-12b9-fcde-4549-e84f318d484f/mzl.oqcbabqo.jpg/1000x1000bb.jpg"
    },
    {
        "id": "304307063",
        "name": "Naza",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/naza/304307063",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/b9/63/ce/b963cea9-a636-b6ae-8d8c-f0964551e693/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1475788251",
        "name": "Boza",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/boza/1475788251",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c4/38/aa/c438aaa1-85e4-90e0-f41d-1e264b4ec681/3b66bcf4-3f60-4b01-9b43-fefc223c2d37_ami-identity-94d609633e0efcc46c897e5b9bb68712-2023-11-10T19-10-25.604Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "304270345",
        "name": "Mustard",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/mustard/304270345",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/f5/70/9c/f5709c69-b97a-1c1c-322e-dde444aa947a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "608509915",
        "name": "Buray",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/buray/608509915",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2c/1a/0f/2c1a0f3c-c8c0-18d5-9095-f6ece16a46cf/2357b86b-3ad6-4485-9978-caf2c8db6818_ami-identity-6544794a43ff0b1fc81fdf29434e1025-2023-11-16T12-13-21.115Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1370196486",
        "name": "Carin Leon",
        "genreNames": [
            "Latin"
        ],
        "url": "https://music.apple.com/us/artist/carin-leon/1370196486",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/31/2a/2a/312a2ae2-d9da-c2e3-30c6-8de9c7267f65/9fce5582-dd27-4638-97d3-38fd7013976b_ami-identity-2a0d87d06a4b57ecc36b3bbada7f2960-2023-05-19T05-08-22.559Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1484701109",
        "name": "AP Dhillon",
        "genreNames": [
            "Worldwide"
        ],
        "url": "https://music.apple.com/us/artist/ap-dhillon/1484701109",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/7a/d4/5a/7ad45ac9-d38b-fda2-b912-0430e3611f7b/dfeebfef-71d0-42a9-9e76-f6bc168d70ae_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1074387486",
        "name": "Yzomandias",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/yzomandias/1074387486",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/36/ae/04/36ae047e-8e9a-4c51-0076-d92661a4f94f/741be015-5841-4c41-b0e3-95d9f7b49da2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "216284931",
        "name": "Wolfine",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/wolfine/216284931",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/51/53/f8/5153f8fa-2a53-6241-ff68-b26acb11683d/mzl.hdwlniet.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1497144792",
        "name": "Güneş",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/g%C3%BCne%C5%9F/1497144792",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1e/9c/cc/1e9ccc90-c87d-c3c7-8c9b-bb3deed5ce15/23e2ba28-e654-4a13-a99f-91da4cc4b882_ami-identity-f093f6bfe55f9d61f911163eb37f979d-2023-11-17T18-29-51.562Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1024195544",
        "name": "Violette Wautier",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/violette-wautier/1024195544",
        "editorialNotes": "Behind the captivating persona is a powerful and sweet voice.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/3b/91/ab/3b91ab38-8bd8-28e2-eef4-75c5c7628de7/5b023723-f44c-40de-a260-a45817cfb113_ami-identity-b6cc09621dbeec751261d7cc0e5c8df9-2022-10-27T07-54-54.163Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1478288895",
        "name": "Patrickananda",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/patrickananda/1478288895",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/88/89/52/888952e5-e3f9-8206-22f7-5d790e1c9b03/b6f4a275-54bf-4257-8dbb-7f3655a81764_ami-identity-29e5a1979a0c49617773388da5dcda93-2023-09-26T04-47-50.177Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1523533674",
        "name": "Only Monday",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/only-monday/1523533674",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/09/6f/05/096f0565-776f-8336-9579-d1d6ed8b0f50/mzl.bzkbulff.jpg/1000x1000bb.jpg"
    },
    {
        "id": "280038430",
        "name": "Aitch",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/aitch/280038430",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/1b/cc/2a/1bcc2a81-869d-b5b0-8777-469d0043ef9b/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "975450659",
        "name": "MeloMance",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/melomance/975450659",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/25/00/69/250069b9-7d79-f419-fd7e-1128686f235a/mzl.pmcwggph.jpg/1000x1000bb.jpg"
    },
    {
        "id": "4275634",
        "name": "Flume",
        "genreNames": [
            "Electronic"
        ],
        "url": "https://music.apple.com/us/artist/flume/4275634",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/81/d2/09/81d20947-19ce-86b5-3782-da032ee213c6/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "688910162",
        "name": "Jaymes Young",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/jaymes-young/688910162",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/aa/03/82/aa038276-b55a-3702-a91f-7ded47452f1d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "523106872",
        "name": "Mark Forster",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mark-forster/523106872",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/a6/b9/8a/a6b98a21-ecdd-cd09-6a8d-8a6d78506331/mzl.bupqyxwi.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1506180365",
        "name": "yama",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/yama/1506180365",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features112/v4/b3/72/3d/b3723d8b-1f3c-59a3-b62e-dd3d29271e94/mzl.wvzjqoqn.jpg/1000x1000bb.jpg"
    },
    {
        "id": "379679142",
        "name": "Eyal Golan",
        "genreNames": [
            "Worldwide"
        ],
        "url": "https://music.apple.com/us/artist/eyal-golan/379679142",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/00/dc/99/00dc99d9-9a25-cc7d-f07d-7ca0f722a143/mzl.fxdnnljs.jpg/1000x1000bb.jpg"
    },
    {
        "id": "696584738",
        "name": "Earth Patravee",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/earth-patravee/696584738",
        "editorialNotes": "Dreamy tones and lilting vocals frame achingly personal lyrics.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/e0/88/29/e0882926-032b-2e90-53bf-dca9f3ca3c6b/406c76f1-4a31-405d-a9f1-45c46689e705_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1351375726",
        "name": "Normani",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/normani/1351375726",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/17/77/ea/1777eaa5-d84f-5dcc-e1c6-76611b31e005/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "185088141",
        "name": "BUMP OF CHICKEN",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/bump-of-chicken/185088141",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/0f/82/3d/0f823dec-4226-9836-8426-e4266b9d4f4b/mzl.bloazwmc.jpg/1000x1000bb.jpg"
    },
    {
        "id": "673556643",
        "name": "Quavo",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/quavo/673556643",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/66/cd/c5/66cdc5cd-d98a-b1df-a6aa-5d7571f21429/8c688370-e5d2-48c8-b517-fac874808fc8_ami-identity-a1f75b9ed8d7736217c185119b5a09ad-2023-08-18T02-20-09.403Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "370412270",
        "name": "A-Lin",
        "genreNames": [
            "Mandopop"
        ],
        "url": "https://music.apple.com/us/artist/a-lin/370412270",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/31/b5/e5/31b5e53b-d9dc-93ac-d468-320bb2d812ce/mzl.lidhepnl.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1480524514",
        "name": "BLOOM VASE",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/bloom-vase/1480524514",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/1e/f1/4a/1ef14ae8-185e-6088-126e-12554bbf9816/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "876648149",
        "name": "Osher Cohen",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/osher-cohen/876648149",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/d7/73/d6/d773d6d7-d3dc-10b4-17b1-5b4e3cc4603c/mzl.rdoaiari.jpg/1000x1000bb.jpg"
    },
    {
        "id": "384641776",
        "name": "Jay Park",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/jay-park/384641776",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/1c/77/dd/1c77ddd4-49dc-8a50-2fe4-1fec9379a9f2/b8a6e7a7-b8ae-4613-ac01-01032e35d292_ami-identity-cbd70af3c8603f16b45be591306cb402-2023-11-15T05-13-11.824Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1498499723",
        "name": "Iñigo Quintero",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/i%C3%B1igo-quintero/1498499723",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/c7/6e/49/c76e49ea-cafd-13e5-7fbd-1ee2e9fe8a63/48a115d8-5378-4dc9-b5a3-7e4eb08169cc_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "881027285",
        "name": "Allj",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/allj/881027285",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/4f/2d/92/4f2d92d6-7a34-e7af-40df-c8d2be6264bf/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1437009453",
        "name": "Fireboy DML",
        "genreNames": [
            "Afrobeats"
        ],
        "url": "https://music.apple.com/us/artist/fireboy-dml/1437009453",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ac/60/9e/ac609ecf-e35f-1654-3ebd-3f4067d96e7f/9fdfa666-89c1-4027-8c38-47043ee53099_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "359650987",
        "name": "Emis Killa",
        "genreNames": [
            "Hip-Hop"
        ],
        "url": "https://music.apple.com/us/artist/emis-killa/359650987",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/71/87/ee/7187ee4c-5ac2-2ed4-4d9b-0254af46f7a0/e4a0017e-dff6-4d6d-9beb-390a4783ee47_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1382257814",
        "name": "Kaho Hung",
        "genreNames": [
            "Cantopop/HK-Pop"
        ],
        "url": "https://music.apple.com/us/artist/kaho-hung/1382257814",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/54/b1/d7/54b1d74f-2ad0-b3f5-f21c-8ad94b62211b/b081a085-cb00-479b-a6f0-419ef1976aec_ami-identity-abef73974df049f820ae5a5efee2f9af-2023-05-31T08-35-02.883Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "922140824",
        "name": "Ego",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/ego/922140824",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/37/47/cc/3747cc4f-6abd-3096-509f-58e550505c44/7143.jpg/1000x1000ac.jpg"
    },
    {
        "id": "1253135209",
        "name": "NSG",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nsg/1253135209",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/48/ab/ac/48abace4-2277-2445-6e90-43249ecf7891/973e9335-eb1f-4cfa-8ba8-1e049b20cc39_ami-identity-2eaf129f832d737d2b38e01c6588a806-2023-08-13T17-47-01.753Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "516169807",
        "name": "LP",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/lp/516169807",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/f0/ad/38/f0ad38ae-4fce-2d2b-8705-2d63d845824c/ec98b8c5-793a-480a-8c51-78727a26e601_ami-identity-d7c9e4a6e806f2ff59bbf9ad5d7f4619-2023-05-10T06-08-14.338Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1156448786",
        "name": "Monetochka",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/monetochka/1156448786",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/3a/4a/2b/3a4a2b31-292e-4dbf-9577-cfae27a102fd/mzl.xaoxembq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1035916223",
        "name": "Maggie Lindemann",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/maggie-lindemann/1035916223",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/ef/e9/bb/efe9bb2e-2125-6491-2d09-bb81352cb4bd/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "584759967",
        "name": "Merve Özbey",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/merve-%C3%B6zbey/584759967",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/c2/ba/f0/c2baf0e7-7b39-66da-7145-d9cdc5a4513a/mza_4056994579596104747.png/1000x1000bb.jpg"
    },
    {
        "id": "1077567887",
        "name": "CNCO",
        "genreNames": [
            "Pop Latino"
        ],
        "url": "https://music.apple.com/us/artist/cnco/1077567887",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/d3/72/a1/d372a1f1-8d04-295d-8b11-2c9b5c5d0e88/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1548008317",
        "name": "JISOO",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/jisoo/1548008317",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/a9/04/cb/a904cb7c-4669-8317-6cf1-32a993994cfe/5284e032-034f-402b-b015-a1746349501a_ami-identity-27ce329449e330082219af194269e317-2023-03-28T00-52-11.917Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1302824608",
        "name": "Aitana",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/aitana/1302824608",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/c2/b5/26/c2b5269b-013a-c051-1357-a26f077b7e75/2692dd29-759b-411a-bdc7-158884b6020f_ami-identity-674df84f49dc2062d33bd758a2b6e171-2023-09-20T14-28-52.509Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "657331568",
        "name": "Paul Kim",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/paul-kim/657331568",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/52/66/8d/52668d0d-cb59-796d-bf81-807924fa0fbc/c423c71b-6fde-4ab6-845c-1421930e7dfd_ami-identity-003da2d175d4a52f9c6e99e717c26ce6-2023-10-08T12-44-30.786Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1206070304",
        "name": "Sigrid",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/sigrid/1206070304",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/4d/c5/6c/4dc56c55-6d82-b0a9-a3d5-269df54a7302/a6b6d429-df8d-4617-ab40-9c7bf3b6a2af_ami-identity-1a93ab0d0fec750275fc7c347cf8f095-2023-10-24T16-36-18.525Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1141391002",
        "name": "Aleyna Tilki",
        "genreNames": [
            "Turkish Pop"
        ],
        "url": "https://music.apple.com/us/artist/aleyna-tilki/1141391002",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/f4/fa/d5/f4fad54e-740c-9397-a959-98cc29d1dbf5/mza_12976612179598369371.png/1000x1000bb.jpg"
    },
    {
        "id": "1444667300",
        "name": "NЮ",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/n%D1%8E/1444667300",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/bc/f6/09/bcf609f9-702c-b4f4-865b-ca950c40f3ed/mzl.bpzwyfpq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1411110180",
        "name": "Shane Codd",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/shane-codd/1411110180",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/68/e5/ac/68e5acc4-cc71-7141-578d-1e450337b746/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "868717338",
        "name": "Gradur",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/gradur/868717338",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/35/21/f2/3521f2b9-5278-e6bc-dcb8-a0d48f1001f9/mzl.iacfltjd.jpg/1000x1000bb.jpg"
    },
    {
        "id": "472054",
        "name": "John Mayer",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/john-mayer/472054",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/19/b8/29/19b829eb-be90-3a46-bd9b-042fe4065f0a/0c8d7b39-7330-48ea-aa8d-d686fcf303a2_ami-identity-2dd144b72793489b04a38746047cf897-2023-03-10T21-03-27.155Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1282689268",
        "name": "Elettra Lamborghini",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/elettra-lamborghini/1282689268",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/35/23/ab/3523ab62-7f9d-968f-ade4-fd5cd431c3e7/1ae8e3f9-6e71-4feb-a2ae-2c4102afdaa3_ami-identity-aba8fed14a159d12cae2df407bdf165b-2023-05-30T13-07-22.202Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "799176218",
        "name": "Soso Maness",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/soso-maness/799176218",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/a6/48/06/a6480652-a555-969a-dc7f-2faf5f7d6722/mzl.acpfxujq.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1281974195",
        "name": "HVME",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/hvme/1281974195",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ca/80/c8/ca80c836-402b-28e6-fe18-d47425527033/03ecd8ce-c071-464b-893a-d495ea287663_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1468290871",
        "name": "Diplo",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/diplo/1468290871",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages123/v4/44/17/30/4417304a-7ba8-e1e3-bdb3-ff86eec7da9d/51c06a87-6b84-4892-853c-c415ea8d836a_ami-identity-e2cfa91d8e6f4b507976bb4f592a39b0-2023-01-12T20-58-21.638Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1115218952",
        "name": "Manuş Baba",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/manu%C5%9F-baba/1115218952",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/de/9c/10/de9c10ab-93e5-aa81-679f-1a0d622298e6/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "49252346",
        "name": "Wisin",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/wisin/49252346",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/0e/22/89/0e228909-fd9a-3113-8b9c-5ceea42f3ae6/ca17f3b4-b5e4-47d0-9256-6c01513732b7_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "254710145",
        "name": "Burak Yeter",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/burak-yeter/254710145",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/58/42/5c/58425c80-e5a6-9701-723c-3fbdadd720d1/mzl.ynxlqaco.png/1000x1000bb.jpg"
    },
    {
        "id": "16326351",
        "name": "The Ronettes",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/the-ronettes/16326351",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/37/48/8c/37488c5e-e8cc-39b8-d539-7971ae9d4dbf/mzl.rniudjpf.jpg/1000x1000bb.jpg"
    },
    {
        "id": "205732582",
        "name": "Labrinth",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/labrinth/205732582",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/7d/8d/b2/7d8db228-e4f1-3835-3f8f-b4ad1be252ab/9c2ff831-6645-4025-8ec0-7400cc365d10_ami-identity-9425cfdc876613edeff76fc375e35c08-2023-05-10T00-39-37.614Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1526319335",
        "name": "TREASURE",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/treasure/1526319335",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e1/73/03/e173030f-9673-7452-0019-977b13d996cf/9941b323-9a77-4d39-836d-f41201875f64_ami-identity-e48e0fe4d44534c004e07cebe44ad787-2023-07-28T16-49-38.076Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "160343465",
        "name": "Dima Bilan",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/dima-bilan/160343465",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/b9/e4/62/b9e462e5-15c7-d564-c31f-38a16a961778/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "381210857",
        "name": "Chris Jedi",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/chris-jedi/381210857",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/f6/56/de/f656de33-4687-99d8-e4ca-03e2157b7ad7/26788893-3e2c-4cd0-a0dc-4de2f97fe1f1_ami-identity-859add540d9ed86440df953c0fe12483-2022-11-03T22-03-28.640Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "342260741",
        "name": "Hozier",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/hozier/342260741",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/5f/d7/4f/5fd74fde-fef2-2299-bf6d-04630ad0fb5c/af48c2b9-543d-49e5-b071-2a8025a17ea0_ami-identity-e8b26be07c0315108fa529e2f615cb38-2023-03-17T11-28-22.617Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1109477747",
        "name": "Luísa Sonza",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lu%C3%ADsa-sonza/1109477747",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/0a/0b/16/0a0b16f4-551c-e7a9-6163-41c3bd779a29/d7b79514-cc51-49e3-9f4e-cb19e6d8eabf_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "311205761",
        "name": "Silvestre Dangond",
        "genreNames": [
            "Música tropical"
        ],
        "url": "https://music.apple.com/us/artist/silvestre-dangond/311205761",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/81/67/a0/8167a035-b547-ee40-2ad6-6010bfa7cbff/11ff647b-97fb-44c8-83df-98d2935731c9_ami-identity-8c485ee38a882c93ba1a146886ba7411-2023-09-05T02-54-40.585Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "450650075",
        "name": "Simge",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/simge/450650075",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/14/ad/49/14ad4920-1c1d-40b4-57e4-9a1041efd433/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1883403",
        "name": "Kings of Leon",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/kings-of-leon/1883403",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/fb/a1/e0/fba1e081-02ef-a409-ec36-eb74b5aa18b2/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1192369008",
        "name": "NOTD",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/notd/1192369008",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/ff/8d/7b/ff8d7b28-b4e3-598f-620f-58022dd20277/ee865e40-3032-4a3e-b096-4e167f554bc0_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "863244534",
        "name": "Mari Kraymbreri",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/mari-kraymbreri/863244534",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3c/43/9f/3c439f83-c877-b595-d540-890bc45c510e/5d749ce3-474a-4f60-85db-bb8c3796413f_ami-identity-a13484b3981fec86eea1e018dfedeb34-2023-10-20T06-57-46.054Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "334329603",
        "name": "Jain",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/jain/334329603",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/9d/93/ee/9d93ee11-53ed-d045-4b11-5f6f4b15f324/mzl.ztqrsgts.jpg/1000x1000bb.jpg"
    },
    {
        "id": "102640",
        "name": "Tarkan",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/tarkan/102640",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/24/f1/6b/24f16be5-8346-c40a-7fe7-ba1184549fcf/68dedfe3-32c7-4bd4-9f1a-599db6684ba2_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "108709",
        "name": "Eve",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/eve/108709",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/6f/22/1c/6f221c91-842c-fce1-3bda-7dea3807835a/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "885054140",
        "name": "Kungs",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/kungs/885054140",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/c4/18/ed/c418eda9-e66a-a5e0-0443-cb94f876d6a8/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "930928857",
        "name": "Kane Brown",
        "genreNames": [
            "Country"
        ],
        "url": "https://music.apple.com/us/artist/kane-brown/930928857",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/b2/ca/09/b2ca090a-505c-f746-2a94-ea2e7cb4643b/mzl.shrmiomc.jpg/1000x1000bb.jpg"
    },
    {
        "id": "269598403",
        "name": "Gen Hoshino",
        "genreNames": [
            "J-Pop"
        ],
        "url": "https://music.apple.com/us/artist/gen-hoshino/269598403",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/6d/e8/b5/6de8b5c3-e007-78eb-576c-9c1414c7c5f9/1025bbcb-834b-45ae-91e1-9b6468d8204d_ami-identity-e4018876c715344aa8d2cd32e414e82a-2023-08-07T03-03-22.706Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1456156961",
        "name": "Os Barões da Pisadinha",
        "genreNames": [
            "Forró"
        ],
        "url": "https://music.apple.com/us/artist/os-bar%C3%B5es-da-pisadinha/1456156961",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features122/v4/80/b3/89/80b389df-ee76-e3ba-c9c7-52264049ba7a/mzl.hkfwdoza.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1174745559",
        "name": "MOMOLAND",
        "genreNames": [
            "K-Pop"
        ],
        "url": "https://music.apple.com/us/artist/momoland/1174745559",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/21/fa/f1/21faf1d0-524c-08cc-cc8c-7a35bc3190e9/mzl.ooyzifct.jpg/1000x1000bb.jpg"
    },
    {
        "id": "709788643",
        "name": "Tion Wayne",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/tion-wayne/709788643",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/2c/c4/1f/2cc41f5e-6a83-c009-995b-1e6378fcc960/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1423138649",
        "name": "Rhove",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/rhove/1423138649",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/b2/a1/6e/b2a16eb9-4daa-74ec-be6e-7cc88b41b72f/43a4df6e-a17d-41ea-9234-e2d5a9846364_ami-identity-5af81faf91fc0f32fcc6b5da0c6dcb0d-2023-06-22T14-22-08.828Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "128860700",
        "name": "Nipsey Hussle",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/nipsey-hussle/128860700",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/6f/c1/67/6fc16735-6a90-05cc-ec25-94a30f61fb5d/mzl.vvowpzeu.jpeg/1000x1000bb.jpg"
    },
    {
        "id": "891265611",
        "name": "MJ116",
        "genreNames": [
            "Chinese Hip-Hop"
        ],
        "url": "https://music.apple.com/us/artist/mj116/891265611",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/b5/fa/7c/b5fa7c67-3f27-ce9b-aabe-a388a3d4f009/mzl.twnfltde.jpg/1000x1000bb.jpg"
    }
]