const DeleteAccountModal = ({
  handleClose,
  handleDeleteAccount,
}) => {
  return (
    <div
      style={{ zIndex: 100, minHeight: "100vh" }}
      className="absolute text-black inset-0 flex items-center justify-center bg-[rgba(21,24,28,0.90)]"
    >
      <div className="w-[500px] h-[225px] flex flex-col bg-white rounded-[24px] p-6">
        <div className="flex justify-end">
          <button onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#1E2228"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#1E2228"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-bold mb-4">Are you sure?</h1>
          <div>
            <button
              onClick={handleDeleteAccount}
              className="mt-4 bg-[rgba(239,209,101,1)] text-black font-bold py-2 px-4 rounded-[8px]"
            >
              Yes
            </button>
            <button
              onClick={handleClose}
              className="mt-4 bg-white text-black border border-[rgba(239,209,101,1)] font-bold py-2 px-4 rounded-[8px] ml-4"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
