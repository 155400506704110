import { useState } from "react";

const UpdateUsernameModal = ({ handleClose, accessToken, setUpdatedUsername }) => {
  const [inputeUsername, setInputUsername] = useState("");
  const [error, setError] = useState("");

  const handleUpdateUsername = async () => {
    console.log(inputeUsername);
    if (inputeUsername === "") {
      setError("Username is required");
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_ASSET_API}/api/v1/User/profile/username`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
          body: JSON.stringify({
            username: inputeUsername,
          }),
        }
      );

      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        handleClose();
        setUpdatedUsername(inputeUsername);
        alert("Username added successfully");
      } else {
        setError("Username is taken");
      }
    } catch (err) {
      console.log(err);
      setError("Username already taken");
    }
  };

  return (
    <div
      style={{ zIndex: 100, minHeight: "100vh" }}
      className="absolute text-black inset-0 flex items-center justify-center bg-[rgba(21,24,28,0.90)]"
    >
      <div className="w-[500px] h-[225px] flex flex-col bg-white rounded-[24px] p-6">
        <div className="flex justify-end">
          <button onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#1E2228"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#1E2228"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col items-center justify-center">
          <h1 className="text-2xl font-bold mb-4">Update username</h1>
          <input
            value={inputeUsername}
            onChange={(e) => setInputUsername(e.target.value)}
            className="border border-gray-300 rounded-[8px] py-2 px-4 w-full"
            type="text"
            placeholder="Username"
          />
          <button
            onClick={handleUpdateUsername}
            className="mt-4 bg-[rgba(239,209,101,1)] text-black font-bold py-2 px-4 rounded-[8px]"
          >
            Save
          </button>
          <p className="text-red-500 mt-2">{error}</p>
        </div>
      </div>
    </div>
  );
};

export default UpdateUsernameModal;
