import clevertap from "clevertap-web-sdk";
import ReactGA from "react-ga4";

export default function useClevertap() {
  function handleEventPushClick(eventName: string, eventPayload: object) {
    clevertap.event.push(eventName, eventPayload); // Replace Payload as per your event schema and design
    ReactGA.event({
      action: eventName,
      category: "Event",
      label: eventName
    })
  }

  function onUserLogin(userObject: any) {
    clevertap.onUserLogin.push(
      {
        Site: {
          Email: userObject?.email,
          Name: userObject.name || "",
        }
      }
    ); // Replace with user profile object of the logged in user
  }

  return {
    handleEventPushClick,
    onUserLogin,
  };
}
