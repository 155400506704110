import React, { useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { generateUsername } from "friendly-username-generator";
import useAsset from "../hooks/useAsset";
import useClevertap from "../hooks/useClevertap";
import { usePostHog } from "posthog-js/react";
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID } from "../constants/dataConstant";
import { INCREMENT_ARTIST_SIGNUP, postArtistEvent } from "../endpoints/api";

const LoginOverlay = ({ artistName, artistId, artistImage, setShowAuth }) => {
  const [loading, setLoading] = useState(false);
  const { setAccessToken, setUser } = useAsset();

  const { handleEventPushClick } = useClevertap();
  const posthog = usePostHog();

  const auth = getAuth();

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleGoogleSignIn = async () => {

    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      clevertap.setLogLevel(3);
      clevertap.init(CLEVERTAP_ACCOUNT_ID);
      setUser(result.user);

      if (result.user) {
        try {
          result.user.getIdToken().then(async (token) => {
            // console.log("TOKEN ", token);
            result.user.getIdToken().then(async (token) => {
              clevertap.profile.push({
                "Site": {
                  "Name": result.user.displayName,               
                  "Email": result.user?.email,               // Email address of the user
                }
              });
              handleEventPushClick("MUSICNERD_SIGNUP", {
                email: result.user?.email ? result.user.email : "inboxdev.b@gmail.com",
                artist: artistName,
              });
              setAccessToken(token);
            });

            let checkIfReferralUsed = localStorage.getItem("referralCodeUsed");
            if (checkIfReferralUsed === null) {
              let ref = localStorage.getItem("ref");
              // console.log("Referral code", ref);
              const body = {
                referralCode: ref,
                referreduser: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
              };
              fetch(`https://cruncher.asset.money/misc/referral`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
              })
                .then((response) => {
                  // console.log(response);
                  localStorage.setItem("referralCodeUsed", "true");
                })
                .catch((err) => {
                  console.log(err?.message);
                });
            }

            let getFirstTime = localStorage.getItem("firstTime");
            if (result.user.email && artistName) {
              if (getFirstTime === null) {
                posthog?.capture("signup", {
                  email: result.user.email,
                });
                console.log({
                  email: result.user.email,
                  artistName: artistName,
                });
                postArtistEvent(artistId, artistName, artistImage, INCREMENT_ARTIST_SIGNUP);
                handleEventPushClick("MusicNerd_AccountCreated", {
                  username: result.user.displayName,
                  email: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
                  artistName: artistName,
                });
                handleEventPushClick("MUSICNERD_ACCOUNT_CREATED", {
                  username: result.user.displayName,
                  email: result.user.email ? result.user.email : "inboxdev.b@gmail.com",
                  artistName: artistName,
                });
                console.log("Email sent", result.user.email);
                // const body = {
                //   email: result.user.email,
                //   artist_name: artistName ? artistName : "Music Fan",
                // };
                // fetch(`${process.env.REACT_APP_URL}/send-email`, {
                //   method: "POST",
                //   headers: {
                //     "Content-Type": "application/json",
                //   },
                //   body: JSON.stringify(body),
                // })
                //   .then(() => {
                //     console.log("Email sent");
                //   })
                //   .catch((err) => {
                //     console.log(err?.message);
                //   });
                localStorage.setItem("firstTime", "false");
              }
            }
            setAccessToken(token);
            localStorage.setItem("loggedIn", "true");
          });
        } catch (e) {
          console.log("error", e);
        }
      }
      setShowAuth(false);
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    // use tailwind
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg">
        <h2 className="text-2xl font-bold text-center">
          Verify yourself to play again
        </h2>
        <p className="text-center text-gray-500 mt-2">
          Please sign-up to continue playing MusicNerd.
        </p>
        <button
          className="bg-[rgba(239,209,101,1)] text-black font-bold py-2 px-4 rounded mt-4 w-full"
          onClick={handleGoogleSignIn}
          disabled={loading}
        >
          {loading ? "Loading..." : "Sign in with Google"}
        </button>
      </div>
    </div>
  );
};

export default LoginOverlay;
